import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

//! Ant Imports

function Error404() {
  return (
    <Result
      className="cms-404"
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist or you do not have privilege to access this page."
      extra={
        <Button type="primary">
          <Link to="/contacts">Back Home</Link>
        </Button>
      }
    />
  );
}

export default Error404;
