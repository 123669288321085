import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Space, Typography } from "antd";
import Layout from "../layout/Layout";
import success from "../../../assets/images/success.png";
import { ROUTES } from "../../../common/constants";

function RegisterSuccess() {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="success-container">
        <div className="align-text-center success-container-box">
          <Space direction="vertical" size="small">
            <img src={success} alt="success" />
            <Typography.Title level={4} className="dark-text">
              Great!
            </Typography.Title>
            <Typography.Text>
              Your account has been created successfully.
            </Typography.Text>{" "}
            <Button
              type="primary"
              onClick={() => {
                navigate(ROUTES.LOGIN);
              }}
            >
              Get started
            </Button>
          </Space>
        </div>
      </div>
    </Layout>
  );
}

export default RegisterSuccess;
