import React, { useContext, useEffect, useRef, useState } from "react";

//! Antd imports
import { Input, Col } from "antd";

//! User files
import {
  EditIcon,
  ImageIcon,
  DocIcon,
  ExternalIcon,
  DownloadIcon,
  RemoveUser,
  AudioIcon,
  VideoIcon,
  PdfIcon,
  PptIcon,
} from "../../../../../assets/svg";
import Loading from "../../../../../common/components/Loading";
import {
  DELETE_DOCUMENT,
  EDIT,
  EDIT_DOCUMENT,
  VIEW,
} from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import { hasGroupRight } from "../../../../../common/utils";

function ContactDocumentsListItem({
  doc,
  handleDownloadAttachment,
  editDocumentName,
  editLoading,
  docId,
  setDocId,
  handlePopup,
  contactPermission,
  openDocExternalLink,
  docLength,
  index,
}) {
  const [docName, setDocName] = useState();
  const [docExtension, setDocExtension] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const {
    state: { userGroupPermission, currentUser },
  } = useContext(AppContext);
  const docInputRef = useRef();

  const getDocumentIcon = (type) => {
    if (type === "IMAGE") {
      return <ImageIcon />;
    }
    if (type === "PDF") {
      return <PdfIcon />;
    }
    if (type === "DOC") {
      return <DocIcon />;
    }
    if (type === "PPT") {
      return <PptIcon />;
    }
    if (type === "AUDIO") {
      return <AudioIcon />;
    }
    if (type === "VIDEO") {
      return <VideoIcon />;
    }
  };

  const editFileName = (fileName, id) => {
    const ext = fileName?.split(".");
    setDocName(ext[0]);
    setDocExtension(`.${ext[1]}`);
    setDocId(id);
    setIsEdit(true);
  };

  const handleInputValue = (e, id) => {
    if (e?.target?.value?.trim()?.length > 0) {
      editDocumentName(id, `${e.target.value}${docExtension}`);
    } else {
      docInputRef?.current?.focus();
    }
  };

  useEffect(() => {
    if (editLoading) {
      setIsEdit(false);
      setTimeout(() => {
        setDocName();
        setDocExtension();
        setDocId(0);
      }, 1000);
    }
  }, [editLoading, setDocId]);

  const noEdit = () => {
    setIsEdit(false);
  };

  const handleInput = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={
        docLength - 1 !== index ? "cms-docs cms-docs-border" : "cms-docs"
      }
      onMouseDown={(e) => noEdit(e)}
    >
      <Col span={18} className="cms-doc-list">
        <div>{doc?.type && getDocumentIcon(doc?.type)}</div>
        <div className="cms-doc-name-info">
          <span
            className={`${
              isEdit && docId === doc?.id ? "hide" : ""
            } font-inter cms-doc-name`}
          >
            {doc?.name}
          </span>
          {docName && docId === doc?.id && (
            <Input
              className={`${!isEdit ? "hide" : ""} cms-doc-name `}
              addonAfter={docExtension}
              defaultValue={docName}
              onPressEnter={(e) => handleInputValue(e, doc?.id)}
              onMouseDown={(e) => handleInput(e)}
              ref={docInputRef}
            />
          )}
          {contactPermission &&
            hasGroupRight(
              (userGroupPermission ?? contactPermission) ? EDIT : VIEW,
              EDIT_DOCUMENT,
            ) && (
              <span
                className={`${
                  isEdit && docId === doc?.id ? "hide" : ""
                } cms-pointer`}
                onClick={() => editFileName(doc?.name, doc?.id)}
              >
                {editLoading && docName ? (
                  <Loading size="small" />
                ) : (
                  <EditIcon />
                )}
              </span>
            )}
        </div>
        <div className="cms-name-separator" />
        <div className="cms-doc-username">
          {parseInt(currentUser?.id, 10) === doc?.userId
            ? "You"
            : doc?.username}
        </div>
      </Col>
      <Col span={6} className="cms-document-list-action">
        <span
          className="cms-pointer cms-doc-external-icon"
          onClick={() => openDocExternalLink(doc?.key)}
        >
          <ExternalIcon />
        </span>
        <span
          className="cms-pointer cms-doc-download-icon"
          onClick={() =>
            handleDownloadAttachment(doc?.key, doc?.name, doc?.url)
          }
        >
          <DownloadIcon />
        </span>
        {contactPermission &&
          hasGroupRight(
            (userGroupPermission ?? contactPermission) ? EDIT : VIEW,
            DELETE_DOCUMENT,
          ) && (
            <span
              className="cms-pointer cms-doc-delete-icon"
              onClick={() => handlePopup(doc?.name, doc?.key, doc?.id)}
            >
              <RemoveUser />
            </span>
          )}
      </Col>
    </div>
  );
}

export default ContactDocumentsListItem;
