import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import filter from "lodash/filter";
import amplitude from "amplitude-js";
import without from "lodash/without";
import isEmpty from "lodash/isEmpty";

//! Ant Imports
import { Alert, message, notification } from "antd";

//! User Files
import {
  defaultDateFormat,
  MANAGE,
  OWNER,
  REGEX,
  EDIT,
  IMAGE,
  PDF,
  DOC,
  PPT,
  VIDEO,
  AUDIO,
  ADMIN,
  VIEW,
  AVATAR_COLOR,
  PLAN_STATUS,
  LIMITED,
  AUTO_TAGS,
  IMAGE_EXTENSION,
  VIDEO_EXTENSION,
  AUDIO_EXTENSION,
  WINDOW_SIZE,
  ROUTES,
} from "./constants";
import {
  AudioIcon,
  DocIcon,
  ImageIcon,
  NotificationErrorIcon,
  NotificationTickIcon,
  NotificationWarningIcon,
  PdfIcon,
  PptIcon,
  VideoIcon,
} from "../assets/svg";
import Outlook from "../assets/images/outlook-1 1.png";
import GooglePlus from "../assets/images/google-plus 1.png";
import Icloud from "../assets/images/icloud 1.png";
import Csv from "../assets/images/csv file.png";
import Vcf from "../assets/images/vcf file.png";

export function useRouteQuery() {
  return new URLSearchParams(useLocation().search);
}

export const toast = (
  { message: content, type, duration = 3 },
  destroy = true,
) => {
  if (destroy) {
    message.destroy();
  }
  switch (type) {
    case "info":
      message.info(content, duration);
      break;
    case "success":
      message.success(content, duration);
      break;
    case "warning":
      message.warning(content, duration);
      break;
    case "error":
      message.error(content, duration);
      break;
    default:
      break;
  }
};

// eslint-disable-next-line no-shadow
export const notificationToast = ({ message, description = "", type }) => {
  let className = "cms-notification cms-notification-success";
  let icon = <NotificationTickIcon />;
  switch (type) {
    case "Success":
      className = "cms-notification cms-notification-success";
      icon = <NotificationTickIcon />;
      break;
    case "warning":
      className = "cms-notification cms-notification-warning";
      icon = <NotificationWarningIcon />;
      break;
    case "error":
      className = "cms-notification cms-notification-error";
      icon = <NotificationErrorIcon />;
      break;
    default:
      break;
  }
  notification.open({
    message,
    description,
    duration: 2,
    icon,
    className,
    placement: "topRight",
  });
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const generateRandomThemeColor = (theme) => {
  let color = "#";
  switch (theme) {
    case "dark":
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 3; i++)
        // eslint-disable-next-line no-restricted-properties
        color += `0${Math.floor((Math.random() * 16 ** 2) / 2).toString(
          16,
        )}`.slice(-2);
      return color;
    case "light":
    default:
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 3; i++)
        color += `0${Math.floor(
          // eslint-disable-next-line no-restricted-properties
          ((1 + Math.random()) * 16 ** 2) / 2,
        ).toString(16)}`.slice(-2);
      return color;
  }
};

export const refreshGrid = () => {
  // eslint-disable-next-line no-undef
  window.dispatchEvent(new Event("refresh-grid"));
};

export const formatDate = (
  datetime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  requiredAndAllowedWhiteSpace: {
    required: true,
    message: "Required",
  },
  required: {
    required: true,
    message: "Required",
    whitespace: true,
  },
  email: {
    type: "email",
    message: "Please enter valid email.",
  },
  password: {
    pattern: REGEX.PASSWORD,
    message:
      "Password should be minimum of 8 characters consisting of alphanumeric, special characters only!",
  },
  phoneNumber: {
    pattern: REGEX.PHONE,
    message: "Please enter valid phone number",
  },
  groupName: {
    pattern: REGEX.GROUP_NAME,
    message: "Only single space allowed between two words",
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("Should be a valid Number");
      }
      return Promise.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, "");

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export const trimWhiteSpace = (formInstance, formData, fieldsToChange) => {
  const changedField = formData?.[0]?.name[0];
  if (changedField && fieldsToChange.includes(changedField)) {
    formInstance.setFieldsValue({ [changedField]: formData[0]?.value?.trim() });
  }
};
export const capitalizeWord = (word) => {
  return word?.length > 0
    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    : null;
};

export const getTrimValues = (values) => {
  const newArr = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(values).map((key) => {
    // eslint-disable-next-line no-unused-expressions
    typeof values[key] === "string" && key !== "password"
      ? (newArr[key] = values[key].trim())
      : (newArr[key] = values[key]);
  });

  return newArr;
};

export const importIconMap = {
  MICROSOFT: Outlook,
  GOOGLE: GooglePlus,
  ICLOUD: Icloud,
  CSV: Csv,
  VCF: Vcf,
};
export const filterUndefinedValues = (value, type) => {
  return value && type === "address"
    ? filter(
        value,
        (e) =>
          (e?.city !== undefined && e?.city?.length !== 0) ||
          (e?.country !== undefined && e?.country?.length !== 0) ||
          (e?.id !== undefined && e?.id?.length !== 0) ||
          //! Commenting this condition as of now
          // (e?.label !== undefined && e?.label?.length !== 0) ||
          (e?.line1 !== undefined && e?.line1?.length !== 0) ||
          (e?.line2 !== undefined && e?.line2?.length !== 0) ||
          (e?.state !== undefined && e?.state?.length !== 0) ||
          (e?.zipCode !== undefined && e?.zipCode?.length !== 0),
      )
    : filter(
        value,
        (e) =>
          e?.type !== undefined && e?.value !== undefined && e?.value !== "",
      );
};

// eslint-disable-next-line no-shadow
export function AlertMessage({ icon, message, type }) {
  return <Alert type={type} message={message} icon={icon} showIcon closable />;
}

export const getFileType = (extension) => {
  if (IMAGE_EXTENSION.includes(extension)) {
    return IMAGE;
  }
  if (extension === "pdf") {
    return PDF;
  }
  if (extension === "doc" || extension === "docx" || extension === "DOC") {
    return DOC;
  }
  if (extension === "ppt" || extension === "pptx" || extension === "PPT") {
    return PPT;
  }
  if (VIDEO_EXTENSION.includes(extension)) {
    return VIDEO;
  }
  if (AUDIO_EXTENSION.includes(extension)) {
    return AUDIO;
  }
  return 0;
};

export const GroupActions = {
  changeOwner: [OWNER],
  inviteUser: [OWNER, MANAGE],
  changePermission: [OWNER, MANAGE],
  removeUser: [OWNER, MANAGE],
  reInviteUser: [OWNER, MANAGE],
  editGroup: [OWNER, MANAGE],
  deleteGroup: [OWNER],
  addContact: [OWNER, MANAGE, EDIT],
  editContact: [OWNER, MANAGE, EDIT],
  deleteContact: [OWNER, MANAGE, EDIT],
  addExistingContact: [OWNER, MANAGE, EDIT],
  addNote: [OWNER, MANAGE, EDIT],
  removeNote: [OWNER, MANAGE, EDIT],
  addDocument: [OWNER, MANAGE, EDIT],
  deleteDocument: [OWNER, MANAGE, EDIT],
  editDocument: [OWNER, MANAGE, EDIT],
  deleteContactPermanently: [OWNER, MANAGE],
};

//! Checking group permission
export const hasGroupRight = (rights, action) => {
  return GroupActions[action]?.includes(rights);
};

export const SpaceActions = {
  spaceBilling: [OWNER],
  changeOwner: [OWNER],
  publicPrivateGroup: [OWNER],
  deleteSpace: [OWNER],
  createSpace: [OWNER, ADMIN],
  editSpace: [OWNER, ADMIN],
  shareSpace: [OWNER, ADMIN],
  createGroup: [OWNER, ADMIN, MANAGE],
  editGroup: [OWNER, ADMIN, MANAGE],
  deleteGroup: [OWNER, ADMIN, MANAGE],
  shareGroup: [OWNER, ADMIN, MANAGE],
  addContact: [OWNER, ADMIN, MANAGE, EDIT],
  editContact: [OWNER, ADMIN, MANAGE, EDIT],
  deleteContact: [OWNER, ADMIN, MANAGE, EDIT],
  addExistingContact: [OWNER, MANAGE, EDIT],
  deleteTag: [OWNER, ADMIN, MANAGE],

  changePermission: [OWNER, ADMIN],
  removeUser: [OWNER, ADMIN],
  inviteUser: [OWNER, ADMIN],
  reInviteUser: [OWNER, ADMIN],
  addSpaceContactSource: [OWNER, ADMIN],
  importContacts: [OWNER, ADMIN],

  spaceSetting: [OWNER, ADMIN, MANAGE, EDIT, VIEW, LIMITED],
  spaceMembers: [OWNER, ADMIN, MANAGE, EDIT, VIEW, LIMITED],
  spaceContactSource: [OWNER, ADMIN],
  spaceImportContacts: [OWNER, ADMIN],

  cleanUpDuplicateContact: [OWNER, ADMIN, MANAGE],
  noGroupContacts: [OWNER, ADMIN, MANAGE, EDIT],

  activateReminder: [OWNER],
  deActivateReminder: [OWNER],
  pricingPage: [OWNER],
};

//! Checking space permission
export const hasSpaceRight = (rights, action) => {
  return SpaceActions[action]?.includes(rights);
};

export const createAmplitudeEvent = (eventName, eventProperties) => {
  amplitude.getInstance().logEvent(eventName, eventProperties);
};

export const getNotificationCreatedTime = (time) => {
  if (time) {
    return moment(time).calendar();
  }
};

export const getIcon = (type) => {
  if (type === PDF) {
    return <PdfIcon />;
  }
  if (type === DOC) {
    return <DocIcon className="cms-group-folder" />;
  }
  if (type === PPT) {
    return <PptIcon className="cms-group-folder" />;
  }
  if (type === IMAGE) {
    return <ImageIcon className="cms-group-folder" />;
  }
  if (type === AUDIO) {
    return <AudioIcon className="cms-group-folder" />;
  }
  if (type === VIDEO) {
    return <VideoIcon className="cms-group-folder" />;
  }
};

export const generateRandomColor = function (id) {
  return AVATAR_COLOR[parseInt(id, 10) % AVATAR_COLOR.length];
};

export const getUserLocation = () => {
  return fetch("https://ipapi.co/json/")
    .then((res) => res.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const stringToObj = (string) =>
  string
    ?.split("&")
    ?.map((keyVal) => keyVal?.split("=")?.map((val) => val?.trim()))
    ?.reduce((acc, curr) => {
      // eslint-disable-next-line prefer-destructuring
      acc[curr[0]] = curr[1];
      return acc;
    }, {});

export const getCurrencySymbol = (currency) => {
  return currency === "inr" ? "Rs." : "$";
};

export const getDateDifference = (date1, date2, diffIn) => {
  return moment(date1).diff(date2, diffIn);
};

export const setProgressPercentage = (
  totalDocumentSize,
  totalStorageAllocated,
) => {
  return (totalDocumentSize * 100) / totalStorageAllocated;
};

export const isPlanActive = (planStatus, endDate) => {
  if (
    planStatus === PLAN_STATUS.ACTIVE ||
    planStatus === PLAN_STATUS.CANCELLED ||
    planStatus === PLAN_STATUS.TRIAL
  ) {
    if (!moment(moment().toISOString()).isAfter(endDate)) {
      return true;
    }
    return false;
  }
};

export const removeAutoTags = (tags) => {
  if (tags.length !== 0) {
    let filTags;
    // eslint-disable-next-line prefer-const
    filTags = without(
      tags,
      "no-email",
      "no-address",
      "no-phone",
      "no-company",
      "no-last-name",
      "no-first-name",
      "no-country-code",
      "source-microsoft",
      "source-google",
      "source-csv",
      "source-vcf",
      //! commenting this for now
      // "source-mobile"
    );
    return filTags;
  }
  return tags;
};

export const daysInYear = (year) => {
  return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
};

export const setHttps = (link) => {
  if (link.search(/^http[s]?:\/\//) === -1) {
    const newLink = `https://${link}`;
    return newLink;
  }
  return link;
};

export const filterMergeValue = (field, type) => {
  const filterValue = [];
  field?.map((val) =>
    // eslint-disable-next-line no-nested-ternary
    type === "tags"
      ? filterValue.push(val?.value)
      : type === "groups"
        ? filterValue.push(val?.id)
        : filterValue.push({ type: val?.type, value: val?.value }),
  );
  return filterValue;
};

export const isAddressNull = (address) => {
  let bool;
  if (address?.length) {
    address?.map(
      // eslint-disable-next-line no-return-assign
      (addr) =>
        (bool =
          isEmpty(addr?.city) &&
          isEmpty(addr?.state) &&
          isEmpty(addr?.zipCode) &&
          isEmpty(addr?.country) &&
          isEmpty(addr?.line1) &&
          isEmpty(addr?.line2)),
    );
    return bool;
  }
  return true;
};

export const getAutoTags = (tags) => {
  if (tags.length !== 0) {
    const formTag = [];
    tags.map((tag) => (AUTO_TAGS.includes(tag) ? formTag.push(tag) : ""));
    return formTag;
  }
  return tags;
};

export const showContactDetail = (jobTitle, company, emails, numbers) => {
  if (jobTitle || company) {
    return (
      <span
        title={`${jobTitle || ""}${jobTitle && company ? ", " : ""}${
          company || ""
        }`}
      >
        {jobTitle}
        {jobTitle && company && ", "}
        {company}
      </span>
    );
  }
  if (emails) {
    return <span title={emails?.[0]?.value}>{emails?.[0]?.value}</span>;
  }
  if (numbers) {
    return <span title={numbers?.[0]?.value}>{numbers?.[0]?.value}</span>;
  }
};

export const getAddress = (info) => {
  const fields = [
    info?.line1,
    info?.line2,
    info?.city,
    info?.state,
    info?.country,
    info?.zipCode,
  ];
  return fields.filter((n) => n).join(", ");
};

export const getFullContactName = (firstName, middleName, lastName) => {
  const name = `${firstName ?? ""} ${middleName ?? ""} ${lastName ?? ""}`;
  return name?.trim();
};

export const isMobileBrowser = (route, width, spaceId) => {
  if (width <= WINDOW_SIZE) {
    return `${ROUTES?.CONTACTS}/${spaceId}/all`;
  }
  return route;
};

export const handleCsvDownload = (
  data,
  name = `data-${new Date().toISOString()}.csv`,
) => {
  // eslint-disable-next-line no-undef
  const a = document.createElement("a");
  a.href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(data)}`;
  a.target = "_Blank";
  a.download = name;
  // eslint-disable-next-line no-undef
  document.body.appendChild(a);
  a.click();
  // eslint-disable-next-line no-undef
  document.body.removeChild(a);
};

export const handleVcfDownload = (
  data,
  name = `data-${new Date().toISOString()}.csv`,
) => {
  const blob = new Blob([data], { type: "text/vcard" });
  // eslint-disable-next-line no-undef
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getSearchParams = (urlParams) => {
  const searchParams = new URLSearchParams(urlParams)?.get("search");
  return searchParams;
};

export const getNameTag = (name, company) => {
  // eslint-disable-next-line no-nested-ternary
  const nameTag = name?.trim()?.length
    ? name
    : company?.trim()?.length
      ? company
      : "No Name";

  return nameTag;
};
