import React from "react";
import { message, Modal } from "antd";

function ShowContactPublicLinkPopup({
  visible,
  removeNote,
  link,
  closeText = "Close",
  modalClass,
  maskClosable = true,
}) {
  const { confirm } = Modal;

  const handleCopy = () => {
    window.navigator.clipboard.writeText(link);
    message.success("Link copied to clipboard");
    // take grant to open link in new tab
    confirm({
      title: "Would you like to open link in new tab!",
      content:
        "When you clicked the Yes, this this link will open in a new tab.",
      onOk() {
        window.open(link, "_blank");
      },
      onCancel() {},
    });
  };
  return (
    <Modal
      className={`${modalClass || ""} cms-confirm-popup`}
      open={visible && link !== ""}
      onCancel={removeNote}
      centered
      cancelText={closeText}
      maskClosable={maskClosable}
      onOk={() => handleCopy(link)}
      okText="Copy link & open"
      okButtonProps={{ className: "ok" }}
    >
      <div className="popup-content">
        <div>
          <span className="popup-text-title font-inter">
            Your Shareable Link:
          </span>
          <div className="popup-text-content">
            <div className="link-contact-content">
              <span className="popup-text-content-body margin-right cms-text-bold">
                {link}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ShowContactPublicLinkPopup;
