import React from "react";

//! User files
import ContactSourceListItem from "./ContactSourceListItem";

function ContactSourceList({
  data,
  handleFetching,
  hideFetching,
  removeContactSource,
  removeLoader,
  handleUpdateSource,
  status,
  rowId,
  setOpen,
  open,
}) {
  return (
    <div className="cms-account-list">
      <div className="cms-account-list-items">
        {data?.map((item, i) => (
          <ContactSourceListItem
            item={item}
            key={item?.id}
            index={i}
            count={data.length}
            handleFetching={handleFetching}
            hideFetching={hideFetching}
            removeContactSource={removeContactSource}
            removeLoader={removeLoader}
            handleUpdateSource={handleUpdateSource}
            status={status}
            rowId={rowId}
            setOpen={setOpen}
            open={open}
          />
        ))}
      </div>
    </div>
  );
}

export default ContactSourceList;
