import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

//! Antd imports
import { Card, Form, Space, Tag, Typography } from "antd";
import { ThumbsUp } from "../../../assets/svg";
import Error404 from "../../../Error404";
import IconButton from "../../../common/components/IconButton/IconButton";
import ImportContacts from "./ImportContactsCard";
import ConnectServiceCards from "../../../common/components/ConnectServiceCards/ConnectServiceCards";

//! Graphql files
import {
  CONNECT_WITH_GOOGLE,
  CONNECT_WITH_MICROSOFT,
} from "../graphql/Queries";

function ContactSourceCard({
  connectServiceRef,
  current,
  importRef,
  setIsImportContact,
  isImportContact,
}) {
  const location = useLocation();
  const [form] = Form.useForm();
  const [connect, { error, data }] = useLazyQuery(CONNECT_WITH_GOOGLE);
  const [connectMicrosoft, { data: microsoftData, error: microsoftError }] =
    useLazyQuery(CONNECT_WITH_MICROSOFT);
  const [service, setService] = useState("google");
  const currStep = current + 1;

  useEffect(() => {
    if (data) {
      window.location.href = data.connectWithGoogle.authorizationUri;
    } else if (microsoftData) {
      window.location.href =
        microsoftData.connectWithMicrosoft.authorizationUri;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location, microsoftData]);

  if (error || microsoftError) return <Error404 />;

  const onFinish = () => {
    if (service === "google") {
      connect();
    } else if (service === "outlook") {
      connectMicrosoft();
    }
  };

  const renderImportContact = () => {
    setIsImportContact(!isImportContact);
  };

  return (
    <>
      {!isImportContact ? (
        <div className="import-card">
          <Card className="import-card card-hover">
            <Form onFinish={onFinish} form={form}>
              <Space direction="vertical" size="middle">
                <div className="d-flex-center mb-4 cms-source-wrapper">
                  <Typography.Text className="font-inter import-card-title">
                    Please connect a source
                  </Typography.Text>
                  <Tag
                    icon={<ThumbsUp className="import-card-title-tag-icon" />}
                    className="font-inter import-card-title-tag"
                  >
                    <span className="font-inter import-card-title-tag-text">
                      Recommended
                    </span>
                  </Tag>
                </div>
                <ConnectServiceCards
                  setService={setService}
                  service={service}
                />
                <IconButton
                  iconClass="hide import-card-button"
                  type="primary"
                  htmlType="submit"
                  text="Connect service"
                  buttonRef={connectServiceRef}
                />
              </Space>
            </Form>
          </Card>
        </div>
      ) : (
        <ImportContacts importRef={importRef} currStep={currStep} />
      )}
      <div className="cms-import-contact-link mb-32">
        <span className="font-inter cms-import-contact-link-or-text">OR</span>
        <span
          className="font-inter cms-import-contact-link-import-text cursor-pointer"
          onClick={renderImportContact}
        >
          {isImportContact ? "Connect Source" : "Import Contacts"}
        </span>
      </div>
    </>
  );
}

export default ContactSourceCard;
