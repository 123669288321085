import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useLazyQuery } from "@apollo/client";

//! Antd imports
import { List } from "antd";

//! User files
import { DuplicateIcon } from "../../../../../assets/svg";
import CountTag from "../../../../../common/components/CountTag";
import { AppContext } from "../../../../../AppContext";
import { SET_USER_SPACE_PERMISSION } from "../../../../../common/actionTypes";

//! Graphql files
import { GET_CLEAN_UP_SPACE_INFO } from "../../../graphql/queries/getCleanUpSpaceInfo";

function AssistantListItem({ spaceId }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [defaultRoute, setDefaultRoute] = useState();
  const {
    dispatch,
    state: { duplicateContactCount },
  } = useContext(AppContext);
  const paramSpaceId = pathname?.split("/")[2];
  const [getSpaceDetail] = useLazyQuery(GET_CLEAN_UP_SPACE_INFO, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      dispatch({
        type: SET_USER_SPACE_PERMISSION,
        data: data?.space?.permission,
      });
    },
  });

  const cleanUpTypes = [
    {
      name: "Duplicates",
      icon: <DuplicateIcon />,
      pathLink: `/clean-up/${spaceId}/duplicates`,
      count: duplicateContactCount ?? 0,
    },
    //! Keeping this comment until backend is done
    // {
    //   name: "Incorrect data",
    //   icon: <IncorrectIcon />,
    //   pathLink: `/clean-up/${spaceId}/incorrect-data`,
    //   count: 0,
    // },
  ];

  useEffect(() => {
    if (cleanUpTypes.length > 0) {
      const firstItem = cleanUpTypes[0];
      if (firstItem?.name === "Duplicates") {
        setDefaultRoute(firstItem.pathLink);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paramSpaceId) {
      getSpaceDetail({
        variables: {
          id: parseInt(paramSpaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSpaceId]);

  useEffect(() => {
    if (
      defaultRoute &&
      (paramSpaceId ? defaultRoute?.split("/")?.includes(paramSpaceId) : 1)
    ) {
      if (defaultRoute && !pathname.includes("merge-contact")) {
        navigate(defaultRoute);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultRoute]);

  return (
    <div>
      <List
        className="assistant-list-wrapper"
        dataSource={cleanUpTypes}
        renderItem={(item) => {
          const { name, icon, pathLink, right, count } = item;
          const isSelected = pathname.includes(pathLink);
          return (
            <List.Item
              className={clsx(
                isSelected && "selected-list",
                right && "space-right",
              )}
            >
              <List.Item.Meta
                className="cms-assistant-text-ellipsis"
                avatar={icon}
                title={
                  <Link
                    to={{
                      pathname: pathLink,
                      state: { name },
                    }}
                  >
                    <span className="cms-assistant-ellipsis">{name}</span>
                    <CountTag count={count} />
                  </Link>
                }
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
}

export default AssistantListItem;
