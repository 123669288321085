import React, { useState, useRef, useContext, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import find from "lodash/find";
import _isInteger from "lodash/isInteger";

//! Antd imports
import { Modal } from "antd";

//! User files
import { AppContext } from "../../../../../AppContext";
import SpaceSharingComponent from "../../../../../common/components/SpaceSharingComponent/SpaceSharingComponent";
import { SET_IS_GSUITE_ADMIN } from "../../../../../common/actionTypes";
import {
  notificationToast,
  validateEmail,
  createAmplitudeEvent,
} from "../../../../../common/utils";
import {
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  GOOGLE,
  USER,
} from "../../../../../common/constants";
import { CloseBlack } from "../../../../../assets/svg";
import UpgradePlanPopup from "../../../../../common/components/UpgradePlanPopup/UpgradePlanPopup";

//! Graphql files
import { ADD_MEMBER_TO_SPACE } from "../../../graphql/mutations/addMemberToSpace";
import { REMOVE_MEMBER_FROM_SPACE } from "../../../graphql/mutations/removeMemberFromSpace";
import { UPDATE_SPACE_PERMISSION } from "../../../graphql/mutations/updateSpacePermission";
import {
  CHECK_ADMIN_USER_AND_DOMAIN_WIDE_ACCESS,
  LIST_DOMAIN_USERS_AND_GROUPS,
} from "../../../../onboard/graphql/Queries";
import SHARE_MULTIPLE_GROUP_TO_USER from "../../../graphql/mutations/shareMultiGroupToUser";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";
import { GET_SPACE_MEMBERS } from "../../../graphql/queries/getSpaceMembers";

function SpaceShareModal({
  setOpenShareModal,
  openShareModal,
  members,
  spaceId,
  groupCheckList,
  setGroupCheckList,
  checkAll,
  setCheckAll,
}) {
  const [permission, setPermission] = useState("VIEW");
  const [modalVisible, setModalVisible] = useState();
  const [email, setEmail] = useState();
  const [domainUsers, setDomainUsers] = useState();
  const [groupMembers, setGroupMembers] = useState([]);
  const inputFocus = useRef();
  const {
    state: { userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const groupId = pathname.split("/")[4];

  const [checkAdminUserAndDomainAccess] = useLazyQuery(
    CHECK_ADMIN_USER_AND_DOMAIN_WIDE_ACCESS,
    {
      fetchPolicy: "network-only",
      onCompleted(adminData) {
        dispatch({
          type: SET_IS_GSUITE_ADMIN,
          data: adminData?.checkAdminUserAndDomainWideAccess?.isAdmin,
        });
      },
      onError() {},
    },
  );

  const [listDomainUsersAndGroups, { data: domainMembers }] = useLazyQuery(
    LIST_DOMAIN_USERS_AND_GROUPS,
    {
      onCompleted() {},
      onError() {},
    },
  );

  const [getSpaceMembers, { data: spaceMember }] = useLazyQuery(
    GET_SPACE_MEMBERS,
    {
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(spaceId, 10),
        data: {
          offset: 0,
          limit: CONTACT_LIMIT,
        },
      },
    },
  );

  const [multipleGroupSharing, { loading: sharingLoader }] = useMutation(
    SHARE_MULTIPLE_GROUP_TO_USER,
    {
      refetchQueries: [
        {
          query: GET_SPACE_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
            data: {
              offset: 0,
              limit: CONTACT_LIMIT,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
      onError() {},
    },
  );

  const [getGroupInfo] = useLazyQuery(GET_GROUP_INFO_V2, {
    fetchPolicy: "network-only",
    onError() {},
  });

  const [addMemberToSpace, { loading: memberLoading }] = useMutation(
    ADD_MEMBER_TO_SPACE,
    {
      refetchQueries: [
        {
          query: GET_SPACE_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
            data: {
              offset: 0,
              limit: CONTACT_LIMIT,
            },
          },
        },
      ],
    },
  );

  const [removeMember, { loading: removeLoader }] = useMutation(
    REMOVE_MEMBER_FROM_SPACE,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_SPACE_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
            data: {
              offset: 0,
              limit: CONTACT_LIMIT,
            },
          },
        },
      ],
      onCompleted() {
        setModalVisible(false);
      },
    },
  );

  const [updatePermission] = useMutation(UPDATE_SPACE_PERMISSION, {
    onCompleted() {},
  });

  const handleAddMember = () => {
    if (!email) {
      inputFocus?.current?.focus();
      return;
    }
    if (
      !find(
        spaceMember?.spaceMembers?.data?.members,
        (e) => e?.email === email,
      ) &&
      spaceMember?.spaceMembers?.data?.membersCount >=
        userSubscriptionInfo?.subscription?.quantity
    ) {
      //! Will remove this after testing
      // dispatch({
      //   type: SET_SPACE_PLAN_STATUS,
      //   data: {
      //     planExpired: true,
      //     message: "Space sharing limit has been exceeded!",
      //   },
      // });
      notificationToast({
        type: "error",
        message: "Space sharing limit has been exceeded!",
      });
      setEmail("");
      setPermission("VIEW");
      return false;
    }
    if (email) {
      const isValidEmail = validateEmail(email);
      if (isValidEmail) {
        const isUserInvited = find(groupMembers, (e) => e.email === email);
        if (isUserInvited) {
          notificationToast({
            message: `User ${email} is already is the list`,
            type: "warning",
          });
        } else {
          if (
            userSubscriptionInfo?.type === GOOGLE &&
            groupCheckList?.length > 0
          ) {
            const emailType = find(
              domainUsers,
              (e) => e?.email === email,
            )?.type;
            multipleGroupSharing({
              variables: {
                groupIds: checkAll ? [] : groupCheckList,
                data: {
                  permissions: permission,
                  spaceId: parseInt(spaceId, 10),
                  email: email?.toLowerCase(),
                  emailType: emailType || USER,
                },
              },
            })
              .then((data) => {
                if (data?.data?.shareMultiGroupToUser?.status === "SUCCESS") {
                  const id = Math.floor(Math.random() * 10) + 1;
                  setGroupMembers([
                    ...groupMembers,
                    {
                      email: email?.toLowerCase(),
                      permissions: permission,
                      name: email?.toLowerCase(),
                      id: id,
                    },
                  ]);
                  if (groupId !== "all") {
                    getGroupInfo({
                      variables: {
                        id: parseInt(groupId, 10),
                      },
                    });
                  }
                  setPermission("VIEW");
                  const eventProperties = {
                    "Space Id": spaceId,
                    "Plan Name":
                      userSubscriptionInfo?.subscription?.product?.name,
                    "Plan status":
                      userSubscriptionInfo?.subscription?.planStatus,
                    "Group Id": groupId,
                    "Shared with": email,
                  };
                  createAmplitudeEvent(
                    AMPLITUDE_EVENT_LOG.GROUP_SHARED,
                    eventProperties,
                  );
                }
              })
              .catch(() => {});
          } else {
            addMemberToSpace({
              variables: {
                data: {
                  permissions: permission,
                  spaceId: parseInt(spaceId, 10),
                  email: email?.toLowerCase(),
                },
              },
            }).then(() => {
              setPermission("VIEW");
              const eventProperties = {
                "Space Id": spaceId,
                "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
                "Plan status": userSubscriptionInfo?.subscription?.planStatus,
                "Shared With": email,
              };
              createAmplitudeEvent(
                AMPLITUDE_EVENT_LOG.SPACE_SHARED,
                eventProperties,
              );
            });
          }
          setEmail("");
        }
      } else {
        notificationToast({
          message: `Please enter valid email`,
          type: "error",
        });
      }
    }
  };

  const handleRemoveMember = (memberId, memberName) => {
    if (memberId) {
      removeMember({
        variables: {
          id: memberId,
        },
      })
        .then(() => {
          const eventProperties = {
            "Space Id": spaceId,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
            "Removed from": memberId,
            "User Name": memberName,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.SPACE_REVOKED,
            eventProperties,
          );
        })
        .catch(() => {});
    }
  };

  const handleMemberPermission = (value, memberId) => {
    if (value && memberId) {
      updatePermission({
        variables: {
          id: parseInt(memberId, 10),
          permissions: value,
          spaceId: parseInt(spaceId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (userSubscriptionInfo?.type === GOOGLE) {
      checkAdminUserAndDomainAccess();
      listDomainUsersAndGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptionInfo]);

  useEffect(() => {
    if (domainMembers?.listDomainUsersAndGroups?.users?.length > 0) {
      setDomainUsers([...domainMembers.listDomainUsersAndGroups.users]);
    }
  }, [domainMembers]);

  useEffect(() => {
    if (spaceId && _isInteger(parseInt(spaceId, 10))) {
      getSpaceMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    setGroupMembers([...members, ...groupMembers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  const handleCloseModal = () => {
    setOpenShareModal(false);
    setGroupCheckList([]);
    setGroupMembers([...members]);
    setEmail(null);
    setCheckAll(false);
  };

  return (
    <Modal
      className={`${
        sharingLoader || removeLoader ? "cms-disable-close-btn" : ""
      } cms-space-sharing-modal`}
      open={openShareModal}
      footer={null}
      centered
      title={
        <span className="font-inter cms-shared-space-title">
          Share selected groups
        </span>
      }
      onCancel={handleCloseModal}
      closeIcon={<CloseBlack />}
      maskClosable={false}
    >
      <div className="cms-space-share-section">
        <SpaceSharingComponent
          noFlex
          email={email}
          setEmail={setEmail}
          inputFocus={inputFocus}
          setPermission={setPermission}
          members={groupMembers}
          setVisible={setModalVisible}
          visible={modalVisible}
          handleAddMember={handleAddMember}
          memberLoading={memberLoading}
          handleRemoveMember={handleRemoveMember}
          removeLoader={removeLoader}
          handleMemberPermission={handleMemberPermission}
          isGoogleSpace={userSubscriptionInfo?.type === GOOGLE}
          domainUsers={domainUsers}
          sharingLoader={sharingLoader}
          openShareModal={openShareModal}
          membersCount={spaceMember?.spaceMembers?.data?.membersCount}
        />
        {spaceMember?.spaceMembers?.data?.membersCount >=
          userSubscriptionInfo?.subscription?.quantity && (
          <UpgradePlanPopup
            title="You've reached your contact sharing limit"
            content="Share with more users by upgrading plan to higher number of users"
            spaceId={parseInt(spaceId, 10)}
          />
        )}
      </div>
    </Modal>
  );
}

export default SpaceShareModal;
