import React from "react";

//! User files
import TagEmptyStateIcon from "../../../assets/images/tagEmptyGIF.gif";
import RemindersGIF from "../../../assets/images/reminders.gif";
import { EMPTY_STATE_REMINDER_TEXT } from "../../constants";

function TagEmptyState({ text, link }) {
  return (
    <div className="cms-tag-empty-state">
      <span className="font-inter cms-tag-empty-state-title">
        {text || "Organize your contacts using tags"}
      </span>
      <span className="cms-tag-empty-state-image">
        <img src={link ? RemindersGIF : TagEmptyStateIcon} alt="tags" />
      </span>
      <span
        className={`${
          text ? "ft-18" : ""
        } font-inter cms-tag-empty-state-content`}
      >
        {text
          ? EMPTY_STATE_REMINDER_TEXT
          : "Organise your contacts even further by using Tags. It gives a filtered view of all your contacts. You can easily create tags and add them on your contacts. You will see a list of tags and contacts within them in Tags view of your Dashboard."}
      </span>
      <span className="font-inter cms-tag-empty-state-content">
        <a
          href={
            link ||
            "https://www.contactbook.app/help/organize-contacts-with-tags"
          }
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </a>
      </span>
    </div>
  );
}

export default TagEmptyState;
