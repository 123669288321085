import React from "react";

//! User files
import Loading from "../../../../../common/components/Loading";
import SyncLogListItem from "./SyncLogListItem";

function SyncLogList({ syncLogs, loading }) {
  return (
    <>
      <div className="cms-notification-list cms-contact-scrollbar">
        {syncLogs?.map((logs) => (
          <SyncLogListItem logs={logs} key={logs?.id} />
        ))}
      </div>
      {loading && (
        <div className="cms-infinite-scroll-loader">
          <Loading />
        </div>
      )}
    </>
  );
}

export default SyncLogList;
