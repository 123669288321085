import React, { useState } from "react";

//! Antd import
import { Avatar } from "antd";

function ContactAvatar({ name, color, size, photo }) {
  const [imgLoadError, setImgLoadError] = useState(false);

  return (
    <Avatar
      src={imgLoadError ? "" : photo}
      size={size}
      style={
        !photo || imgLoadError
          ? {
              backgroundColor: color,
            }
          : {
              backgroundColor: "transparent",
            }
      }
      onError={() => setImgLoadError(true)}
    >
      {name?.[0]?.toUpperCase()}
    </Avatar>
  );
}

export default ContactAvatar;
