import React from "react";
import { useLocation } from "react-router-dom";
import { ADMIN, MANAGE, OWNER, VIEW, EDIT, LIMITED } from "./constants";
import NoAccessComponent from "./components/NoAccessComponent/NoAccessComponent";

function NoAccessFile({ children, spacePermission }) {
  const { pathname } = useLocation();

  // eslint-disable-next-line no-nested-ternary
  return pathname.includes("clean-up")
    ? (spacePermission !== null || spacePermission !== undefined) &&
      (spacePermission === OWNER ||
        spacePermission === ADMIN ||
        spacePermission === MANAGE)
      ? children
      : (spacePermission !== null || spacePermission !== undefined) &&
        (spacePermission === VIEW ||
          spacePermission === EDIT ||
          spacePermission === LIMITED) && (
          <div className="empty-screen-layout">
            <NoAccessComponent />
          </div>
        )
    : children;
}

export default NoAccessFile;
