import React from "react";

//! User files
import { ProgressDone } from "../../../assets/svg";

function ProgressScreen() {
  return (
    <div className="cms-import-done-wrapper">
      <div className="cms-import-done-wrapper-sign flex-all-center">
        <span className="cms-import-done-wrapper-sign-icon">
          <ProgressDone />
        </span>
        <span className="cms-import-done-wrapper-sign-text">
          Contact synced successfully!
        </span>
        <span className="cms-import-done-wrapper-sign-content mt-8">
          Congratulations! Your Google Contacts and labels are now in sync with
          ContactBook. Any changes will appear on both the platforms.
        </span>
      </div>
    </div>
  );
}

export default ProgressScreen;
