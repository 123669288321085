import React, { useEffect, useState } from "react";

//! Antd import
import { Modal, Radio, Space } from "antd";

//! User files
import { generateRandomColor } from "../../utils";
import UserAvatar from "../UserAvatar/UserAvatar";

function ChangeOwnerPopup({
  visible,
  okText,
  loading,
  closePopup,
  handleChangeOwner,
  content,
  title,
  ownerId,
}) {
  const [id, setId] = useState();
  const [groupOwner, setGroupOwner] = useState();
  const handleValueChange = (e, name) => {
    setId(e?.target?.value);
    setGroupOwner(name);
  };

  useEffect(() => {
    if (ownerId > 0) {
      setId(ownerId);
    }
  }, [ownerId]);

  return (
    <Modal
      className="cms-owner-change-popup"
      open={visible}
      onCancel={closePopup}
      onOk={() => handleChangeOwner(id, groupOwner)}
      centered
      closable={false}
      okText={okText}
      cancelText="Close"
      confirmLoading={loading}
      title={title}
      okButtonProps={{ disabled: ownerId === parseInt(id, 10) }}
    >
      <div className="popup-content">
        <Radio.Group defaultValue={ownerId?.toString()}>
          <Space direction="vertical">
            {content?.map((user) => (
              <Radio
                value={user?.userId?.toString() ?? user?.id?.toString()}
                onChange={(e) => handleValueChange(e, user?.username)}
                key={user?.userId?.toString() ?? user?.id?.toString()}
              >
                <UserAvatar
                  className="cms-change-owner-avatar"
                  photo={user?.photo}
                  name={user?.name ?? user?.username}
                  color={generateRandomColor(parseInt(user?.id, 10))}
                />
                <span className="cms-font-ellipsis">
                  {user?.name ?? user?.username}
                </span>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </Modal>
  );
}

export default ChangeOwnerPopup;
