import React from "react";

//! User files
import { Member } from "../../../assets/svg";

function NoLabelFound() {
  return (
    <div className="cms-no-label-found">
      <div className="cms-no-label-found-title d-flex-center font-inter mb-24">
        No labels found in your Google Contacts!
      </div>
      <span className="cms-no-label-found-icon mb-24">
        <Member />
      </span>
      <div className="cms-no-label-found-description">
        In order to <span className="text-bold">share contacts</span> with your
        team, you will need to organize them in labels or groups. You can do
        that in the Dashboard or in Google Contacts.
      </div>
    </div>
  );
}

export default NoLabelFound;
