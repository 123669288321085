import React, { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

//! Antd import
import { Col } from "antd";

function DetailPanel({ children, hidePaths }) {
  const [span, setSpan] = useState(12);
  const location = useLocation();

  useEffect(() => {
    const shouldExpand = hidePaths.some((path) =>
      matchPath(path, location.pathname),
    );
    setSpan(shouldExpand ? 20 : 12);
  }, [location.pathname, hidePaths]);

  return (
    <Col
      span={span}
      className="dashboard-contact-detail-col cms-contact-scrollbar h-inherit"
    >
      {children}
    </Col>
  );
}

export default DetailPanel;
