import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

//! Antd import
import { Button, Col, Form, Input, Row, Typography } from "antd";

//! Graphql files
import { CHANGE_PASSWORD } from "../../../graphql/mutations/changePassword";

//! User files
import check from "../../../../../assets/images/check.png";
import { ROUTES } from "../../../../../common/constants";
import {
  formValidatorRules,
  notificationToast,
} from "../../../../../common/utils";
import { CloseEyeIcon, OpenEyeIcon } from "../../../../../assets/svg";

const { required, password } = formValidatorRules;
const { Title } = Typography;

function ChangePassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onError() {},
  });
  const [passwordChanged, setPasswordChanged] = useState(false);

  const onFinish = (e) => {
    // eslint-disable-next-line no-shadow
    const { oldPassword, newPassword, confirmPassword } = e;
    if (oldPassword === newPassword) {
      notificationToast({
        message: "Old password and new password cannot be same",
        type: "error",
      });
      return 0;
    }
    if (newPassword !== confirmPassword) {
      notificationToast({
        message: "New password and confirm password should be same",
        type: "error",
      });
      return 0;
    }
    changePassword({ variables: { oldPassword, newPassword } }).then((data) => {
      if (data) {
        setPasswordChanged(true);
        setTimeout(() => {
          navigate(ROUTES.LOGOUT);
        }, 2000);
      }
    });
  };
  return (
    <div className="create-group-update-modal">
      <Title
        level={3}
        className="font-inter group-list-heading cms-text-strong"
      >
        Change Password
      </Title>
      <div className="create-group-area">
        <div className="create-group-form">
          {passwordChanged ? (
            <div className="success-box">
              <img src={check} alt="success" className="success-box-image" />
              <div className="success-box-message">
                Your password has been changed successfully
              </div>
            </div>
          ) : (
            <div className="change-password-form">
              <Form
                layout="vertical"
                onFinish={onFinish}
                form={form}
                requiredMark={false}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Old password"
                      name="oldPassword"
                      rules={[required, password]}
                      validateTrigger={["onPressEnter"]}
                    >
                      <Input.Password
                        placeholder="Enter old password"
                        // eslint-disable-next-line react/no-unstable-nested-components
                        iconRender={(visible) =>
                          visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="New password"
                      name="newPassword"
                      rules={[required, password]}
                      validateTrigger={["onPressEnter"]}
                    >
                      <Input.Password
                        placeholder="Enter new password"
                        // eslint-disable-next-line react/no-unstable-nested-components
                        iconRender={(visible) =>
                          visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Confirm password"
                      name="confirmPassword"
                      rules={[required, password]}
                      validateTrigger={["onPressEnter"]}
                    >
                      <Input.Password
                        placeholder="Confirm new password"
                        // eslint-disable-next-line react/no-unstable-nested-components
                        iconRender={(visible) =>
                          visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="cms-submit-change-pwd-btn">
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="change-password-button"
                    loading={loading}
                  >
                    Change Password
                  </Button>
                </Row>
              </Form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
