import React, { useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

//! User files
import { ReminderIcon } from "../../../../../assets/svg";
import RemindersGIF from "../../../../../assets/images/reminders.gif";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { OWNER } from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import {
  SET_USER_SPACE_PERMISSION,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../../../../common/actionTypes";

//! Graphql files
import { ACTIVATE_REMINDER } from "../../../graphql/mutations/activateReminder";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";

function ActivateReminders() {
  const { spaceId } = useParams();
  const navigate = useNavigate();
  const {
    dispatch,
    state: { userSpacePermission },
  } = useContext(AppContext);

  const { data: spaceDetail, loading: spaceLoading } = useQuery(
    GET_SPACE_DETAIL_V2,
    {
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(spaceId, 10),
      },
      onCompleted(spaceData) {
        dispatch({
          type: SET_USER_SUBSCRIPTION_INFO,
          data: spaceData?.spaceV2,
        });
        dispatch({
          type: SET_USER_SPACE_PERMISSION,
          data: spaceData?.spaceV2?.permission,
        });
      },
    },
  );

  const [activateReminders, { loading }] = useMutation(ACTIVATE_REMINDER, {
    variables: {
      spaceId: parseInt(spaceId, 10),
    },
    onCompleted() {
      navigate({
        pathname: `/addOns/${spaceId}/reminders`,
        state: { isReminderActivated: true },
      });
    },
    onError() {},
  });

  useEffect(() => {
    if (spaceDetail?.spaceV2?.spaceAddon?.active && !spaceLoading) {
      navigate(`/addOns/${spaceId}/reminders`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDetail, spaceId]);

  return (
    <div className="cms-reminders-activate">
      <div className="cms-reminders-activate-state">
        <ReminderIcon className="cms-reminders-activate-state-svg-icon" />
        <span className="font-inter cms-reminders-activate-state-title mt-16">
          Reminders
        </span>
        <span className="cms-reminders-activate-state-image">
          <img src={RemindersGIF} alt="Reminders" />
        </span>
        <span className="font-inter cms-reminders-activate-state-content mb-32">
          Keep your relationships warm with smart reminders. Easily set up
          reminders on your contacts so you get an update when it's time to
          connect with them.
        </span>
        <IconButton
          iconClass={`dropdown-menu-button-section ${
            userSpacePermission !== OWNER
              ? "cms-request-admin cms-request-admin-padding"
              : ""
          }`}
          type="primary"
          text={
            userSpacePermission === OWNER ? (
              <span className="font-inter dropdown-menu-button-section-text">
                Activate
              </span>
            ) : (
              <span className="font-inter cms-request-admin-text">
                Request admin to activate
              </span>
            )
          }
          handleClick={activateReminders}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ActivateReminders;
