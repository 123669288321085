import React from "react";

//! User files
import NotificationListItemCard from "./NotificationListitemCard";

function NotificationListItem({ notification, closeDrawer }) {
  return (
    <div>
      {notification?.map((item, key) => (
        <NotificationListItemCard
          notification={item}
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          closeDrawer={closeDrawer}
        />
      ))}
    </div>
  );
}

export default NotificationListItem;
