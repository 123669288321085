import React from "react";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";

//! User Files
import { Skeleton } from "antd";

//! User Files
import ContactData from "./ContactData";
import ContactTabsContainer from "./ContactTabsContainer";
import { WINDOW_SIZE } from "../../../../../common/constants";

function ContactView({ contactDetails, loading, setContactDetails }) {
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  return (
    <div className={width <= WINDOW_SIZE ? "scroll-y" : null}>
      {loading ? (
        <div className="contact-user-info-wrapper-skeleton">
          <Skeleton avatar paragraph={{ rows: 3 }} title={false} active />
        </div>
      ) : (
        <ContactData
          contactDetails={contactDetails}
          setContactDetails={setContactDetails}
        />
      )}
      {!pathname.includes("reminders") && (
        <ContactTabsContainer
          contactPermission={contactDetails?.isEditable}
          contactDetail={contactDetails}
        />
      )}
    </div>
  );
}

export default ContactView;
