import React, { useContext } from "react";
import { Alert, Modal, Radio, Space } from "antd";

//! User files
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { AppContext } from "../../../../../AppContext";
import { AlertIcon } from "../../../../../assets/svg";

function ExportContactModal({
  setExportContactModal,
  exportContactModal,
  exportContactFormat,
  setExportContactFormat,
  manageExportContactLoader,
  handleManageExportContact,
}) {
  const {
    state: { connectSourceParams },
  } = useContext(AppContext);
  const { spaceId } = connectSourceParams;
  // eslint-disable-next-line no-unused-vars
  const currentSpace =
    parseInt(spaceId, 10) || parseInt(localStorage.getItem("currentSpace"), 10);

  const onRadioChange = (e) => {
    setExportContactFormat(e.target.value);
  };

  const handleExportContact = () => {
    handleManageExportContact();
  };

  const handleCloseModal = () => {
    setExportContactModal(false);
    setExportContactFormat();
  };
  return (
    <div>
      <Modal
        open={exportContactModal}
        width={424}
        className="multiple-group-modal-wrapper export-contact-modal-wrapper"
        centered
        title={
          <span className="font-inter cms-shared-space-title">
            Export Contacts
          </span>
        }
        onCancel={handleCloseModal}
        maskClosable={false}
        footer={
          <IconButton
            iconClass="cms-apply-btn"
            text="Export"
            handleClick={handleExportContact}
            loading={manageExportContactLoader}
            disable={exportContactFormat === undefined}
          />
        }
      >
        <div className="cms-infinite-scroll">
          <Alert
            message="Some of the contact data may can not be exported."
            type="info"
            showIcon
            icon={<AlertIcon />}
          />
          <div className="export-contact-content">
            <Radio.Group onChange={onRadioChange} value={exportContactFormat}>
              <Space direction="vertical">
                <Radio value="csv">
                  CSV file <br />
                  <p>
                    Use this option to export basic contact details in a .CSV
                    file format.
                  </p>
                </Radio>
                <Radio value="vcf">
                  VCF file <br />
                  <p>
                    Use this option to export basic contact details in a .VCF
                    file format.
                  </p>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ExportContactModal;
