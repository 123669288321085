import React, { useContext, useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";

//! Antd import
import { Form } from "antd";

//! User files
import ConnectServiceCards from "../ConnectServiceCards/ConnectServiceCards";
import IconButton from "../IconButton/IconButton";
import ImportContacts from "../../../modules/onboard/components/ImportContactsCard";
import {
  CONNECT_WITH_GOOGLE,
  CONNECT_WITH_MICROSOFT,
} from "../../../modules/onboard/graphql/Queries";
import { AppContext } from "../../../AppContext";
import { isPlanActive } from "../../utils";
import { SET_SPACE_PLAN_STATUS } from "../../actionTypes";
import { UPGRADE_PLAN_TITLE } from "../../constants";

function ConnectSourcePlaceholder() {
  const [isImportContact, setIsImportContact] = useState(false);
  const [service, setService] = useState("google");
  const [loader, setLoader] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [form] = Form.useForm();
  const importRef = useRef();
  const connectServiceRef = useRef();
  const {
    state: { userSubscriptionInfo, contactsCount },
    dispatch,
  } = useContext(AppContext);

  const [connect, { data, loading: googleLoading }] = useLazyQuery(
    CONNECT_WITH_GOOGLE,
    {
      onError() {},
    },
  );

  const [connectMicrosoft, { data: microsoftData, loading: microsoftLoading }] =
    useLazyQuery(CONNECT_WITH_MICROSOFT, {
      onError() {},
    });

  const onFinish = () => {
    if (service === "google") {
      connect();
    } else if (service === "outlook") {
      connectMicrosoft();
    }
  };

  const connectSource = () => {
    if (
      !isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      )
    ) {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
      return;
    }
    if (isImportContact) {
      importRef?.current?.click();
      if (isFileUploaded) {
        setLoader(true);
      }
    } else {
      connectServiceRef?.current?.click();
    }
  };

  useEffect(() => {
    if (data) {
      window.location.href = data.connectWithGoogle.authorizationUri;
    } else if (microsoftData) {
      window.location.href =
        microsoftData.connectWithMicrosoft.authorizationUri;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, microsoftData]);

  const renderImportContact = () => {
    setIsImportContact(!isImportContact);
  };

  return (
    <div
      className={`${
        contactsCount === 0 ? "cms-contact-list-height" : ""
      } no-contact-connect-source`}
    >
      <div className="cms-connect-source-placeholder">
        <div className="font-inter username cms-connect-source-placeholder-title mb-24">
          Connect sources to import contact from other platforms
        </div>
        <div className="cms-connect-source-placeholder-card">
          {isImportContact ? (
            <ImportContacts
              noMargin
              importRef={importRef}
              setLoader={setLoader}
              setIsFileUploaded={setIsFileUploaded}
            />
          ) : (
            <Form
              className="d-flex-center"
              onFinish={onFinish}
              form={form}
              initialValues={{ serviceName: "google" }}
            >
              <ConnectServiceCards setService={setService} service={service} />
              <IconButton
                iconClass="hide import-card-button"
                type="primary"
                htmlType="submit"
                text="Connect service"
                buttonRef={connectServiceRef}
              />
            </Form>
          )}
          <IconButton
            iconClass="hide mt-32 connect-service-btn mb-16"
            type="primary"
            htmlType="submit"
            text={isImportContact ? "Upload" : "Connect Source"}
            loading={googleLoading || microsoftLoading || loader}
            handleClick={connectSource}
            disable={isImportContact ? !isFileUploaded : false}
          />
        </div>
        <div className="cms-import-contact-link">
          <span className="font-inter cms-import-contact-link-or-text">OR</span>
          <span
            className="font-inter cms-import-contact-link-import-text cursor-pointer"
            onClick={renderImportContact}
          >
            {isImportContact ? "Connect Source" : "Import Contacts"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ConnectSourcePlaceholder;
