export const SET_TOKEN = "SET_TOKEN";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_USER_ID = "SET_USER_ID";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT = "LOGOUT";
export const SET_DRAWER_VISIBLE = "SET_DRAWER_VISIBLE";
export const SET_CURRENT_SIDE_TAB = "SET_CURRENT_SIDE_TAB";
export const GROUP_SETTING_DRAWER = "GROUP_SETTING_DRAWER";
export const CONTACT_FORM_DRAWER = "CONTACT_FORM_DRAWER";
export const SET_CONTACTS = "SET_CONTACTS";
export const SET_CONTACTS_COUNT = "SET_CONTACTS_COUNT";
export const SET_SPACES = "SET_SPACES";
export const SET_SPACES_COUNT = "SET_SPACES_COUNT";
export const SET_IS_EXISTING_CONTACT = "SET_IS_EXISTING_CONTACT";
export const SET_GROUP_LIST = "SET_GROUP_LIST";
export const SET_UNASSIGNED_CONTACTS = "SET_UNASSIGNED_CONTACTS";
export const SET_NOTIFICATION_DRAWER = "SET_NOTIFICATION_DRAWER";
export const SET_SPACE_INFO = "SET_SPACE_INFO";
export const SET_USER_GROUP_PERMISSION = "SET_USER_GROUP_PERMISSION";
export const SET_USER_SPACE_PERMISSION = "SET_USER_SPACE_PERMISSION";
export const SET_USER_SUBSCRIPTION_INFO = "SET_USER_SUBSCRIPTION_INFO";
export const SET_CONTACT_LIMIT_EXCEEDED = "SET_CONTACT_LIMIT_EXCEEDED";
export const SET_SPACE_PLAN_STATUS = "SET_SPACE_PLAN_STATUS";
export const SET_NEW_NOTIFICATION = "SET_NEW_NOTIFICATION";
export const SET_EXISTING_DONE_CLICK = "SET_EXISTING_DONE_CLICK";
export const SET_ADDED_CONTACTS_COUNT = "SET_ADDED_CONTACTS_COUNT";
export const SET_CURRENT_SELECTED_TAG = "SET_CURRENT_SELECTED_TAG";
export const SET_CURRENT_SELECTED_SPACE = "SET_CURRENT_SELECTED_SPACE";
export const SET_ACTIVE_SWITCH = "SET_ACTIVE_SWITCH";
export const SET_DUPLICATE_CARD_ID = "SET_DUPLICATE_CARD_ID";
export const SET_DUPLICATE_CONTACT_CARD = "SET_DUPLICATE_CONTACT_CARD";
export const SET_CONNECT_SOURCE_PARAMS = "SET_CONNECT_SOURCE_PARAMS";
export const SET_IMPORT_CONTACT_STATUS = "SET_IMPORT_CONTACT_STATUS";
export const SET_DUPLICATE_CONTACT_COUNT = "SET_DUPLICATE_CONTACT_COUNT";
export const SET_SYNC_LOG_DRAWER = "SET_SYNC_LOG_DRAWER";
export const SET_IS_GSUITE_ADMIN = "SET_IS_GSUITE_ADMIN";
export const SET_START_SYNC = "SET_START_SYNC";
export const SET_TAG_LIST = "SET_TAG_LIST";
export const SET_IMPORT_STATUS_AND_PROGRESS = "SET_IMPORT_STATUS_AND_PROGRESS";
export const SET_CURRENT_EMAIL = "SET_CURRENT_EMAIL";
export const SET_RUN_TOUR = "SET_RUN_TOUR";
export const SET_CURRENT_SELECTED_LABELS = "SET_CURRENT_SELECTED_LABELS";
export const SET_SPACE_GROUPS = "SET_SPACE_GROUPS";
export const SET_REFETCH_TAGS = "SET_REFETCH_TAGS";
export const SET_CONTACTS_REFETCH = "SET_CONTACTS_REFETCH";
export const SET_CONTACT_REFETCH = "SET_CONTACT_REFETCH";
export const PAYMENT_METHOD_EXISTS = "PAYMENT_METHOD_EXISTS";
