/* eslint-disable prefer-template */
import React, { useContext, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";

//! Antd imports
import { List, Modal, Row, Col, Typography } from "antd";

//! User files
import { AttachmentIcon, DocumentIcon, MergeIcon } from "../../../assets/svg";
import ContactCard from "../ContactCard/ContactCard";
import UserAvatar from "../UserAvatar/UserAvatar";
import {
  generateRandomColor,
  getNameTag,
  isPlanActive,
  removeAutoTags,
  showContactDetail,
} from "../../utils";
import TagLabel from "../../../modules/main/modules/contacts/components/TagLabel";
import ContactGroups from "../../../modules/main/modules/contacts/components/ContactGroups";
import ContactMetaDetailItemFields from "../ContactMetaDetailItemFields/ContactMetaDetailItemFields";
import RemovePopup from "../RemovePopup/RemovePopup";
import { REMOVE, UPGRADE_PLAN_TITLE } from "../../constants";
import { AppContext } from "../../../AppContext";
import * as ActionTypes from "../../actionTypes";

const { Text } = Typography;

function DuplicatesCard({
  contacts,
  removeLoader,
  spaceId,
  setRemoveContactModal,
  removeContactModal,
  removeSingleDuplicateContact,
  ignoreDuplicateContactCard,
  setIgnoreContactModal,
  ignoreContactModal,
  listId,
}) {
  const [contactModal, setContactModal] = useState(false);
  const [modalContactDetail, setModalContactDetail] = useState(false);
  const [removeContactInfo, setRemoveContactInfo] = useState({});
  const [mainId, setMainId] = useState();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    state: { duplicateCardId, userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);
  const isContactDetailNull =
    modalContactDetail?.numbers === null &&
    modalContactDetail?.emails === null &&
    modalContactDetail?.websites === null &&
    modalContactDetail?.contactAddresses === null &&
    modalContactDetail?.anniversary === null &&
    modalContactDetail?.birthday === null;

  const noActiveSubscription = () => {
    if (
      !isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      )
    ) {
      dispatch({
        type: ActionTypes.SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
      return false;
    }
    return true;
  };

  const removeContactFromDuplicateCard = (
    e,
    contactId,
    duplicateId,
    contactName,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (noActiveSubscription()) {
      setRemoveContactModal(true);
      setRemoveContactInfo({ contactId, duplicateId, contactName });
    }
  };

  const openContactModal = () => {
    setContactModal(true);
  };

  const closeContactModal = () => {
    setContactModal(false);
  };

  const handleContactDetailModal = (e, contact, contactId) => {
    e.preventDefault();
    e.stopPropagation();
    const filteredContact = filter(
      contact?.contacts,
      (cnt) => cnt?.id === contactId,
    );
    setModalContactDetail(filteredContact[0]);
    openContactModal();
  };

  const handleCloseModal = () => {
    setRemoveContactModal(false);
    setIgnoreContactModal(false);
  };

  const handleIgnoreDuplicate = (mainItemId) => {
    if (noActiveSubscription()) {
      setIgnoreContactModal(true);
      setMainId(mainItemId);
    }
  };

  const handlePreviewMerge = (duplicateId, contactCount) => {
    if (noActiveSubscription()) {
      navigate(`/clean-up/${spaceId}/duplicates/${duplicateId}/merge-contact`, {
        state: { mergeContactCount: contactCount },
      });
    }
  };

  const getContactName = (contactName) => {
    const name = `${contactName?.firstName ?? ""} ${
      contactName?.middleName ?? ""
    } ${contactName?.lastName ?? ""}`;
    const nameTag = getNameTag(name, contactName?.company);
    return nameTag;
  };

  return (
    <>
      {contacts?.map(
        (contact, i) =>
          contact?.contacts?.length > 1 && (
            <div
              className={`${
                listId === contact?.id || duplicateCardId === contact?.id
                  ? "fade-out"
                  : ""
              }
          ${pathname.includes(contact?.id) && "selected-contact"}
          duplicate-card contacts-col`}
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            >
              <div className="duplicate-card-header">
                <div className="duplicate-card-header-left-section">
                  {contact?.contacts?.length} Duplicate Contacts
                </div>
                <div className="duplicate-card-header-right-section">
                  <div
                    className="font-inter duplicate-card-header-right-section-ignore-btn"
                    onClick={() => handleIgnoreDuplicate(contact?.id)}
                  >
                    Ignore
                  </div>
                  <div
                    className="duplicate-card-header-right-section-merge-btn"
                    onClick={() =>
                      handlePreviewMerge(contact?.id, contact?.contacts?.length)
                    }
                  >
                    <span className="font-inter">
                      Merge now
                      <MergeIcon className="duplicate-card-header-right-section-merge-btn-icon" />
                    </span>
                  </div>
                </div>
              </div>
              <List
                className="contacts-list"
                dataSource={sortBy(contact?.contacts, "firstName")}
                renderItem={(item) => {
                  const {
                    firstName,
                    middleName,
                    lastName,
                    photo,
                    jobTitle,
                    id,
                    numbers,
                    emails,
                    company,
                  } = item;
                  const name =
                    // eslint-disable-next-line prefer-template
                    (firstName ?? "") +
                    " " +
                    (middleName ?? "") +
                    " " +
                    (lastName ?? "");
                  const userTitle = (
                    <Text
                      className="username cms-font-weight-medium font-inter"
                      title={getNameTag(name, company)}
                    >
                      {getNameTag(name, company)}
                    </Text>
                  );
                  const description = (
                    <Row className="description-row">
                      {(jobTitle || company || emails || numbers) && (
                        <Col
                          span={24}
                          className="designation-col cms-font-ellipsis"
                        >
                          <Text type="secondary" className="designation">
                            {showContactDetail(
                              jobTitle,
                              company,
                              emails,
                              numbers,
                            )}
                          </Text>
                        </Col>
                      )}
                    </Row>
                  );
                  return (
                    <ContactCard
                      title={userTitle}
                      description={description}
                      company={company}
                      size={48}
                      duplicateClass
                      deleteDuplicateContact
                      actions
                      removeContactFromDuplicateCard={
                        removeContactFromDuplicateCard
                      }
                      openContactModal={(e) =>
                        handleContactDetailModal(e, contact, id)
                      }
                      isSelected={pathname.includes(contact?.id)}
                      photo={photo}
                      item={item}
                      contactId={id}
                      numbers={numbers}
                      emails={emails}
                      firstName={firstName}
                      duplicateId={contact?.id}
                      shouldClick
                    />
                  );
                }}
              />
            </div>
          ),
      )}
      <Modal
        open={contactModal}
        footer={null}
        onCancel={closeContactModal}
        className="duplicate-contact-modal-wrapper"
      >
        <div className="cms-duplicate-contact-modal-wrapper">
          <div className="cms-duplicate-contact-modal-wrapper-upper-section">
            <div className="cms-duplicate-contact-modal-wrapper-upper-section-contact-info">
              <div className="duplicate-contact-name">
                <UserAvatar
                  name={modalContactDetail?.firstName}
                  color={generateRandomColor(
                    parseInt(modalContactDetail?.id, 10),
                  )}
                  size={56}
                  photo={modalContactDetail?.photo}
                />
                <div className="duplicate-contact-name-detail">
                  <Link
                    to={`/contacts/${spaceId}/group/all/contact/${modalContactDetail?.id}`}
                  >
                    <span className="font-inter duplicate-contact-name-detail-username">
                      {getContactName(modalContactDetail)}
                    </span>
                  </Link>
                  <span className="duplicate-contact-name-detail-post">
                    {modalContactDetail?.jobTitle}
                  </span>
                </div>
              </div>
              <div className="duplicate-contact-group-tag description-row">
                <div className="duplicate-contact-group-tag-list-tag">
                  <span className="tags-col with-border">
                    <TagLabel
                      tagArray={
                        modalContactDetail?.tags
                          ? removeAutoTags(modalContactDetail?.tags)
                          : []
                      }
                    />
                  </span>
                </div>
                <div className="duplicate-contact-group-tag-list-group">
                  {modalContactDetail?.groups && (
                    <span className="tags-col with-border group-tag-pt">
                      <ContactGroups
                        tagArray={modalContactDetail?.groups}
                        spaceId={modalContactDetail?.spaceId}
                        mergeContact
                      />
                    </span>
                  )}
                  <div className="document-notes-section">
                    <span className="document-notes-section-doc mr-8 document-notes-section-center">
                      <AttachmentIcon className="attach-icon" />
                      <span>
                        {modalContactDetail?.attachments?.length ?? 0}
                      </span>
                    </span>
                    <span className="document-notes-section-doc document-notes-section-center">
                      <DocumentIcon className="attach-icon" />
                      <span>{modalContactDetail?.notes?.length ?? 0}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isContactDetailNull && (
            <div className="cms-duplicate-contact-modal-wrapper-lower-section">
              <ContactMetaDetailItemFields
                numbers={modalContactDetail?.numbers}
                emails={modalContactDetail?.emails}
                websites={modalContactDetail?.websites}
                contactAddresses={modalContactDetail?.contactAddresses}
                anniversary={modalContactDetail?.anniversary}
                birthday={modalContactDetail?.birthday}
                socialProfiles={modalContactDetail?.socialProfiles}
                mergeContact
              />
            </div>
          )}
        </div>
      </Modal>
      <RemovePopup
        visible={removeContactModal}
        okText={REMOVE}
        content={
          <span className="popup-text-title font-inter">
            Are you sure you want to remove "
            {removeContactInfo?.contactName?.trim()}" from the merge?
          </span>
        }
        removeNote={handleCloseModal}
        handleRemove={() =>
          removeSingleDuplicateContact(
            removeContactInfo?.duplicateId,
            removeContactInfo?.contactId,
          )
        }
        deleteLoader={removeLoader}
        disabled={removeLoader}
      />
      <RemovePopup
        visible={ignoreContactModal}
        okText={REMOVE}
        content={
          <span className="popup-text-title font-inter">
            Are you sure you want to ignore these duplicate contacts?
          </span>
        }
        removeNote={handleCloseModal}
        handleRemove={() => ignoreDuplicateContactCard(mainId)}
      />
    </>
  );
}

export default DuplicatesCard;
