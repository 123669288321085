import React from "react";

//! Antd import
import { Col, Form, Radio, Row, Typography } from "antd";

//! User files
import { RadioChecked } from "../../../assets/svg";
import GooglePlus from "../../../assets/images/google-plus 1.png";
import Outlook from "../../../assets/images/outlook-1 1.png";

function ConnectServiceCards({ setService, service }) {
  return (
    <div className="cms-service-image">
      <Form.Item name="serviceName">
        <Radio.Group
          onChange={(e) => {
            setService(e.target.value);
          }}
        >
          <Row
            gutter={{ xs: 16, sm: 16, md: 24, lg: 24 }}
            className="cms-service-wrapper"
          >
            <Col span={12} className="mr-8 mr-responsive">
              <Radio.Button value="google" className="import-card-radio-button">
                <RadioChecked
                  className={
                    service !== "google"
                      ? "hide"
                      : "import-card-radio-button-checked"
                  }
                />
                <img src={GooglePlus} alt="Googleplus" />
              </Radio.Button>
            </Col>
            <Col span={12} className="ml-8 mr-responsive">
              <Radio.Button
                value="outlook"
                className="import-card-radio-button"
              >
                <RadioChecked
                  className={
                    service !== "outlook"
                      ? "hide"
                      : "import-card-radio-button-checked"
                  }
                />
                <img src={Outlook} alt="outlook" />
              </Radio.Button>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
      <div className="import-card-service-name">
        <Row>
          <Col span={12} className="cms-text-center">
            <Typography.Text className="font-inter cms-service-name">
              Google
            </Typography.Text>
          </Col>
          <Col span={12} className="cms-text-center">
            <Typography.Text className="font-inter cms-service-name">
              Outlook
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ConnectServiceCards;
