import React from "react";
import ReactJoyride from "react-joyride";

function ReactJoyrideGroup({ steps, runTour, currentStep, handleClose }) {
  return (
    <ReactJoyride
      callback={handleClose}
      steps={steps}
      // beaconComponent={ProductTourGoogle}
      run={runTour}
      hideBackButton
      stepIndex={currentStep}
      continuous
      styles={{
        buttonNext: { display: "none" },
      }}
      disableOverlayClose
      spotlightClicks
      scrollToFirstStep={runTour}
    />
  );
}

export default ReactJoyrideGroup;
