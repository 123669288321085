import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import { useMutation } from "@apollo/client";

//! Antd imports
import { Col, Form, Input, Row, Typography } from "antd";

//! Graphql files
import { SET_PASSWORD } from "../graphql/Mutations";

//! User files
import { ROUTES, WINDOW_SIZE } from "../../../common/constants";
import { formValidatorRules, trimWhiteSpace } from "../../../common/utils";
import Layout from "../layout/Layout";
import SomethingWentWrong from "../../../common/SomethingWentWrong";
import { AppContext } from "../../../AppContext";
import IconButton from "../../../common/components/IconButton/IconButton";
import { CloseEyeIcon, OpenEyeIcon } from "../../../assets/svg";

const { required, password } = formValidatorRules;

function CreatePassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const id = location?.id ?? localStorage?.getItem("id");
  const token = location?.token ?? localStorage?.getItem("token");
  const {
    initializeAuth,
    state: { currentUser },
  } = useContext(AppContext);
  const { width } = useWindowSize();

  const [setPassword, { loading }] = useMutation(SET_PASSWORD, {
    onCompleted({ resetPassword }) {
      successCallback(
        resetPassword?.data?.accessToken,
        resetPassword?.data?.userInfo,
      );
      if (resetPassword?.data?.userInfo) {
        if (width <= WINDOW_SIZE) {
          navigate(ROUTES?.CONTACTS);
        } else {
          navigate(
            `/contacts/${resetPassword?.data?.userInfo?.spaceId}/group/all`,
          );
        }
      }
    },
  });

  function successCallback(accessToken, user) {
    initializeAuth(accessToken, user);
  }

  const onFinish = (e) => {
    // eslint-disable-next-line no-shadow
    const { password, confirmPassword } = e;
    if (password !== confirmPassword) {
      SomethingWentWrong("Password does not match.");
      return 0;
    }
    setPassword({ variables: { id: parseInt(id, 10), token, password } })
      ?.then(() => {})
      ?.catch(() => {});
  };

  useEffect(() => {
    if (currentUser?.id) {
      if (width <= WINDOW_SIZE) {
        navigate(ROUTES.CONTACTS);
      } else {
        navigate(`/contacts/${currentUser?.spaceId}/group/all`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <Layout>
      <div className="create-password-container">
        <div className="create-password-container-box">
          <Typography.Title level={4} className="dark-text">
            Set your password
          </Typography.Title>
          <div className="cms-email-container">
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={false}
              form={form}
              onFieldsChange={(e) => {
                trimWhiteSpace(form, e, ["password", "confirmPassword"]);
              }}
            >
              <Form.Item
                label="Password"
                name="password"
                rules={[required, password]}
                validateTrigger={["onPressEnter"]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  iconRender={(visible) =>
                    visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                  }
                />
              </Form.Item>
              <Form.Item
                label="Confirm password"
                name="confirmPassword"
                rules={[required, password]}
                validateTrigger={["onPressEnter"]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  iconRender={(visible) =>
                    visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                  }
                />
              </Form.Item>
              <Row justify="end">
                <Col xs={24} sm={24} md={6} lg={6} xl={24}>
                  <IconButton
                    iconClass="cms-email-login-btn"
                    text={
                      <span className="font-inter cms-email-login-btn-text">
                        Confirm
                      </span>
                    }
                    htmlType="submit"
                    loading={loading}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CreatePassword;
