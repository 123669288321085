import React, { useState } from "react";
import styled from "styled-components";

//! Antd imports
import { Avatar } from "antd";

const StyledAvatar = styled(Avatar)`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color || "transparent"};
  z-index: ${({ zindex }) => zindex || 1};
`;

function UserAvatar({
  name,
  color,
  size,
  right,
  fontSize,
  number,
  zIndex,
  border,
  photo,
  className,
}) {
  const [imgLoadError, setImgLoadError] = useState(false);

  return (
    <StyledAvatar
      className={className}
      size={size}
      color={!photo || imgLoadError ? color : null}
      right={right}
      fontSize={fontSize}
      zindex={zIndex}
      border={border}
      src={photo}
      onError={() => setImgLoadError(true)}
    >
      {name && number ? name : name?.charAt(0)?.toUpperCase()}
    </StyledAvatar>
  );
}

export default UserAvatar;
