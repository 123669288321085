import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

//! Antd select
import { Select } from "antd";

//! User files
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { CaretDown } from "../../../../../assets/svg";
import { HasRight } from "../../../../../common/HasRight";
import {
  DEACTIVATE_REMINDERS,
  DEACTIVATE_REMINDER_TEXT,
  SPACE,
} from "../../../../../common/constants";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";

//! Graphql files
import { DEACTIVATE_REMINDER } from "../../../graphql/mutations/deActivateReminder";
import { UPDATE_REMINDER_SETTING } from "../../../graphql/mutations/updateReminderSetting";
import { GET_REMINDER_SETTING } from "../../../graphql/queries/getReminderSetting";

const { Option } = Select;
function RemindersConfiguration() {
  const [emailReminder, setEmailReminder] = useState("WEEKLY");
  const [visible, setVisible] = useState(false);
  const { spaceId } = useParams();
  const navigate = useNavigate();

  const emailOptions = [
    { value: "WEEKLY", label: "Weekly" },
    { value: "NONE", label: "None" },
  ];

  const [getReminderSetting, { data: reminderSettingData }] = useLazyQuery(
    GET_REMINDER_SETTING,
    {
      fetchPolicy: "network-only",
      onError() {},
    },
  );

  const [deActivateReminder, { loading }] = useMutation(DEACTIVATE_REMINDER, {
    variables: {
      spaceId: parseInt(spaceId, 10),
    },
    onCompleted() {
      navigate(`/addOns/${spaceId}/reminders/activate`);
      setVisible(false);
    },
    onError() {},
  });

  const [updateReminderSetting, { loading: updateLoading }] = useMutation(
    UPDATE_REMINDER_SETTING,
    {
      onError() {},
    },
  );

  const setReminderSetting = () => {
    if (emailReminder) {
      updateReminderSetting({
        variables: {
          data: {
            spaceId: parseInt(spaceId, 10),
            config: {
              schedule: emailReminder,
            },
          },
        },
      }).then(() => {
        getReminderSetting({
          variables: {
            spaceId: parseInt(spaceId, 10),
          },
        });
      });
    }
  };

  useEffect(() => {
    if (spaceId > 0) {
      getReminderSetting({
        variables: {
          spaceId: parseInt(spaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (reminderSettingData) {
      setEmailReminder(
        reminderSettingData?.reminderSettings?.data?.config?.schedule,
      );
    }
  }, [reminderSettingData]);

  const handleDeactivateModal = () => {
    setVisible(!visible);
  };

  const handleChange = (val) => {
    setEmailReminder(val);
  };

  return (
    <div className="cms-reminder-config">
      <div className="cms-reminder-config-header">
        <span className="font-inter username">Settings</span>
        <IconButton
          iconClass="cms-reminder-config-header-save d-flex-center"
          text={
            <span className="font-inter cms-reminder-config-header-save-text cursor-pointer">
              Save
            </span>
          }
          type="primary"
          htmlType="submit"
          handleClick={setReminderSetting}
          loading={updateLoading}
          disable={
            emailReminder ===
            reminderSettingData?.reminderSettings?.data?.config?.schedule
          }
        />
      </div>
      <div className="cms-reminder-config-email" id="reminder">
        <span className="font-inter cms-reminder-config-email-text mr-12">
          Weekly Summary Email
        </span>
        <Select
          value={emailReminder}
          suffixIcon={<CaretDown />}
          className="cms-reminder-config-email-select"
          popupClassName="cms-reminder-config-email-select-popover"
          onChange={handleChange}
          getPopupContainer={() => document.getElementById("reminder")}
        >
          {emailOptions?.map((option) => (
            <Option value={option?.value} key={option?.value}>
              {option?.label}
            </Option>
          ))}
        </Select>
      </div>
      <HasRight action={DEACTIVATE_REMINDERS} type={SPACE}>
        <div className="cms-reminder-config-deactivate">
          <IconButton
            iconClass="cms-reminder-config-deactivate-btn cms-font-ellipsis"
            text={
              <span className="font-inter cms-reminder-config-deactivate-btn-text">
                Deactivate Reminders
              </span>
            }
            handleClick={handleDeactivateModal}
            loading={loading}
          />
        </div>
      </HasRight>
      <RemovePopup
        visible={visible}
        removeNote={handleDeactivateModal}
        handleRemove={deActivateReminder}
        deleteLoader={loading}
        content={
          <span className="popup-text-title font-inter">
            {DEACTIVATE_REMINDER_TEXT}
          </span>
        }
        closeText="Cancel"
        okText="Deactivate"
        disabled={loading}
      />
    </div>
  );
}

export default RemindersConfiguration;
