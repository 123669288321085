import React, { useContext, useState } from "react";
import moment from "moment";

//! User files
import { importIconMap } from "../../../../../common/utils";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import { CloseBlack } from "../../../../../assets/svg";
import Loading from "../../../../../common/components/Loading";
import { GOOGLE } from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";

function ContactSourceListItem({
  item,
  count,
  index,
  removeContactSource,
  removeLoader,
  setOpen,
  open,
  status,
}) {
  const [sourceId, setSourceId] = useState();
  const {
    state: { userSubscriptionInfo },
  } = useContext(AppContext);
  const lastFetched = item?.maxContactUpdated
    ? moment(item?.maxContactUpdated).fromNow()
    : moment().fromNow();

  const handleClosePopup = (id) => {
    setSourceId(id);
    setOpen(!open);
  };

  return (
    <div className="cms-account-item">
      <div className="cms-account-source-icon">
        <img src={importIconMap[item?.sourceType]} alt={item?.sourceType} />
      </div>
      <div
        className={`${
          index !== count - 1 ? "cms-list-border" : ""
        } cms-account-source-data`}
      >
        <div className="cms-account-detail">
          <span className="cms-account-email">{item?.email}</span>
          <div className="cms-account-time">
            <span className="cms-account-fetch-text">Last Fetched</span>
            <span>{lastFetched}</span>
          </div>
        </div>
        {userSubscriptionInfo?.type !== GOOGLE &&
          (status === null || status === "COMPLETED") && (
            <div
              className="cms-account-close-icon"
              onClick={() => handleClosePopup(item?.id)}
            >
              <CloseBlack />
            </div>
          )}
        {status !== null && status !== "COMPLETED" && (
          <div className="cms-fetching-state">
            <span className="cms-fetching-loader">
              <Loading size="small" />
            </span>
            <span className="cms-fetching-text font-inter">
              Importing Contacts
            </span>
          </div>
        )}
        {/* //! Hiding this as we won't sync contacts from service in MVP */}
        {/* ) : ( */}
        {/* <>
            <div>
              <Select
                defaultValue={item?.fetchInterval ?? "DAILY"}
                className="cms-time-selector"
                suffixIcon={<SelectDownArrow />}
                onChange={(val) => handleUpdateSource(val, item?.id)}
              >
                <Option className="rights-option" value="NEVER">
                  Never
                </Option>
                <Option className="rights-option" value="DAILY">
                  Daily
                </Option>
                <Option className="rights-option" value="WEEKLY">
                  Weekly
                </Option>
              </Select>
            </div>
            {item?.id !== rowId ? (
              <div>
                <IconButton
                  iconClass="cms-account-fetch-btn"
                  text={FETCH_NOW}
                  handleClick={() => handleFetching(item?.id)}
                />
              </div>
            ) : (
              <>
                <div className="cms-fetching-state">
                  <span className="cms-fetching-loader">
                    <Loading size="small" />
                  </span>
                  <span className="cms-fetching-text font-inter">Fetching</span>
                </div>
                <div
                  className="cms-account-close-icon"
                  onClick={() => handleClosePopup(item?.id)}
                >
                  <CloseBlack />
                </div>
              </>
            )}
          </>
        )} */}
      </div>
      {sourceId && (
        <RemovePopup
          visible={open}
          content={
            <span className="popup-text-title font-inter">
              Are you sure you want to remove this?
            </span>
          }
          okText="Remove"
          removeNote={handleClosePopup}
          handleRemove={() => removeContactSource(sourceId, item?.sourceType)}
          id={sourceId}
          deleteLoader={removeLoader}
          disabled={removeLoader}
          maskClosable={false}
        />
      )}
    </div>
  );
}

export default ContactSourceListItem;
