import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import slice from "lodash/slice";
import { Tabs } from "antd";

function ContactTabs({ tabsArray = [] }) {
  const [activeTab, setActiveTab] = useState(0);
  const tabSlice = slice(tabsArray, 0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get("tab");

  useEffect(() => {
    if (currentTab !== "expanded") {
      setActiveTab(currentTab);
    } else {
      setActiveTab("activity");
    }
  }, [currentTab, tabsArray]);

  const items = tabSlice.map((tabs) => ({
    key: tabs?.tab,
    icon: tabs?.icon,
    children: tabs?.component,
  }));

  return <Tabs activeKey={activeTab} items={items} onChange={setActiveTab} />;
}

export default ContactTabs;
