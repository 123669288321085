import React, { useContext, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import isInteger from "lodash/isInteger";

//! User files
import { CleanUpIcon } from "../../../../../assets/svg";
import AssistantListItem from "../components/AssistantListItem";
import { AppContext } from "../../../../../AppContext";
import { SET_DUPLICATE_CONTACT_COUNT } from "../../../../../common/actionTypes";

//! Graphql files
import { GET_CLEAN_UP_SPACE_INFO } from "../../../graphql/queries/getCleanUpSpaceInfo";

function AssistantNav() {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);
  const spaceId = parseInt(
    localStorage.getItem("currentSpace") || currentUser?.spaceId,
    10,
  );

  const [getSpaceById] = useLazyQuery(GET_CLEAN_UP_SPACE_INFO, {
    fetchPolicy: "network-only",
    variables: {
      id: spaceId,
    },
    onCompleted({ space }) {
      dispatch({
        type: SET_DUPLICATE_CONTACT_COUNT,
        data: space?.duplicateContactsCount,
      });
    },
    onError() {},
  });

  useEffect(() => {
    if (spaceId && isInteger(spaceId)) {
      getSpaceById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  return (
    <div className="assistant-nav cms-contact-scrollbar">
      <div className="assistant-nav-title">
        <div className="assistant-nav-title-icon">
          <CleanUpIcon className="cms-svg-xl" />
        </div>
        <div className="assistant-nav-title-text">Clean Up</div>
      </div>
      <div className="assistant-nav-space-container cms-contact-scrollbar">
        <div className="assistant-nav-space-container-box" key={spaceId}>
          <AssistantListItem spaceId={parseInt(spaceId, 10)} />
        </div>
      </div>
    </div>
  );
}

export default AssistantNav;
