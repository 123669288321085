import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useLazyQuery } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";
import indexOf from "lodash/indexOf";

//! Ant Imports
import { List, Checkbox } from "antd";

//! User files
import CountTag from "../../../../../common/components/CountTag";
import { capitalizeWord, hasSpaceRight } from "../../../../../common/utils";
import { Folder, FolderLock, FolderShared } from "../../../../../assets/svg";
import { AppContext } from "../../../../../AppContext";
import {
  SET_CURRENT_SIDE_TAB,
  SET_USER_GROUP_PERMISSION,
  SET_USER_SPACE_PERMISSION,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../../../../common/actionTypes";
import { GOOGLE, SHARE_GROUP } from "../../../../../common/constants";
import { HasRight } from "../../../../../common/HasRight";

//! graphql files
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";

function GroupListItem({
  dataSource,
  spacePermission,
  checkAll,
  spaceId,
  setGroupCheckList,
  groupCheckList,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    dispatch,
    state: { currentUser, userSubscriptionInfo, userSpacePermission },
  } = useContext(AppContext);
  const userSpaceId = pathname.split("/")[2];
  const groupId = pathname.split("/")[4];
  // eslint-disable-next-line no-unused-vars
  const isGoogleWorkSpace = userSubscriptionInfo?.type === GOOGLE;

  const [getSpaceDetail, { data: spaceDetail }] = useLazyQuery(
    GET_SPACE_DETAIL_V2,
    {
      onError() {
        localStorage.setItem("currentSpace", currentUser?.spaceId);
        navigate(`/contacts/${currentUser?.spaceId}/group/all`);
      },
    },
  );

  useEffect(() => {
    if (userSpaceId > 0) {
      getSpaceDetail({
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(userSpaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSpaceId]);

  useEffect(() => {
    if (spaceDetail) {
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: spaceDetail?.spaceV2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDetail]);

  const handleListItemClick = (pathLink, title, permissions) => {
    dispatch({ type: SET_CURRENT_SIDE_TAB, data: capitalizeWord(title) });
    dispatch({
      type: SET_USER_GROUP_PERMISSION,
      data: permissions,
    });
    dispatch({ type: SET_USER_SPACE_PERMISSION, data: spacePermission });
    navigate(pathLink);
  };

  const handleCheckboxClick = (e, grpId) => {
    e.stopPropagation();
    if (groupCheckList?.includes(grpId)) {
      const tempId = cloneDeep(groupCheckList);
      const index = indexOf(groupCheckList, grpId);
      if (index !== -1) {
        tempId.splice(index, 1);
      }
      setGroupCheckList(tempId);
    } else {
      setGroupCheckList([...groupCheckList, grpId]);
    }
  };

  return (
    <List
      className="list-wrapper"
      dataSource={dataSource}
      renderItem={(item) => {
        const {
          name,
          contacts,
          id,
          permissions,
          permission,
          type,
          membersCount,
        } = item;
        const pathLink = `/contacts/${spaceId}/group/${id}/settings`;
        const isSelected = parseInt(id, 10) === parseInt(groupId, 10);
        const groupName = (
          <span className="cms-font-ellipsis group-name">
            {capitalizeWord(name)}
          </span>
        );
        const groupTitle = (
          <div className="cms-group-title">
            {groupName}
            <CountTag count={contacts || 0} />
          </div>
        );

        return (
          <List.Item
            onClick={() =>
              handleListItemClick(pathLink, name, permissions || permission, id)
            }
            className={clsx(
              isSelected && "selected-list active-bg",
              // !isGoogleWorkSpace && "hovered-list",
              !hasSpaceRight(
                userSpacePermission || userSubscriptionInfo?.permission,
                SHARE_GROUP,
              ) && "hovered-list",
            )}
            title={capitalizeWord(name)}
          >
            <List.Item.Meta
              avatar={
                type === "PRIVATE" ? (
                  <span className="d-flex-center cms-group-checkbox">
                    {/* { userSubscriptionInfo?.type === GOOGLE &&} */}
                    <HasRight action={SHARE_GROUP} type="space">
                      <Checkbox
                        onClick={(e) => handleCheckboxClick(e, id)}
                        checked={
                          checkAll || groupCheckList?.includes(parseInt(id, 10))
                        }
                      />
                    </HasRight>
                    <FolderLock className="svg-margin-left cms-svg-md full-width" />
                  </span>
                ) : (
                  <span className="d-flex-center cms-group-checkbox">
                    <HasRight action={SHARE_GROUP} type="space">
                      <Checkbox
                        className={`${
                          groupCheckList?.includes(parseInt(id, 10)) ||
                          (checkAll
                            ? "group-checkbox-checked"
                            : "group-checkbox")
                        } `}
                        onClick={(e) => handleCheckboxClick(e, id)}
                        checked={
                          groupCheckList?.includes(parseInt(id, 10)) || checkAll
                        }
                      />
                    </HasRight>
                    {membersCount > 1 ? (
                      <FolderShared
                        className={`${
                          !groupCheckList?.includes(parseInt(id, 10)) &&
                          !checkAll &&
                          hasSpaceRight(
                            userSpacePermission ||
                              userSubscriptionInfo?.permission,
                            SHARE_GROUP,
                          ) &&
                          "svg-margin-left"
                        } cms-svg-md full-width`}
                      />
                    ) : (
                      <Folder
                        className={`${
                          !groupCheckList?.includes(parseInt(id, 10)) &&
                          !checkAll &&
                          hasSpaceRight(
                            userSpacePermission ||
                              userSubscriptionInfo?.permission,
                            SHARE_GROUP,
                          ) &&
                          "svg-margin-left"
                        } cms-svg-md full-width`}
                      />
                    )}
                  </span>
                )
              }
              title={groupTitle}
            />
          </List.Item>
        );
      }}
    />
  );
}

export default GroupListItem;
