import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ThankYouPage() {
  const navigate = useNavigate();
  const spaceId = localStorage?.getItem("currentSpace");

  useEffect(() => {
    setTimeout(() => {
      navigate(`/space/${spaceId}/plans`);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="thankyou-page-wrapper">
      <div className="text-heading">Thank you for subscribing!</div>
      <div className="text-sub-heading">
        Congratulations! You have successfully updated the subscription for this
        space. Enjoy all the features of the plan you have just subscribed to.
      </div>
    </div>
  );
}

export default ThankYouPage;
