import React, { useState } from "react";

//! Antd import
import { Upload } from "antd";

//! User files
import { File } from "../../../assets/svg";
import FilesContainer from "./FilesContainer";
import IconButton from "../../../common/components/IconButton/IconButton";
import { IMPORT_SAMPLE_FILE_URL } from "../../../common/constants";

const { Dragger } = Upload;

function ImportContacts({
  importRef,
  currStep,
  noMargin,
  setLoader,
  setIsFileUploaded,
}) {
  const [file, setFile] = useState();

  const props = {
    name: "file",
    accept: ".csv,.vcf",
    multiple: false,
    // eslint-disable-next-line no-shadow
    onChange: ({ file }) => {
      setFile(file);
      // eslint-disable-next-line no-unused-expressions
      setIsFileUploaded && setIsFileUploaded(true);
    },
  };

  if (file) {
    return (
      <FilesContainer
        file={file}
        setFile={setFile}
        importRef={importRef}
        currStep={currStep}
        setLoader={setLoader}
        setIsFileUploaded={setIsFileUploaded}
      />
    );
  }

  return (
    <div className={`import-card ${noMargin ? "no-margin" : ""}`}>
      <div className="import-card-contact">
        <Dragger {...props} onClick={(e) => e.stopPropagation()}>
          <p className="ant-upload-drag-icon">
            <File />
          </p>
          <p className="font-inter ant-upload-text">
            Drag & Drop CSV/VCF files to import the contacts
          </p>
          <div className="cms-upload-section mt-10">
            <div className="cms-upload-section-divider">
              <span className="font-inter cms-import-contact-link-or-text cms-text-border">
                OR
              </span>
            </div>
            <Upload {...props} onClick={(e) => e.stopPropagation()}>
              <IconButton
                iconClass="import-card-upload-button"
                type="primary"
                text={
                  <span className="font-inter cms-upload-text">
                    Upload File
                  </span>
                }
              />
            </Upload>
            <a
              href={IMPORT_SAMPLE_FILE_URL}
              download="true"
              className="text-decoration-underline"
              onClick={(e) => e.stopPropagation()}
            >
              Download sample file
            </a>
          </div>
        </Dragger>
      </div>
    </div>
  );
}

export default ImportContacts;
