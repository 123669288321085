import React, { useState } from "react";
//! User files
import { Progress } from "antd";
import { DocIcon } from "../../../../../assets/svg";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import ContactDocumentsListItem from "./ContactDocumentsListItem";

function ContactDocumentsList({
  attachments,
  removeDocument,
  deleteLoader,
  handleDownloadAttachment,
  editDocumentName,
  editLoading,
  progress,
  setOpen,
  open,
  contactPermission,
  openDocExternalLink,
}) {
  const [docName, setDocName] = useState();
  const [docId, setDocId] = useState();
  const [docKey, setDocKey] = useState();
  const handlePopup = (name, key, id) => {
    setDocName(name);
    setDocKey(key);
    setDocId(id);
    setOpen(!open);
  };
  return (
    <div className="cms-document-list-wrapper">
      <div className="cms-document-list-content">
        {progress > 0 && progress < 100 && (
          <div className="cms-document-progress">
            <span>
              <DocIcon />
            </span>
            <span className="cms-progress-text">Uploading</span>
            <Progress percent={progress} showInfo={false} size="small" />
          </div>
        )}
        {attachments?.map((doc, i) => (
          <ContactDocumentsListItem
            doc={doc}
            removeDocument={removeDocument}
            deleteLoader={deleteLoader}
            handleDownloadAttachment={handleDownloadAttachment}
            editDocumentName={editDocumentName}
            editLoading={editLoading}
            progress={progress}
            docId={docId}
            setDocId={setDocId}
            key={doc?.id}
            handlePopup={handlePopup}
            contactPermission={contactPermission}
            openDocExternalLink={openDocExternalLink}
            docLength={attachments?.length}
            index={i}
          />
        ))}
        <RemovePopup
          handleRemove={() => removeDocument(docId, docKey, docName)}
          id={docId}
          itemKey={docKey}
          visible={open}
          removeNote={handlePopup}
          deleteLoader={deleteLoader}
          content={
            <span className="popup-text-title font-inter">
              Are you sure you want to remove the {docName}?
            </span>
          }
          okText="Remove"
          disabled={deleteLoader}
          maskClosable={false}
        />
      </div>
    </div>
  );
}

export default ContactDocumentsList;
