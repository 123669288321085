import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useWindowSize } from "react-use";

//! Antd imports
import ContactView from "../components/ContactView";

//! GraphQl files
import { GET_CONTACT_INFO } from "../../../graphql/queries/getContactInfo";
import { AppContext } from "../../../../../AppContext";
import { SET_CONTACT_REFETCH } from "../../../../../common/actionTypes";
import NoAccessComponent from "../../../../../common/components/NoAccessComponent/NoAccessComponent";
import {
  ADDON_PARAMS,
  ROUTES,
  WINDOW_SIZE,
} from "../../../../../common/constants";
import { BackArrow } from "../../../../../assets/svg";

function ContactDetail() {
  const { contactId, spaceId } = useParams();
  const [contactDetails, setContactDetails] = useState([]);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const {
    state: { contactRefetch },
    dispatch,
  } = useContext(AppContext);

  const [getContactInfo, { loading }] = useLazyQuery(GET_CONTACT_INFO, {
    fetchPolicy: "network-only",
    onCompleted({ contactInfo }) {
      dispatch({
        type: SET_CONTACT_REFETCH,
        data: false,
      });
      if (contactInfo?.data) {
        setContactDetails(contactInfo?.data);
      }
    },
    onError() {
      navigate(`/contacts/${spaceId}/group/all`);
    },
  });

  useEffect(() => {
    if (contactId !== "new" && contactId !== "activate") {
      getContactInfo({
        variables: {
          contactId: contactId && parseInt(contactId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    if (contactRefetch) {
      getContactInfo({
        variables: {
          contactId: contactId && parseInt(contactId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRefetch]);

  const handleBackClick = () => {
    navigate(`${ROUTES?.CONTACTS}/${spaceId}/all`);
  };

  return (
    contactId !== "new" &&
    contactId !== "activate" &&
    (JSON?.parse(localStorage.getItem(ADDON_PARAMS))?.length > 0 ? (
      <NoAccessComponent />
    ) : (
      <div className={width <= WINDOW_SIZE ? "padding-15" : ""}>
        {width <= WINDOW_SIZE && (
          <div className="cms-contact-wrapper-header mobile">
            <div className="cms-contact-form-close mobile-close">
              {contactId && (
                <div className="cms-edit-back" onClick={handleBackClick}>
                  <span className="cms-contact-form-close-arrow">
                    <BackArrow />
                  </span>
                  <span className="cms-contact-form-close-back">Back</span>
                </div>
              )}
            </div>
          </div>
        )}
        <ContactView
          loading={loading}
          contactDetails={contactDetails}
          setContactDetails={setContactDetails}
        />
      </div>
    ))
  );
}

export default ContactDetail;
