import React, { useEffect, useState, useContext, useRef } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import find from "lodash/find";
import _isInteger from "lodash/isInteger";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import { useWindowSize } from "react-use";

//! Ants imports
import { Select, Input } from "antd";

//! User files
import {
  DownArrowIcon,
  Member,
  MemberPrimary,
} from "../../../../../assets/svg";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import SharedUserList from "../../../../../common/components/SharedUserList/SharedUserList";
import {
  createAmplitudeEvent,
  hasSpaceRight,
  isPlanActive,
  notificationToast,
  validateEmail,
} from "../../../../../common/utils";
import {
  ADD,
  AMPLITUDE_EVENT_LOG,
  ADMIN,
  SHARE_SPACE,
  VIEW,
  UPGRADE_PLAN_TITLE,
  PERMISSIONS,
  GOOGLE,
  CONTACT_LIMIT,
  OWNER,
  WINDOW_SIZE,
  ROUTES,
  SHARE_GOOGLE_CONTACTS_TEXT,
} from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import { HasRight } from "../../../../../common/HasRight";
import UpgradePlanPopup from "../../../../../common/components/UpgradePlanPopup/UpgradePlanPopup";
import SettingsHeader from "../../../../../common/components/SettingsHeader/SettingsHeader";
import MemberGIF from "../../../../../assets/images/share-member.gif";

import {
  SET_SPACE_PLAN_STATUS,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../../../../common/actionTypes";
import WarningMessage from "../../../../../common/components/WarningMessage/WarningMessage";

//! Graphql files
import { ADD_MEMBER_TO_SPACE } from "../../../graphql/mutations/addMemberToSpace";
import { REMOVE_MEMBER_FROM_SPACE } from "../../../graphql/mutations/removeMemberFromSpace";
import { UPDATE_SPACE_PERMISSION } from "../../../graphql/mutations/updateSpacePermission";
import { REINVITE_SPACE_MEMBER } from "../../../graphql/mutations/reInviteSpaceMember";
import { CHANGE_SPACE_OWNER } from "../../../graphql/mutations/changeSpaceOwner";
import { GET_SPACE_MEMBERS } from "../../../graphql/queries/getSpaceMembers";

const { Option } = Select;

function AddMember() {
  const [permission, setPermission] = useState("VIEW");
  const [members, setMembers] = useState([]);
  const [visible, setVisible] = useState();
  const [email, setEmail] = useState();
  const [invitedUserIds, setInviteUserIds] = useState([]);
  const { spaceId } = useParams();
  const [spaceOwnerId, setSpaceOwnerId] = useState();
  const [ownerChangePopup, setOwnerChangePopup] = useState(false);
  const [offset, setOffset] = useState(0);
  const [membersCount, setMembersCount] = useState(0);
  const {
    state: { currentUser, userSubscriptionInfo, userSpacePermission },
    dispatch,
  } = useContext(AppContext);
  const inputFocus = useRef();
  const navigate = useNavigate();
  const currentSpace =
    currentUser?.spaceId || localStorage?.getItem("currentSpace");
  const [getSpaceMembers, { loading: spaceLoading, data: spaceMember }] =
    useLazyQuery(GET_SPACE_MEMBERS, {
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(spaceId, 10),
        data: {
          offset,
          limit: CONTACT_LIMIT,
        },
      },
    });
  const { width } = useWindowSize();
  // eslint-disable-next-line no-console
  console.log("userSubscriptionInfo", userSubscriptionInfo);

  const [addMemberToSpace, { loading: memberLoading }] =
    useMutation(ADD_MEMBER_TO_SPACE);

  const [removeMember, { loading: removeLoader }] = useMutation(
    REMOVE_MEMBER_FROM_SPACE,
    {
      onCompleted() {
        setVisible(false);
      },
      onError() {
        setVisible(false);
      },
    },
  );

  const [updatePermission] = useMutation(UPDATE_SPACE_PERMISSION, {
    onCompleted() {},
  });

  const [reinviteSpaceMember, { loader: reinviteLoader }] = useMutation(
    REINVITE_SPACE_MEMBER,
  );

  const [changeSpaceOwner, { loading: ownerLoader }] = useMutation(
    CHANGE_SPACE_OWNER,
    {
      refetchQueries: [
        {
          query: GET_SPACE_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
      ],
      onCompleted() {
        setOwnerChangePopup(false);
      },
      onError() {},
    },
  );

  useEffect(() => {
    if (spaceId && _isInteger(parseInt(spaceId, 10)) && members?.length === 0) {
      getSpaceMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    const memberArr = [];

    if (!spaceLoading) {
      if (spaceMember?.spaceMembers?.data) {
        setSpaceOwnerId(spaceMember?.spaceMembers?.data?.ownerId);
        dispatch({
          type: SET_USER_SUBSCRIPTION_INFO,
          data: spaceMember?.spaceMembers?.data,
        });
      }

      if (spaceMember?.spaceMembers?.data?.members) {
        memberArr.push(...members, ...spaceMember.spaceMembers.data.members);
      }
      setMembersCount(spaceMember?.spaceMembers?.data?.membersCount);
      setMembers(uniq(memberArr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceMember]);

  const handleAddMember = () => {
    if (!email) {
      inputFocus?.current?.focus();
      return;
    }
    if (
      userSubscriptionInfo?.membersCount >=
      userSubscriptionInfo?.subscription?.quantity
    ) {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: {
          planExpired: true,
          message: "Space sharing limit has been exceeded!",
        },
      });
      setEmail("");
      setPermission("VIEW");
      return false;
    }
    if (
      !isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      )
    ) {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
      setEmail("");
      setPermission("VIEW");
      return false;
    }

    if (email) {
      const isValidEmail = validateEmail(email);
      if (isValidEmail) {
        const isUserInvited = find(members, (e) => e.email === email);
        if (isUserInvited) {
          notificationToast({
            message: `User ${email} is already in the list`,
            type: "warning",
          });
        } else {
          setEmail("");
          addMemberToSpace({
            variables: {
              data: {
                permissions: permission,
                spaceId: parseInt(spaceId, 10),
                email: email?.toLowerCase(),
              },
            },
          }).then((data) => {
            getSpaceMembers();

            const newData = cloneDeep(members);
            newData.push(data?.data?.addMemberToSpace?.data);
            setMembers(newData);
            setMembersCount(newData?.length);
            setPermission("VIEW");
            const eventProperties = {
              "Space Id": spaceId,
              "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
              "Plan status": userSubscriptionInfo?.subscription?.planStatus,
              "Shared With": email,
            };
            createAmplitudeEvent(
              AMPLITUDE_EVENT_LOG.SPACE_SHARED,
              eventProperties,
            );
          });
        }
      } else {
        notificationToast({
          message: `Please enter valid email`,
          type: "error",
        });
      }
    }
  };

  const handleRemoveMember = (memberId, memberName) => {
    if (memberId) {
      removeMember({
        variables: {
          id: memberId,
        },
      })
        .then(() => {
          getSpaceMembers();
          setMembers([]);
          const updateMembers = filter(members, (val) => val?.id !== memberId);
          setMembers(updateMembers);
          setMembersCount(updateMembers?.length);
          const eventProperties = {
            "Space Id": spaceId,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
            "Removed from": memberId,
            "User Name": memberName,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.SPACE_REVOKED,
            eventProperties,
          );
        })
        .catch(() => {});
    }
  };

  const handleMemberPermission = (value, memberId) => {
    if (value && memberId) {
      updatePermission({
        variables: {
          id: parseInt(memberId, 10),
          permissions: value,
          spaceId: parseInt(spaceId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleReinviteMember = (userId) => {
    if (userId) {
      setInviteUserIds([...invitedUserIds, userId]);
      reinviteSpaceMember({
        variables: {
          userId: parseInt(userId, 10),
          spaceId: parseInt(spaceId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleChangeOwner = (userId) => {
    if (userId) {
      changeSpaceOwner({
        variables: {
          id: parseInt(spaceId, 10),
          userId: parseInt(userId, 10),
        },
      });
    }
  };

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      members?.length < membersCount
    ) {
      setOffset(members?.length);
    }
  };

  const handleRedirect = () => {
    if (width <= WINDOW_SIZE) {
      navigate(ROUTES?.CONTACTS);
    } else {
      navigate(`/contacts/${currentSpace}/group/all`);
    }
  };

  return userSubscriptionInfo?.type === GOOGLE && membersCount === 1 ? (
    <div className="cms-reminders-activate">
      <div className="cms-reminders-activate-state">
        <MemberPrimary className="cms-reminders-activate-state-svg-icon" />
        <span className="font-inter cms-members-activate-state-title">
          Members
        </span>
        <span className="cms-reminders-activate-state-image">
          <img src={MemberGIF} alt="Reminders" />
        </span>
        <span className="font-inter cms-reminders-activate-state-content mb-32">
          {SHARE_GOOGLE_CONTACTS_TEXT}
        </span>
        <IconButton
          iconClass={`dropdown-menu-button-section ${
            userSpacePermission !== OWNER
              ? "cms-request-admin cms-request-admin-padding"
              : ""
          }`}
          type="primary"
          text={
            <span className="font-inter dropdown-menu-button-section-text">
              Go to Dashboard
            </span>
          }
          handleClick={handleRedirect}
        />
      </div>
    </div>
  ) : (
    <>
      <SettingsHeader
        icon={<Member />}
        title={`Members(${`${membersCount || 0}/${
          spaceMember?.spaceMembers?.data?.subscription?.quantity || 0
        }`})`}
      />
      <div className="cms-add-member-wrapper">
        <div className="cms-add-member-wrapper-block">
          {userSubscriptionInfo?.type !== GOOGLE && (
            <HasRight type="space" action={SHARE_SPACE}>
              <div className="cms-add-member-block">
                <div
                  span={20}
                  className="cms-email-block"
                  id="group-space-member"
                >
                  <Input
                    id="name"
                    className="cms-add-member-block-input"
                    type="text"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e?.target?.value)}
                    disabled={!hasSpaceRight(userSpacePermission, "inviteUser")}
                    ref={inputFocus}
                  />
                  <Select
                    defaultValue={VIEW}
                    className="cms-select-after"
                    optionLabelProp="label"
                    suffixIcon={<DownArrowIcon />}
                    onChange={(val) => setPermission(val)}
                  >
                    {PERMISSIONS.map((rights) => (
                      <Option
                        value={rights?.value}
                        label={rights?.label}
                        key={rights?.value}
                      >
                        <div className="rights-option">{rights?.label}</div>
                      </Option>
                    ))}
                  </Select>
                </div>
                <div span={3} className="cms-add-btn">
                  <IconButton
                    iconClass="cms-add-email-btn"
                    text={<span className="font-inter">{ADD}</span>}
                    handleClick={handleAddMember}
                    loading={memberLoading}
                  />
                </div>
              </div>
            </HasRight>
          )}
          <SharedUserList
            spaceLoading={spaceLoading}
            members={members}
            handleRemoveMember={handleRemoveMember}
            removeLoader={removeLoader}
            spaceName={spaceMember?.spaceMembers?.data?.name}
            handleMemberPermission={handleMemberPermission}
            setVisible={setVisible}
            visible={visible}
            handleReinviteMember={handleReinviteMember}
            reinviteLoader={reinviteLoader}
            invitedUserIds={invitedUserIds}
            setOwnerChangePopup={setOwnerChangePopup}
            ownerChangePopup={ownerChangePopup}
            handleChangeOwner={handleChangeOwner}
            ownerId={spaceOwnerId}
            ownerLoader={ownerLoader}
            allowToChangeOwner={false}
            handleInfiniteScroll={handleInfiniteScroll}
            offset={offset}
            addEmailInSharedUserList
          />
          {membersCount >= userSubscriptionInfo?.subscription?.quantity &&
            spaceOwnerId === parseInt(currentUser?.id, 10) && (
              <div>
                <UpgradePlanPopup
                  title="Want to share contacts to more users?"
                  content="Have access to additional features by switching to higher plan."
                  spaceId={spaceId}
                />
              </div>
            )}
          {userSpacePermission === ADMIN &&
            membersCount >= userSubscriptionInfo?.subscription?.quantity && (
              <WarningMessage
                content="This space is shared with you and the shared members limit is reached.
              Please contact Space Owner to add more shared members."
              />
            )}
        </div>
      </div>
    </>
  );
}

export default AddMember;
