import React, { useContext, useState, useEffect, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import map from "lodash/map";
import find from "lodash/find";
import _isInteger from "lodash/isInteger";
import { capitalize, nth } from "lodash";

//! Ant Imports
import { Form, Button } from "antd";

//! User files
import { useWindowSize } from "react-use";
import { AppContext } from "../../../../../AppContext";
import ContactForm from "../../../../../common/components/ContactForm/ContactForm";
import {
  createAmplitudeEvent,
  filterUndefinedValues,
  getTrimValues,
  isPlanActive,
  notificationToast,
} from "../../../../../common/utils";
import * as ActionTypes from "../../../../../common/actionTypes";
import {
  ADDON_PARAMS,
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  GOOGLE,
  ROUTES,
  UPGRADE_PLAN_TITLE,
  WINDOW_SIZE,
} from "../../../../../common/constants";

//! Graphql imports
import { CREATE_NEW_CONTACT } from "../../../graphql/mutations/createNewContact";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";
import { GET_TAGS_LIST } from "../../../graphql/queries/getTagsList";
import { BackArrow } from "../../../../../assets/svg";
import { GET_GROUP_LIST_V2 } from "../../../graphql/queries/getGroupListV2";
import RouterPrompt from "../../../../../common/RouterPrompt";

function AddContact() {
  const [saveButtonClick, isSaveButtonClick] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [contactCreated, isContactCreated] = useState(false);
  const [userGroups, setUserGroups] = useState();
  const { width } = useWindowSize();
  const [isPromptRequired, setIsPromptRequired] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const {
    state: {
      contacts,
      currentSideTab,
      userSubscriptionInfo,
      contactsCount,
      currentUser,
    },
    dispatch,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [groups, setGroups] = useState();
  const [updateButton, setUpdateButton] = useState(true);
  const { spaceId } = useParams();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const selectedGroup = currentSideTab ?? localStorage.getItem("currentGroup");
  const selectedTag = currentSideTab ?? localStorage.getItem("currentTag");
  const submitRef = useRef();
  const curGroupId = parseInt(useParams()[0]?.split("/")[1], 10);

  useEffect(() => {
    localStorage.removeItem("userProfile");
    setInitialValues({
      firstName: "",
      middleName: "",
      lastName: "",
      emailAddress: [{ type: "work", value: "" }],
      anniversary: null,
      birthday: null,
      jobTitle: "",
      company: "",
      addresses: [
        {
          label: "home",
          line1: "",
          line2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      ],
      phoneNumbers: [{ type: "Home", value: "" }],
      website: [{ type: "work", value: "" }],
      socialProfile: [{ type: "facebook", value: "" }],
      notes: "",
      tags:
        pathname?.split("/").includes("tag") && selectedTag
          ? [selectedTag]
          : [],
      groups:
        pathname?.split("/").includes("group") && selectedGroup
          ? [selectedGroup]
          : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, selectedTag]);

  useEffect(() => {
    if (JSON?.parse(localStorage.getItem(ADDON_PARAMS))?.length > 0) {
      setUpdateButton(false);
      const addonParams = JSON.parse(localStorage.getItem(ADDON_PARAMS));
      const fullName = nth(addonParams, 0);
      const promise = setInitialValues({
        firstName: capitalize(
          fullName.indexOf(" ") >= 0
            ? fullName.substring(0, fullName.indexOf(" "))
            : fullName.substring(fullName.indexOf(" ") + 1),
        ),
        middleName: "",
        lastName: capitalize(
          fullName.indexOf(" ") >= 0
            ? fullName.substring(fullName.indexOf(" ") + 1)
            : "",
        ),
        emailAddress: [
          {
            type: "work",
            value: nth(addonParams, 1),
          },
        ],
        anniversary: null,
        birthday: null,
        jobTitle: "",
        company: "",
        addresses: [
          {
            label: "home",
            line1: "",
            line2: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
        ],
        phoneNumbers: [{ type: "Home", value: "" }],
        website: [{ type: "work", value: "" }],
        socialProfile: [{ type: "facebook", value: "" }],
        tags: [],
        notes: "",
        groups: [],
      });

      Promise.all([promise]).then(() => {
        localStorage.removeItem(ADDON_PARAMS);
      });
    } else {
      setInitialValues({
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: [{ type: "work", value: "" }],
        anniversary: null,
        birthday: null,
        jobTitle: "",
        company: "",
        addresses: [
          {
            label: "home",
            line1: "",
            line2: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
        ],
        phoneNumbers: [{ type: "Home", value: "" }],
        website: [{ type: "work", value: "" }],
        socialProfile: [{ type: "facebook", value: "" }],
        tags:
          pathname?.split("/").includes("tag") && selectedTag
            ? [selectedTag]
            : [],
        groups:
          pathname?.split("/").includes("group") && selectedGroup
            ? [selectedGroup]
            : [],
        notes: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getGroupListV2] = useLazyQuery(GET_GROUP_LIST_V2, {
    fetchPolicy: "network-only",
    variables: {
      offset: 0,
      limit: CONTACT_LIMIT,
      spaceId: parseInt(spaceId, 10),
    },

    onError() {},
  });

  const [createContact, { loading: contactLoading }] = useMutation(
    CREATE_NEW_CONTACT,
    {
      refetchQueries: [
        {
          query: GET_SPACE_DETAIL_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
        {
          query: GET_TAGS_LIST,
          fetchPolicy: "network-only",
          variables: {
            offset: 0,
            limit: CONTACT_LIMIT,
            spaceId: parseInt(spaceId, 10),
          },
        },
      ],
      onCompleted(data) {
        const eventProperties = {
          "Contact Id": data?.createContact?.data?.id,
          "Contact Name":
            // eslint-disable-next-line prefer-template
            data.createContact.data.firstName +
            " " +
            data.createContact.data.middleName +
            " " +
            data.createContact.data.lastName,
          "Group Id": groups ?? "-",
          "Group Name": map(data?.createContact?.data?.groups, (n) => {
            return n.name;
          }),
          "Space Id": spaceId ?? spaceId,
          "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
          "Plan status": userSubscriptionInfo?.subscription?.planStatus,
        };
        isSaveButtonClick(false);
        setUpdateButton(true);
        setInitialValues({
          firstName: "",
          lastName: "",
          emailAddress: [{ type: "work", value: "" }],
          tags: initialValues?.tags?.length <= 0 ? [] : initialValues?.tags,
          groups:
            initialValues?.groups?.length <= 0 ? [] : initialValues?.groups,
          middleName: "",
          anniversary: null,
          birthday: null,
          jobTitle: "",
          company: "",
          addresses: [
            {
              label: "home",
              line1: "",
              line2: "",
              city: "",
              state: "",
              country: "",
              zipCode: "",
            },
          ],
          phoneNumbers: [{ type: "Home", value: "" }],
          website: [{ type: "work", value: "" }],
          socialProfile: [{ type: "facebook", value: "" }],
          notes: "",
        });
        form.resetFields();
        localStorage.removeItem("userProfile");
        isContactCreated(true);
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.CONTACT_CREATED,
          eventProperties,
        );
        if (data?.createContact?.data) {
          dispatch({
            type: ActionTypes.SET_CONTACTS,
            data: [data?.createContact?.data, ...contacts],
          });
          dispatch({
            type: ActionTypes.SET_CONTACTS_COUNT,
            data: contactsCount + 1,
          });
        }
        if (
          pathname?.includes(`${ROUTES?.CONTACTS}/${spaceId}/all/contact/new`)
        ) {
          navigate(`${ROUTES?.CONTACTS}/${spaceId}/all`);
        }
      },
      onError() {},
    },
  );

  const [getGroupInfo] = useLazyQuery(GET_GROUP_INFO_V2, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(curGroupId, 10),
    },
    onError() {},
  });

  const handleContactSubmit = (values, setProfileImage) => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      )
    ) {
      if (
        parseInt(userSubscriptionInfo.contactsCount + 1, 10) >=
        parseInt(
          userSubscriptionInfo?.subscription?.product?.features?.f_contacts,
          10,
        )
      ) {
        dispatch({
          type: ActionTypes.SET_SPACE_PLAN_STATUS,
          data: {
            planExpired: true,
            message: "You've reached your contact limit",
          },
        });
        return false;
      }
      const formValues = getTrimValues(values);
      const groupIds = [];
      const tags = [];

      if (formValues?.groups) {
        // eslint-disable-next-line array-callback-return
        formValues?.groups.map((group) => {
          if (typeof group === "number") {
            groupIds.push(group);
            setGroups(groupIds);
          } else {
            const ids = find(userGroups, (e) => {
              if (e?.name.toLowerCase() === group.toLowerCase()) {
                return e?.id;
              }
            });
            if (
              ids?.id === undefined &&
              curGroupId !== "all" &&
              curGroupId !== "new" &&
              curGroupId !== null
            ) {
              groupIds.push(curGroupId);
            } else {
              groupIds.push(parseInt(ids?.id, 10));
            }
            setGroups(groupIds);
          }
        });

        if (formValues?.tags) {
          // eslint-disable-next-line array-callback-return
          formValues?.tags.map((tag) => {
            tags.push(tag.toLowerCase());
          });
        }
      }
      const data = {
        sourceType: "USER",
        photo: localStorage.getItem("userProfile") ?? null,
        firstName: formValues?.firstName.trim(),
        middleName: formValues?.middleName,
        lastName: formValues?.lastName,
        birthday: formValues?.birthday,
        anniversary: formValues?.anniversary,
        contactAddresses: formValues?.addresses
          ? filterUndefinedValues(formValues?.addresses, "address")
          : null,
        emails: formValues?.emailAddress
          ? filterUndefinedValues(formValues?.emailAddress, "email")
          : null,
        numbers: formValues?.phoneNumbers
          ? filterUndefinedValues(formValues?.phoneNumbers, "phone")
          : null,
        websites: formValues?.website
          ? filterUndefinedValues(formValues?.website, "website")
          : null,
        company: formValues?.company,
        jobTitle: formValues?.jobTitle,
        groupIds: groupIds ?? null,
        tags: tags ? tags?.filter((tag) => tag?.trim()?.length > 0) : undefined,
        notes: formValues?.notes,
        socialProfiles: formValues?.socialProfile
          ? filterUndefinedValues(formValues?.socialProfile, "socialProfile")
          : null,
        spaceId: parseInt(spaceId, 10),
      };

      if (
        userSubscriptionInfo?.type === GOOGLE &&
        (data?.groupIds?.length === 0 || data?.groupIds === undefined) &&
        userSubscriptionInfo?.ownerId !== parseInt(currentUser?.id, 10)
      ) {
        notificationToast({
          message: "Please select atleast 1 group",
          type: "error",
        });
        return false;
      }

      createContact({
        variables: { data },
      })
        .then(() => {
          setProfileImage();
          localStorage?.setItem("userProfile", "");
          if (curGroupId > 0 && _isInteger(parseInt(curGroupId, 10))) {
            getGroupInfo({
              fetchPolicy: "network-only",
              variables: {
                id: parseInt(curGroupId, 10),
              },
            });
          } else {
            getGroupListV2();
          }
          dispatch({
            type: ActionTypes.SET_REFETCH_TAGS,
            data: true,
          });
        })
        .catch(() => {});
    } else {
      dispatch({
        type: ActionTypes.SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
    }
  };

  const handleSaveButtonClick = () => {
    submitRef.current.click();
  };

  const isSpacePlanActive = isPlanActive(
    userSubscriptionInfo?.subscription?.planStatus,
    userSubscriptionInfo?.subscription?.endDate,
  );

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div
      className={`cms-contact-wrapper ${
        width <= WINDOW_SIZE ? "padding-15" : ""
      }`}
    >
      <RouterPrompt
        description="You have unsaved changes! Are you sure you want to leave this page"
        when={isPromptRequired}
      />
      <div className="cms-contact-wrapper-header">
        <span
          className={`font-inter username ${
            width <= WINDOW_SIZE ? "display-flex-center" : ""
          }`}
        >
          {width <= WINDOW_SIZE && (
            <span
              className="cms-contact-form-close-arrow"
              onClick={handleBackClick}
            >
              <BackArrow />
            </span>
          )}
          Add new contact
        </span>
        <Button
          className={`${
            isImageUploading || updateButton ? "cms-disable-form-save-btn" : ""
          } font-inter cms-form-save-btn`}
          htmlType="submit"
          onClick={() => {
            handleSaveButtonClick();
            setIsPromptRequired(false);
          }}
          disabled={contactLoading || isImageUploading || updateButton}
          loading={contactLoading}
        >
          Save
        </Button>
      </div>
      <div
        className={`${
          !isSpacePlanActive ? "cms-contact-form-height" : ""
        } cms-contact-form cms-contact-scrollbar`}
      >
        {initialValues && (
          <ContactForm
            spaceId={spaceId}
            handleContactSubmit={handleContactSubmit}
            name="cms-create-contact"
            form={form}
            saveButtonClick={saveButtonClick}
            initialValues={initialValues}
            contactLoading={contactLoading}
            contactCreated={contactCreated}
            setUserGroups={setUserGroups}
            setIsPromptRequired={setIsPromptRequired}
            setIsImageUploading={setIsImageUploading}
            submitRef={submitRef}
            setUpdateButton={setUpdateButton}
          />
        )}
      </div>
    </div>
  );
}

export default AddContact;
