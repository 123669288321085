import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

//! Ant import
import { Row } from "antd";

//! User imports
import { DocumentIcon, Summary, Attachment } from "../../../../../assets/svg";
import ContactActivityContainer from "./ContactActivityContainer";
import ContactNotesContainer from "./ContactNotesContainer";
import ContactTabs from "./ContactTab";
import ContactDocumentsContainer from "./ContactDocumentsContainer";
import { AppContext } from "../../../../../AppContext";
import { SET_USER_SUBSCRIPTION_INFO } from "../../../../../common/actionTypes";

//! Graphql files
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";

function ContactTabsContainer({ contactPermission, contactDetail }) {
  const navigate = useNavigate();
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);

  const handleTabsRoute = (route) => {
    navigate(route);
  };

  const [getSpaceDetail] = useLazyQuery(GET_SPACE_DETAIL_V2, {
    fetchPolicy: "network-only",
    onCompleted({ spaceV2 }) {
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: spaceV2,
      });
    },
    onError() {
      localStorage.setItem("currentSpace", currentUser?.spaceId);
      navigate(`/contacts/${currentUser?.spaceId}/group/all`);
    },
  });

  useEffect(() => {
    if (contactDetail?.spaceId) {
      getSpaceDetail({
        variables: {
          id: parseInt(contactDetail?.spaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactDetail]);

  const tabsArray = [
    {
      icon: (
        <DocumentIcon
          className="cms-tab-social-icons"
          onClick={() => handleTabsRoute("?tab=notes")}
        />
      ),
      component: (
        <ContactNotesContainer
          contactPermission={contactPermission}
          contactDetail={contactDetail}
        />
      ),
      tab: "notes",
    },
    {
      icon: (
        <Attachment
          className="cms-tab-social-icons"
          onClick={() => handleTabsRoute("?tab=documents")}
        />
      ),
      component: (
        <ContactDocumentsContainer
          contactPermission={contactPermission}
          contactDetail={contactDetail}
        />
      ),
      tab: "documents",
    },
    {
      icon: (
        <Summary
          className="cms-tab-social-icons"
          onClick={() => handleTabsRoute("?tab=activity")}
        />
      ),
      component: (
        <ContactActivityContainer
          contactPermission={contactPermission}
          contactDetail={contactDetail}
        />
      ),
      tab: "activity",
    },
    //! Will remove after testing
    // {
    //   icon: (
    //     <Twitter
    //       className="cms-tab-social-icons"
    //       onClick={() => handleTabsRoute("?tab=twitter")}
    //     />
    //   ),
    //   component: (
    //     <div className="twitter-content">
    //       <div className="twitter-content-icon">
    //         <Twitter />
    //       </div>
    //       <div className="dropdown-menu-content">
    //         <div className="dropdown-menu-text font-inter">Coming Soon</div>
    //         <div className="dropdown-menu-button full-width">
    //           <Button
    //             onClick={handleNotify}
    //             loading={notifyLoader}
    //             className="dropdown-menu-button-section"
    //           >
    //             Notify me
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //   ),
    //   tab: "twitter",
    // },
    //! For MVP we are hiding facebook and instagram options
    // {
    //   icon: <Facebook className="cms-tab-social-icons" onClick={() => handleTabsRoute('?tab=facebook')}/>,
    //   component: <UpgradeProPopup content="Upgrade to Pro to see Vaastav's facebook posts" icon={<Facebook className="cms-tab-icon" />} />,
    //   tab: "facebook"
    // },
    // {
    //   icon: <Instagram className="cms-tab-social-icons" onClick={() => handleTabsRoute('?tab=instagram')}/>,
    //   component: <UpgradeProPopup content="Upgrade to Pro to see Vaastav's instagram posts" icon={<Instagram className="cms-tab-icon"/>} />,
    //   tab: "instagram"
    // }
  ];

  return (
    <Row>
      <div className="contact-tabs">
        <ContactTabs tabsArray={tabsArray} />
      </div>
    </Row>
  );
}

export default ContactTabsContainer;
