import React from "react";

//! Antd imports
import { Row, Col } from "antd";

//! User files
import {
  Phone,
  Mail,
  Location,
  PersonalInfo,
  CalendarIcon,
  RemoveFieldIcon,
  ContactPerson,
  DocumentIcon,
} from "../../../assets/svg";
import ContactMetaDetailsItem from "../../../modules/main/modules/contacts/components/ContactMetaDetailsItem";
import AddContactInfoRow from "../../../modules/main/modules/contacts/components/AddContactInfoRow";
import { defaultDateFormat } from "../../constants";
import { filterUndefinedValues, formatDate, isAddressNull } from "../../utils";

function ContactMetaDetailItemFields({
  numbers,
  emails,
  websites,
  contactAddresses,
  handleRoute,
  anniversary,
  birthday,
  notes = "",
  socialProfiles,
  mergeContact,
  filterValueFromPreviewMerge,
  isEditable,
  isContactMerged,
}) {
  const shouldBirthdayRender = !isEditable
    ? !(
        (birthday === null ||
          birthday?.length === 0 ||
          birthday?.value === null) &&
        (anniversary === null ||
          anniversary?.length === 0 ||
          anniversary?.value === null)
      )
    : true;

  const shouldAddressRender = !isEditable
    ? !isAddressNull(contactAddresses)
    : true;
  const notesMultiline = typeof notes === "string" ? notes?.split("\n") : notes;

  return (
    <>
      {numbers?.length !== 0 &&
        (!mergeContact ||
          (mergeContact &&
            numbers?.length !== 0 &&
            numbers !== null &&
            filterUndefinedValues(numbers, "phone")?.length > 0)) && (
          <ContactMetaDetailsItem
            icon={<Phone />}
            data={numbers}
            handleRoute={() => handleRoute("phone")}
            text="add phone"
            defaultValue="Mobile"
            type="Mobile"
            filterValueFromPreviewMerge={filterValueFromPreviewMerge}
            actualType="numbers"
            mergeContact={mergeContact}
            isEditable={isEditable}
            isContactMerged={isContactMerged}
          />
        )}
      {birthday?.length !== 0 &&
        birthday?.length !== undefined &&
        shouldBirthdayRender &&
        (!mergeContact ||
          (mergeContact &&
            ((birthday !== null &&
              birthday?.length !== 0 &&
              birthday?.value !== null) ||
              (anniversary !== null &&
                anniversary?.length !== 0 &&
                anniversary?.value !== null)))) && (
          <div className="contact-info-mobile">
            <div className="contact-info-mobile-phone">
              <CalendarIcon />
            </div>
            <div className="contact-info-mobile-number">
              <div className="contact-info-wrapper">
                {birthday || anniversary ? (
                  <>
                    {(birthday?.length > 0 || birthday?.value?.length > 0) && (
                      <Row
                        className={`${
                          birthday?.new ? "cms-highlight-text" : ""
                        } contact-info-wrapper-info`}
                      >
                        <Col
                          span={4}
                          className="contact-info-wrapper-text"
                          title="Birthday"
                        >
                          Birthday
                        </Col>
                        <Col
                          span={16}
                          className="cms-contact-detail-field cms-font-ellipsis"
                        >
                          {
                            // eslint-disable-next-line no-nested-ternary
                            mergeContact
                              ? birthday?.value === null
                                ? "-"
                                : formatDate(
                                    new Date(birthday?.value || birthday),
                                    defaultDateFormat,
                                  )
                              : formatDate(
                                  new Date(birthday),
                                  defaultDateFormat,
                                )
                          }
                        </Col>
                        {birthday?.new && !isContactMerged && (
                          <Col span={4} className="remove-merge-contact-field">
                            <RemoveFieldIcon
                              onClick={() =>
                                filterValueFromPreviewMerge(
                                  birthday?.id,
                                  "birthday",
                                  birthday?.value,
                                )
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    )}
                    {(anniversary?.length > 0 ||
                      anniversary?.value?.length > 0) && (
                      <Row
                        className={`${
                          anniversary?.new ? "cms-highlight-text" : ""
                        } contact-info-wrapper-info`}
                      >
                        <Col
                          span={4}
                          className="contact-info-wrapper-text"
                          title="Anniversary"
                        >
                          Anniversary
                        </Col>
                        <Col
                          span={16}
                          className="cms-contact-detail-field cms-font-ellipsis"
                        >
                          {
                            // eslint-disable-next-line no-nested-ternary
                            mergeContact
                              ? anniversary?.value === null
                                ? "-"
                                : formatDate(
                                    new Date(anniversary?.value || anniversary),
                                    defaultDateFormat,
                                  )
                              : formatDate(
                                  new Date(anniversary),
                                  defaultDateFormat,
                                )
                          }
                        </Col>
                        {anniversary?.new && !isContactMerged && (
                          <Col span={4} className="remove-merge-contact-field">
                            <RemoveFieldIcon
                              onClick={() =>
                                filterValueFromPreviewMerge(
                                  anniversary?.id,
                                  "anniversary",
                                  anniversary?.value,
                                )
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    )}
                  </>
                ) : (
                  !mergeContact && (
                    <AddContactInfoRow
                      text="add date"
                      handleRoute={() => handleRoute("date")}
                    />
                  )
                )}
              </div>
              {/* <div className="contact-info-mobile-border" /> */}
            </div>
          </div>
        )}
      {notes?.length !== 0 &&
        notes?.length !== undefined &&
        (!mergeContact ||
          (mergeContact &&
            notes !== null &&
            notes?.length !== 0 &&
            notes?.value !== null)) && (
          <div className="contact-info-mobile">
            <div className="contact-info-mobile-phone">
              <DocumentIcon width={16} height={16} />
            </div>
            <div className="contact-info-mobile-number">
              <div className="contact-info-wrapper">
                {notes && notes?.length > 0 && (
                  <Row className="contact-info-wrapper-info notes">
                    <Col
                      span={4}
                      className="contact-info-wrapper-text"
                      title="Notes"
                    >
                      Notes
                    </Col>
                    <Col
                      span={16}
                      className="cms-contact-detail-field notes cms-font-ellipsis"
                    >
                      {
                        // eslint-disable-next-line no-nested-ternary
                        mergeContact
                          ? Array?.isArray(notes) && notes?.length > 0
                            ? notes?.map((item) => (
                                <p key={item?.id}>{item?.note}</p>
                              ))
                            : "-"
                          : notesMultiline?.length > 0 &&
                            React?.Children?.map(notesMultiline, (child) =>
                              React?.cloneElement(<div>{child}</div>),
                            )
                      }
                    </Col>
                  </Row>
                )}
              </div>
              {/* <div className="contact-info-mobile-border" /> */}
            </div>
          </div>
        )}
      {emails?.length !== 0 &&
        (!mergeContact ||
          (mergeContact &&
            emails?.length !== 0 &&
            emails !== null &&
            filterUndefinedValues(emails, "email")?.length > 0)) && (
          <ContactMetaDetailsItem
            icon={<Mail />}
            data={emails}
            handleRoute={() => handleRoute("email")}
            text="add email"
            defaultValue="Work"
            type="Email"
            actualType="emails"
            filterValueFromPreviewMerge={filterValueFromPreviewMerge}
            mergeContact={mergeContact}
            isEditable={isEditable}
            isContactMerged={isContactMerged}
          />
        )}
      {websites?.length !== 0 &&
        (!mergeContact ||
          (mergeContact &&
            websites?.length !== 0 &&
            websites !== null &&
            filterUndefinedValues(websites, "website")?.length > 0)) && (
          <ContactMetaDetailsItem
            icon={<PersonalInfo />}
            data={websites}
            handleRoute={() => handleRoute("website")}
            text="add website"
            defaultValue="Personal"
            type="Website"
            actualType="websites"
            filterValueFromPreviewMerge={filterValueFromPreviewMerge}
            mergeContact={mergeContact}
            isEditable={isEditable}
            isContactMerged={isContactMerged}
          />
        )}
      {contactAddresses?.length !== 0 &&
        shouldAddressRender &&
        (!mergeContact ||
          (mergeContact &&
            contactAddresses?.length !== 0 &&
            contactAddresses !== null &&
            !isAddressNull(contactAddresses))) && (
          <ContactMetaDetailsItem
            icon={<Location />}
            data={contactAddresses}
            handleRoute={() => handleRoute("address")}
            text="add address"
            defaultValue="Home"
            type="Address"
            filterValueFromPreviewMerge={filterValueFromPreviewMerge}
            mergeContact={mergeContact}
            isEditable={isEditable}
            isContactMerged={isContactMerged}
          />
        )}
      {socialProfiles?.length !== 0 &&
        (!mergeContact ||
          (mergeContact &&
            socialProfiles?.length !== 0 &&
            socialProfiles !== null &&
            filterUndefinedValues(socialProfiles, "socialProfile"))) && (
          <ContactMetaDetailsItem
            icon={<ContactPerson />}
            data={socialProfiles}
            handleRoute={() => handleRoute("socialProfile")}
            text="add social profile"
            defaultValue="Facebook"
            type="SocialProfile"
            actualType="socialProfile"
            filterValueFromPreviewMerge={filterValueFromPreviewMerge}
            mergeContact={mergeContact}
            isEditable={isEditable}
            isContactMerged={isContactMerged}
          />
        )}
    </>
  );
}

export default ContactMetaDetailItemFields;
