import React, { useContext, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import find from "lodash/find";
import _isInteger from "lodash/isInteger";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

//! Antd import
import { Checkbox, Divider } from "antd";

//! User files
import {
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  GOOGLE,
  USER,
} from "../../../common/constants";
import { AppContext } from "../../../AppContext";
import {
  createAmplitudeEvent,
  notificationToast,
  validateEmail,
} from "../../../common/utils";
import {
  SET_SPACE_PLAN_STATUS,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../../common/actionTypes";
import NoLabelFound from "./NoLabelFound";
import SpaceSharingComponent from "../../../common/components/SpaceSharingComponent/SpaceSharingComponent";
import SpaceListSkeleton from "../../../common/components/SpaceListSkeleton/SpaceListSkeleton";
import Loading from "../../../common/components/Loading";

//! Graphql files
import { ADD_MEMBER_TO_SPACE } from "../../main/graphql/mutations/addMemberToSpace";
import { REMOVE_MEMBER_FROM_SPACE } from "../../main/graphql/mutations/removeMemberFromSpace";
import { UPDATE_SPACE_PERMISSION } from "../../main/graphql/mutations/updateSpacePermission";
import { LIST_DOMAIN_USERS_AND_GROUPS } from "../graphql/Queries";
import SHARE_MULTIPLE_GROUP_TO_USER from "../../main/graphql/mutations/shareMultiGroupToUser";
import { GET_SPACE_DETAIL_V2 } from "../../main/graphql/queries/getSpaceDetailV2";
import { GET_SPACE_MEMBERS } from "../../main/graphql/queries/getSpaceMembers";
import { GET_GROUP_LIST_V2 } from "../../main/graphql/queries/getGroupListV2";

function SpaceSharing({ email, setEmail }) {
  const {
    state: { userSubscriptionInfo, currentUser },
    dispatch,
  } = useContext(AppContext);
  const inputFocus = useRef();

  const [permission, setPermission] = useState("VIEW");
  const [members, setMembers] = useState([]);
  const [visible, setVisible] = useState();
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [domainUsers, setDomainUsers] = useState([]);
  const [membersCount, setMembersCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const spaceId = currentUser?.spaceId;

  const { data: spaceDetail } = useQuery(GET_SPACE_DETAIL_V2, {
    fetchPolicy: "network-only",
    variables: {
      id: spaceId ? parseInt(spaceId, 10) : currentUser?.spaceId,
    },
  });

  const [getSpaceMembers, { loading: spaceLoading, data: spaceMember }] =
    useLazyQuery(GET_SPACE_MEMBERS, {
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(spaceId, 10),
        data: {
          offset: 0,
          limit: CONTACT_LIMIT,
        },
      },
    });

  const [getGroupList, { data: groupList, loading: groupListLoader }] =
    useLazyQuery(GET_GROUP_LIST_V2, {
      fetchPolicy: "network-only",
      variables: {
        offset: offset,
        limit: CONTACT_LIMIT,
        spaceId: parseInt(spaceId, 10),
      },
      onError() {},
    });

  const [listDomainUsersAndGroups, { data: domainMembers }] = useLazyQuery(
    LIST_DOMAIN_USERS_AND_GROUPS,
    {
      onCompleted() {},
      onError() {},
    },
  );

  const [addMemberToSpace, { loading: memberLoading }] = useMutation(
    ADD_MEMBER_TO_SPACE,
    {
      refetchQueries: [
        {
          query: GET_SPACE_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
            data: {
              offset: 0,
              limit: CONTACT_LIMIT,
            },
          },
        },
      ],
    },
  );

  const [removeMember, { loading: removeLoader }] = useMutation(
    REMOVE_MEMBER_FROM_SPACE,
    {
      refetchQueries: [
        {
          query: GET_SPACE_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
            data: {
              offset: 0,
              limit: CONTACT_LIMIT,
            },
          },
        },
      ],
      onCompleted() {
        setVisible(false);
      },
    },
  );

  const [updatePermission] = useMutation(UPDATE_SPACE_PERMISSION, {
    onCompleted() {},
  });

  const [multipleGroupSharing, { loading: sharingLoader }] = useMutation(
    SHARE_MULTIPLE_GROUP_TO_USER,
    {
      refetchQueries: [
        {
          query: GET_SPACE_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
            data: {
              offset: 0,
              limit: CONTACT_LIMIT,
            },
          },
        },
      ],
      onError() {},
    },
  );

  useEffect(() => {
    if (spaceId && _isInteger(parseInt(spaceId, 10))) {
      getSpaceMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    inputFocus?.current?.focus();
  }, []);

  useEffect(() => {
    if (spaceDetail?.spaceV2) {
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: spaceDetail?.spaceV2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDetail]);

  useEffect(() => {
    if (spaceDetail?.spaceV2?.type === GOOGLE) {
      getGroupList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDetail]);

  useEffect(() => {
    if (groupList) {
      // setGroups(groupList?.groupListV2?.groups);
      setGroups(
        sortBy(
          uniqBy([...groups, ...groupList.groupListV2.groups], "id"),
          "name",
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupList]);

  useEffect(() => {
    const memberArr = [];
    if (spaceMember?.spaceMembers?.data) {
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: spaceMember?.spaceMembers?.data,
      });
    }
    if (spaceMember?.spaceMembers?.data?.members) {
      memberArr.push(...spaceMember.spaceMembers.data.members);
    }
    setMembersCount(spaceMember?.spaceMembers?.data?.membersCount);
    setMembers(memberArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceMember]);

  useEffect(() => {
    if (domainMembers?.listDomainUsersAndGroups?.users?.length > 0) {
      setDomainUsers([...domainMembers.listDomainUsersAndGroups.users]);
    }
  }, [domainMembers]);

  useEffect(() => {
    if (userSubscriptionInfo?.type === GOOGLE) {
      listDomainUsersAndGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptionInfo]);

  const handleAddMember = () => {
    if (checkedList?.length === 0 && userSubscriptionInfo?.type === GOOGLE) {
      notificationToast({
        message: `Please select labels to share`,
        type: "error",
      });
      return;
    }
    if (!email) {
      inputFocus?.current?.focus();
      return;
    }
    if (membersCount >= userSubscriptionInfo?.subscription?.quantity) {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: {
          planExpired: true,
          message: "Space sharing limit has been exceeded!",
        },
      });
      setEmail("");
      setPermission("VIEW");
      return false;
    }
    if (email) {
      const isValidEmail = validateEmail(email);
      if (isValidEmail) {
        const isUserInvited = find(members, (e) => e?.email === email);
        if (isUserInvited) {
          notificationToast({
            message: `User ${email} is already is the list`,
            type: "warning",
          });
        } else {
          setEmail("");
          if (userSubscriptionInfo?.type === GOOGLE) {
            const groupIds = [];
            checkedList?.map((name) =>
              groupIds.push(
                parseInt(find(groups, (e) => e?.name === name)?.id, 10),
              ),
            );
            const emailType = find(
              domainUsers,
              (e) => e?.email === email,
            )?.type;
            multipleGroupSharing({
              variables: {
                groupIds: checkAll ? [] : groupIds,
                data: {
                  permissions: permission,
                  spaceId: parseInt(spaceId, 10),
                  email: email?.toLowerCase(),
                  emailType: emailType || USER,
                },
              },
            }).then(() => {
              setPermission("VIEW");
              const eventProperties = {
                "Space Id": spaceId,
                "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
                "Plan status": userSubscriptionInfo?.subscription?.planStatus,
                "Group Id": groupIds,
                "Shared with": email,
              };
              createAmplitudeEvent(
                AMPLITUDE_EVENT_LOG.GROUP_SHARED,
                eventProperties,
              );
            });
          } else {
            addMemberToSpace({
              variables: {
                data: {
                  permissions: permission,
                  spaceId: parseInt(spaceId, 10),
                  email: email?.toLowerCase(),
                },
              },
            }).then(() => {
              setPermission("VIEW");
              const eventProperties = {
                "Space Id": spaceId,
                "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
                "Plan status": userSubscriptionInfo?.subscription?.planStatus,
                "Shared With": email,
              };
              createAmplitudeEvent(
                AMPLITUDE_EVENT_LOG.SPACE_SHARED,
                eventProperties,
              );
            });
          }
        }
      } else {
        notificationToast({
          message: `Please enter valid email`,
          type: "error",
        });
      }
    }
  };

  const handleRemoveMember = (memberId, memberName) => {
    if (memberId) {
      removeMember({
        variables: {
          id: memberId,
        },
      })
        .then(() => {
          const eventProperties = {
            "Space Id": spaceId,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
            "Removed from": memberId,
            "User Name": memberName,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.SPACE_REVOKED,
            eventProperties,
          );
        })
        .catch(() => {});
    }
  };

  const handleMemberPermission = (value, memberId) => {
    if (value && memberId) {
      updatePermission({
        variables: {
          id: parseInt(memberId, 10),
          permissions: value,
          spaceId: parseInt(spaceId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const onSelectLabel = (list) => {
    setCheckedList(list);
    setCheckAll(list?.length === groups?.length);
  };

  const handleSelectAll = (e) => {
    setCheckAll(e?.target?.checked);
    setCheckedList(
      e?.target?.checked ? groups?.map((group) => group?.name) : [],
    );
  };

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      !groupListLoader &&
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      groups?.length < groupList?.groupListV2?.count
    ) {
      setOffset(offset + CONTACT_LIMIT);
    }
  };

  return (
    <div className="cms-space-sharing-box">
      <div className="cms-space-sharing-box-block">
        {spaceLoading || (groupListLoader && offset === 0) ? (
          <SpaceListSkeleton loading={spaceLoading} />
        ) : (
          <>
            {userSubscriptionInfo?.type === GOOGLE &&
              /* //! No labels found */
              (groups?.length === 0 ? (
                <NoLabelFound />
              ) : (
                /* //! Label list for google users  */
                <>
                  <div className="cms-space-sharing-box-block-label">
                    <Checkbox
                      className="font-inter cms-label-select-all"
                      checked={checkAll}
                      onChange={handleSelectAll}
                    >
                      Select All Labels
                    </Checkbox>
                    <Divider className="cms-label-divider" />
                    <div
                      className="cms-space-sharing-box-block-label-checkbox-section cms-contact-scrollbar"
                      onScroll={handleInfiniteScroll}
                    >
                      <Checkbox.Group
                        options={groups?.map((group) => group?.name)}
                        onChange={(e) => onSelectLabel(e)}
                        value={checkedList}
                        key={groups?.map((group) => group?.id)}
                      />
                      {groupListLoader && (
                        <div className="cms-infinite-scroll-loader">
                          <Loading />
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider type="vertical" className="cms-section-divider" />
                  <SpaceSharingComponent
                    email={email}
                    setEmail={setEmail}
                    inputFocus={inputFocus}
                    setPermission={setPermission}
                    members={members}
                    setVisible={setVisible}
                    visible={visible}
                    handleRemoveMember={handleRemoveMember}
                    handleMemberPermission={handleMemberPermission}
                    handleAddMember={handleAddMember}
                    removeLoader={removeLoader}
                    memberLoading={memberLoading}
                    isGoogleSpace={userSubscriptionInfo?.type === GOOGLE}
                    domainUsers={domainUsers}
                    sharingLoader={sharingLoader}
                    spaceName={spaceMember?.spaceMembers?.data?.name}
                    membersCount={membersCount}
                  />
                </>
              ))}

            {/* //! Normal onboarding flow */}
            {userSubscriptionInfo?.type !== GOOGLE && (
              <SpaceSharingComponent
                email={email}
                setEmail={setEmail}
                inputFocus={inputFocus}
                setPermission={setPermission}
                members={members}
                setVisible={setVisible}
                visible={visible}
                handleRemoveMember={handleRemoveMember}
                handleMemberPermission={handleMemberPermission}
                handleAddMember={handleAddMember}
                removeLoader={removeLoader}
                memberLoading={memberLoading}
                isGoogleSpace={userSubscriptionInfo?.type === GOOGLE}
                domainUsers={domainUsers}
                sharingLoader={sharingLoader}
                spaceName={spaceMember?.spaceMembers?.data?.name}
                membersCount={membersCount}
              />
            )}
            {/* //! End */}
          </>
        )}
      </div>
    </div>
  );
}

export default SpaceSharing;
