import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";

//! Antd imports
import { Col } from "antd";

function ListPanel({ children, hidePaths, size, isReminder }) {
  const location = useLocation();
  const [showListPanel, setShowListPanel] = useState(true);

  useEffect(() => {
    const shouldHide = hidePaths.some((path) =>
      matchPath(path, location.pathname),
    );
    setShowListPanel(!shouldHide);
  }, [location.pathname, hidePaths]);

  return (
    showListPanel && (
      <Col
        span={size || 8}
        className={`${
          isReminder ? "cms-reminder-state" : ""
        } dashboard-contacts-col h-inherit`}
      >
        <div
          className={`${isReminder ? "full-height" : ""} contact-list-wrapper`}
        >
          {children}
        </div>
      </Col>
    )
  );
}

export default ListPanel;
