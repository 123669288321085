import React from "react";
import { Skeleton } from "antd";

function SpaceListSkeleton({ loading }) {
  const n = 4;
  return (
    <div className="skeleton-layout">
      {[...Array(n)].map((i, k) => (
        <Skeleton
          loading={loading}
          active
          avatar={{ shape: "circle", size: "small" }}
          paragraph={false}
          // eslint-disable-next-line react/no-array-index-key
          key={k}
        />
      ))}
    </div>
  );
}

export default SpaceListSkeleton;
