import React from "react";
import { Link } from "react-router-dom";

function NotificationLink({ link, children, closeDrawer }) {
  return (
    <Link to={link} onClick={closeDrawer}>
      {children}
    </Link>
  );
}

export default NotificationLink;
