import React from "react";
import { useParams } from "react-router-dom";

//! User files
import AddContactSourceCard from "./AddContactSourceCard";

function AddContactSource() {
  const { spaceId } = useParams();

  return (
    <div className="cms-add-contact-source-wrapper">
      <AddContactSourceCard spaceId={spaceId} />
    </div>
  );
}

export default AddContactSource;
