import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import find from "lodash/find";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";

//! Antd import
import { AutoComplete, Col, Row, Select } from "antd";

//! User files
import {
  PersonIcon,
  LeaveGroupIcon,
  DownArrowIcon,
  NotificationWarningIcon,
  DustbinIcon,
  Notification,
  UnsubscribeIcon,
  GroupShareIcon,
  OneShareIcon,
  RenameIcon,
  FolderLock,
  Folder,
} from "../../../../../assets/svg";
import { AppContext } from "../../../../../AppContext";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import {
  capitalizeWord,
  createAmplitudeEvent,
  generateRandomColor,
  hasGroupRight,
  isPlanActive,
  notificationToast,
  validateEmail,
} from "../../../../../common/utils";
import SharedUserList from "../../../../../common/components/SharedUserList/SharedUserList";
import {
  GROUP_PERMISSIONS,
  EDIT_GROUP_NAME,
  DELETE_GROUP,
  INVITE_USER,
  VIEW,
  EDIT,
  LEAVE_GROUP,
  AMPLITUDE_EVENT_LOG,
  OWNER,
  ADMIN,
  UPGRADE_PLAN_TITLE,
  GOOGLE,
  USER,
  CONTACT_LIMIT,
  SHARE,
} from "../../../../../common/constants";
import { SET_USER_GROUP_PERMISSION } from "../../../../../common/actionTypes";
import { HasRight } from "../../../../../common/HasRight";
import * as ActionTypes from "../../../../../common/actionTypes";
import UpgradePlanPopup from "../../../../../common/components/UpgradePlanPopup/UpgradePlanPopup";
import WarningMessage from "../../../../../common/components/WarningMessage/WarningMessage";
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import GroupCreateUpdateModal from "../../../../../common/components/GroupCreateUpdateModal/GroupCreateUpdateModal";

//! Graphql files
import { EDIT_GROUP, REMOVE_GROUP } from "../graphql/Mutations";
import { SHARE_GROUP_TO_USER } from "../../../graphql/mutations/shareGroupToUser";
import { UPDATE_SHARED_GROUP_PERMISSION } from "../../../graphql/mutations/updateSharedGroupPermission";
import { REVOKE_GROUP } from "../../../graphql/mutations/revokeGroup";
import { REINVITE_GROUP_MEMBER } from "../../../graphql/mutations/reinviteGroupMember";
import { CHANGE_GROUP_OWNER } from "../../../graphql/mutations/changeGroupOwner";
import { LEAVE_USER_GROUP } from "../../../graphql/mutations/leaveGroup";
import { UNSUBSCRIBE_GROUP_NOTIFICATION } from "../../../graphql/mutations/unSubscribeGroupNotification";
import { SUBSCRIBE_GROUP_NOTIFICATION } from "../../../graphql/mutations/subscribeGroupNotification";
import SHARE_MULTIPLE_GROUP_TO_USER from "../../../graphql/mutations/shareMultiGroupToUser";
import {
  CHECK_ADMIN_USER_AND_DOMAIN_WIDE_ACCESS,
  LIST_DOMAIN_USERS_AND_GROUPS,
} from "../../../../onboard/graphql/Queries";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";
import { GET_GROUP_MEMBERS } from "../../../graphql/queries/getGroupMembers";
import { GET_GROUP_LIST_V2 } from "../../../graphql/queries/getGroupListV2";

function GroupName({ groupData }) {
  return (
    <span className="group-setting-title-text">
      {groupData?.visibility ? <FolderLock /> : <Folder />}
      <span
        className="group-setting-title-text-name cms-font-ellipsis full-width"
        title={capitalizeWord(groupData?.name)}
      >
        {capitalizeWord(groupData?.name)}
      </span>
    </span>
  );
}

const { Option } = Select;
const { Option: AutoCompleteOption } = AutoComplete;

function GroupSetting() {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState();
  const [permission, setPermission] = useState("VIEW");
  const [visible, setVisible] = useState();
  const [sharingDetails, setSharingDetails] = useState([]);
  const [invitedUserIds, setInvitedUserIds] = useState([]);
  const [ownerChangePopup, setOwnerChangePopup] = useState(false);
  const [groupOwnerId, setGroupOwnerId] = useState();
  const [userPermission, setUserPermission] = useState();
  const [leaveGroupModal, openLeaveGroupModal] = useState();
  const [groupData, setGroupData] = useState();
  const {
    state: {
      currentUser,
      userGroupPermission,
      userSubscriptionInfo,
      isGsuiteAdmin,
    },
    dispatch,
  } = useContext(AppContext);
  const { groupId, spaceId } = useParams();
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [notificationSubscribed, isNotificationSubscribed] = useState();
  const [offset, setOffset] = useState(0);
  const inputSearch = useRef();

  const [editGroup, { loading: groupUpdateLoading }] = useMutation(EDIT_GROUP, {
    refetchQueries: [
      {
        query: GET_GROUP_INFO_V2,
        fetchPolicy: "network-only",
        variables: { id: parseInt(groupId, 10) },
      },
    ],
    onError() {},
  });

  const [shareGroupToUser, { loading: userLoading }] = useMutation(
    SHARE_GROUP_TO_USER,
    {
      refetchQueries: [
        {
          query: GET_GROUP_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            offset: 0,
            limit: CONTACT_LIMIT,
            id: parseInt(groupId, 10),
          },
        },
      ],
    },
  );

  const [deleteGroup, { loading: deleteGroupLoader }] = useMutation(
    REMOVE_GROUP,
    {
      refetchQueries: [
        {
          query: GET_GROUP_LIST_V2,
          variables: {
            offset: 0,
            limit: CONTACT_LIMIT,
            spaceId: parseInt(spaceId, 10),
          },
        },
      ],
    },
  );

  const [getSpaceDetail] = useLazyQuery(GET_SPACE_DETAIL_V2, {
    fetchPolicy: "network-only",
    onCompleted({ spaceV2 }) {
      dispatch({
        type: ActionTypes.SET_USER_SUBSCRIPTION_INFO,
        data: spaceV2,
      });
      dispatch({
        type: ActionTypes.SET_USER_SPACE_PERMISSION,
        data: spaceV2?.permission,
      });
    },
    onError() {
      localStorage.setItem("currentSpace", currentUser?.spaceId);
      navigate(`/contacts/${currentUser?.spaceId}/group/all`);
    },
  });

  const { data, loading: groupInfoLoading } = useQuery(GET_GROUP_INFO_V2, {
    variables: { id: parseInt(groupId, 10) },
    onError(error) {
      if (error?.message?.length > 0) {
        getSpaceDetail({
          variables: {
            id: parseInt(spaceId, 10),
          },
        });
        navigate(`/contacts/${spaceId}/group/all`);
      }
    },
  });

  const [getGroupMembers, { data: groupMembers, loading: groupLoading }] =
    useLazyQuery(GET_GROUP_MEMBERS, {
      fetchPolicy: "network-only",
      variables: {
        offset: offset,
        limit: CONTACT_LIMIT,
        id: parseInt(groupId, 10),
      },
      onError() {
        navigate(`/contacts/${spaceId}/group/all`);
      },
    });

  const [updateSharedGroupPermission] = useMutation(
    UPDATE_SHARED_GROUP_PERMISSION,
  );

  const [removeUserFromGroup, { loading: removeLoader }] = useMutation(
    REVOKE_GROUP,
    {
      refetchQueries: [
        {
          query: GET_GROUP_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            offset,
            limit: CONTACT_LIMIT,
            id: parseInt(groupId, 10),
          },
        },
      ],
      onCompleted() {
        setVisible(false);
      },
    },
  );

  const [reInviteGroupMember, { loading: reinviteLoader }] = useMutation(
    REINVITE_GROUP_MEMBER,
  );

  const [changeGroupOwner, { loading: ownerLoader }] = useMutation(
    CHANGE_GROUP_OWNER,
    {
      refetchQueries: [
        {
          query: GET_GROUP_INFO_V2,
          fetchPolicy: "network-only",
          variables: { id: parseInt(groupId, 10) },
        },
      ],
      onCompleted() {
        getGroupMembers();
        setOwnerChangePopup(false);
        const eventProperties = {
          "Group Id": groupId,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.OWNER_CHANGED,
          eventProperties,
        );
      },
      onError() {},
    },
  );

  const [leaveGroup, { loading: leaveGroupLoader }] = useMutation(
    LEAVE_USER_GROUP,
    {
      refetchQueries: [
        {
          query: GET_GROUP_LIST_V2,
          variables: {
            offset: 0,
            limit: CONTACT_LIMIT,
            spaceId: parseInt(spaceId, 10),
          },
        },
      ],
      onCompleted() {
        navigate({
          pathname: `/contacts/${spaceId}/group/all`,
          deletedGroupId: groupId,
        });
      },
    },
  );

  const [subscribeGroupNotification] = useMutation(
    SUBSCRIBE_GROUP_NOTIFICATION,
    {
      // eslint-disable-next-line no-shadow
      onCompleted(data) {
        if (data?.subscribeGroupNotification?.status === "Success") {
          isNotificationSubscribed(true);
        }
      },
    },
  );

  const [unSubscribeGroupNotification] = useMutation(
    UNSUBSCRIBE_GROUP_NOTIFICATION,
    {
      // eslint-disable-next-line no-shadow
      onCompleted(data) {
        if (data?.unSubscribeGroupNotification?.status === "Success") {
          isNotificationSubscribed(false);
        }
      },
    },
  );

  const [listDomainUsersAndGroups, { data: domainMembers }] = useLazyQuery(
    LIST_DOMAIN_USERS_AND_GROUPS,
    {
      onCompleted() {},
      onError() {},
    },
  );

  const [multipleGroupSharing, { loading: sharingLoader }] = useMutation(
    SHARE_MULTIPLE_GROUP_TO_USER,
    {
      refetchQueries: [
        {
          query: GET_GROUP_MEMBERS,
          fetchPolicy: "network-only",
          variables: {
            offset,
            limit: CONTACT_LIMIT,
            id: parseInt(groupId, 10),
          },
        },
      ],
      onError() {},
    },
  );

  const [checkAdminUserAndDomainAccess] = useLazyQuery(
    CHECK_ADMIN_USER_AND_DOMAIN_WIDE_ACCESS,
    {
      fetchPolicy: "network-only",
      onCompleted(adminData) {
        dispatch({
          type: ActionTypes.SET_IS_GSUITE_ADMIN,
          data: adminData?.checkAdminUserAndDomainWideAccess?.isAdmin,
        });
      },
      onError() {},
    },
  );

  useEffect(() => {
    if (groupId > 0) {
      getGroupMembers();
      setSharingDetails([]);
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    if (data?.groupInfoV2 && !groupInfoLoading) {
      setGroupOwnerId(data?.groupInfoV2?.ownerId);
      isNotificationSubscribed(data?.groupInfoV2?.notificationsSubscribed);
      dispatch({
        type: SET_USER_GROUP_PERMISSION,
        data: data?.groupInfoV2?.permission,
      });
      setUserPermission(data?.groupInfoV2?.permission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data) {
      const parsedData = data?.groupInfoV2;
      setGroupData({
        name: capitalizeWord(parsedData?.name),
        visibility: parsedData?.type === "PRIVATE",
        ownerId: parsedData?.ownerId,
        permission: parsedData?.permission,
      });
    }
  }, [data]);

  const popupText = (
    <span className="popup-text-title font-inter">
      Are you sure you want to remove the {groupData?.name} group?
    </span>
  );

  const handleConfirmation = () => {
    setOpen(!open);
  };

  const handleDeleteGroup = () => {
    deleteGroup({
      variables: {
        spaceId: parseInt(spaceId, 10),
        groupId: parseInt(groupId, 10),
      },
    })
      .then(() => {
        const eventProperties = {
          "Group Id": groupId,
          "Space Id": spaceId,
          "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
          "Plan status": userSubscriptionInfo?.subscription?.planStatus,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.GROUP_DELETED,
          eventProperties,
        );
        setOpen(!open);
        navigate({
          pathname: `/contacts/${spaceId}/group/all`,
          deletedGroupId: groupId,
        });
      })
      .catch(() => {});
  };

  const handleGroupEdit = (e, form) => {
    const { name, visibility } = e;
    editGroup({
      variables: {
        name: name?.trim(),
        type: visibility ? "PRIVATE" : "PUBLIC",
        groupId: parseInt(groupId, 10),
      },
    }).then((res) => {
      form?.resetFields();
      setEdit(false);
      const groupName = res?.data?.editGroup?.data?.name;
      if (groupName) {
        dispatch({
          type: ActionTypes.SET_CURRENT_SIDE_TAB,
          data: groupName,
        });
      }
      const eventProperties = {
        "Group Id": res?.data?.editGroup?.data?.id,
        "Group Name": res?.data?.editGroup?.data?.name,
        "Space Id": spaceId,
        "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
        "Plan status": userSubscriptionInfo?.subscription?.planStatus,
      };
      createAmplitudeEvent(AMPLITUDE_EVENT_LOG.GROUP_UPDATED, eventProperties);
      setEdit(false);
    });
  };

  const addUserToGroup = () => {
    if (!email) {
      inputSearch?.current?.focus();
      return;
    }
    if (
      sharingDetails?.length >= userSubscriptionInfo?.subscription?.quantity
    ) {
      dispatch({
        type: ActionTypes.SET_SPACE_PLAN_STATUS,
        data: {
          planExpired: true,
          message: "Space sharing limit has been exceeded!",
        },
      });
      setEmail("");
      return;
    }
    if (
      !isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      )
    ) {
      dispatch({
        type: ActionTypes.SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
      setEmail("");
      return false;
    }

    if (email) {
      const isValidEmail = validateEmail(email);
      const isUserInvited = find(sharingDetails, (e) => e?.email === email);
      if (isUserInvited) {
        notificationToast({
          message: `User ${email} is already in the list`,
          icon: <NotificationWarningIcon />,
          type: "warning",
          className: "cms-notification cms-notification-warning",
        });
        return;
      }
      if (isValidEmail) {
        if (userSubscriptionInfo?.type === GOOGLE && groupId) {
          const emailType = find(
            domainMembers?.listDomainUsersAndGroups?.users,
            (e) => e?.email === email,
          )?.type;
          multipleGroupSharing({
            variables: {
              groupIds: [parseInt(groupId, 10)],
              data: {
                permissions: permission,
                spaceId: parseInt(spaceId, 10),
                email: email?.toLowerCase(),
                emailType: emailType || USER,
              },
            },
          }).then(() => {
            getSpaceDetail({
              variables: {
                id: parseInt(spaceId, 10),
              },
            });
            setPermission("VIEW");
            const eventProperties = {
              "Space Id": spaceId,
              "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
              "Plan status": userSubscriptionInfo?.subscription?.planStatus,
              "Group Id": groupId,
              "Group Name": groupData?.name,
              "Shared with": email,
            };
            createAmplitudeEvent(
              AMPLITUDE_EVENT_LOG.GROUP_SHARED,
              eventProperties,
            );
            setEmail("");
          });
        } else {
          shareGroupToUser({
            variables: {
              groupId: parseInt(groupId, 10),
              data: {
                permissions: permission,
                email: email?.toLowerCase(),
              },
            },
          })
            .then(() => {
              getSpaceDetail({
                variables: {
                  id: parseInt(spaceId, 10),
                },
              });
              setPermission("VIEW");
              const eventProperties = {
                "Space Id": spaceId,
                "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
                "Plan status": userSubscriptionInfo?.subscription?.planStatus,
                "Group Id": groupId,
                "Group Name": groupData?.name,
                "Shared with": email,
              };
              createAmplitudeEvent(
                AMPLITUDE_EVENT_LOG.GROUP_SHARED,
                eventProperties,
              );
              setEmail("");
            })
            .catch(() => {
              getSpaceDetail({
                variables: {
                  id: parseInt(spaceId, 10),
                },
              });
            });
        }
      } else {
        notificationToast({
          message: `Please enter valid email`,
          type: "error",
        });
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleGroupPermission = (permission, id) => {
    if (permission && id) {
      updateSharedGroupPermission({
        variables: {
          id: parseInt(id, 10),
          groupId: parseInt(groupId, 10),
          permissions: permission,
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleRemoveMember = (id, memberName) => {
    if (id) {
      removeUserFromGroup({
        variables: {
          id: parseInt(id, 10),
          groupId: parseInt(groupId, 10),
        },
      })
        .then(() => {
          const updateMembers = filter(sharingDetails, (val) => val?.id !== id);
          setSharingDetails(updateMembers);
          const eventProperties = {
            "Space Id": spaceId,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
            "Group Id": groupId,
            "Removed from": id,
            "User Name": memberName,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.GROUP_REVOKED,
            eventProperties,
          );
        })
        .catch(() => {});
    }
  };

  const handleReinviteMember = (userId) => {
    if (userId) {
      setInvitedUserIds([...invitedUserIds, userId]);
      reInviteGroupMember({
        variables: {
          userId: parseInt(userId, 10),
          groupId: parseInt(groupId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleChangeOwner = (userId) => {
    if (userId) {
      setOffset(0);
      changeGroupOwner({
        variables: {
          id: parseInt(groupId, 10),
          userId: parseInt(userId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleLeaveGroup = () => {
    if (groupId) {
      leaveGroup({
        variables: {
          id: parseInt(groupId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const closeLeaveGroupModal = () => {
    openLeaveGroupModal(false);
  };

  const handleSearch = (val) => {
    setEmail(val);
  };

  const closeEditModal = () => {
    setEdit(false);
  };

  const subscribeUnsubscribeNotification = (id) => {
    if (notificationSubscribed) {
      unSubscribeGroupNotification({
        variables: {
          groupId: parseInt(id, 10),
        },
      });
    } else {
      subscribeGroupNotification({
        variables: {
          groupId: parseInt(id, 10),
        },
      });
    }
  };

  useEffect(() => {
    if (userSubscriptionInfo?.type === GOOGLE) {
      checkAdminUserAndDomainAccess();
      listDomainUsersAndGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptionInfo]);

  useEffect(() => {
    const temp = [];
    if (!groupLoading) {
      if (groupMembers) {
        temp?.push(
          ...sharingDetails,
          ...groupMembers.groupMembers.sharingDetails,
        );
      }
      setSharingDetails(uniqBy(temp, "id"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMembers]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      sharingDetails?.length < groupMembers?.groupMembers?.count
    ) {
      setOffset(sharingDetails?.length);
    }
  };

  return (
    <>
      <div className="group-setting">
        <div className="group-setting-btn">
          <div className="group-setting-back-arrow">
            <span className="font-inter group-setting-btn-back">Settings</span>
          </div>
          <div className="cms-group-cta">
            <div className="cms-leave-group">
              <IconButton
                iconClass="font-inter cms-leave-group-btn"
                icon={<LeaveGroupIcon />}
                text={
                  <span className="font-inter cms-leave-text">
                    {LEAVE_GROUP}
                  </span>
                }
                handleClick={() => openLeaveGroupModal(true)}
              />
            </div>
          </div>
        </div>
        <div className="group-setting-title">
          <Row className="group-setting-title-wrapper">
            <Col span={20} className="group-setting-title-space">
              <GroupName groupData={groupData} />
              <HasRight action={EDIT_GROUP_NAME}>
                <span className="cursor-pointer d-flex-center">
                  <RenameIcon onClick={() => setEdit(true)} />
                </span>
              </HasRight>
            </Col>
            <Col span={1} className="cursor-pointer">
              <IconButton
                iconClass="cms-icon-btn cms-bell-icon cms-icon-btn-size"
                shape="circle"
                icon={
                  notificationSubscribed ? (
                    <Notification className="setting-svg" />
                  ) : (
                    <UnsubscribeIcon />
                  )
                }
                handleClick={() => subscribeUnsubscribeNotification(groupId)}
              />
            </Col>
          </Row>
          <div className="font-inter group-setting-member-title">
            Members
            <div>
              <PersonIcon />
              <span className="group-setting-total-contacts">
                {groupMembers?.groupMembers?.count}
              </span>
            </div>
          </div>
        </div>
        <HasRight action={INVITE_USER}>
          <div className="group-setting-share-with">
            <div span={3} className="group-setting-share-with-text">
              Share with
            </div>
            <div
              span={16}
              className="group-setting-share-with-block"
              id="group-space-member"
            >
              <AutoComplete
                id="email"
                className="group-setting-share-with-input"
                type="text"
                placeholder="Email"
                onChange={(e) => setEmail(e)}
                value={email}
                disabled={!hasGroupRight(userGroupPermission, "inviteUser")}
                onSearch={handleSearch}
                getPopupContainer={() =>
                  document.getElementById("group-space-member")
                }
                ref={inputSearch}
                filterOption={(inputValue, option) =>
                  option?.value
                    ?.toUpperCase()
                    ?.indexOf(inputValue.toUpperCase()) !== -1
                }
              >
                {userSubscriptionInfo?.type === GOOGLE && isGsuiteAdmin
                  ? domainMembers?.listDomainUsersAndGroups?.users?.map(
                      (member, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <AutoCompleteOption value={member?.email} key={i}>
                          <UserAvatar
                            className="cms-owner-avatar"
                            name={member?.name}
                            color={generateRandomColor(parseInt(i, 10))}
                            photo={member?.photo}
                          />
                          {member?.email}
                        </AutoCompleteOption>
                      ),
                    )
                  : userSubscriptionInfo?.members?.map((member) => (
                      <AutoCompleteOption
                        value={member?.email}
                        key={member?.id}
                      >
                        <UserAvatar
                          className="cms-owner-avatar"
                          name={member?.name}
                          color={generateRandomColor(parseInt(member?.id, 10))}
                          photo={member?.photo}
                        />
                        {member?.email}
                      </AutoCompleteOption>
                    ))}
              </AutoComplete>
              <Select
                defaultValue="VIEW"
                className="cms-select-after"
                optionLabelProp="label"
                suffixIcon={<DownArrowIcon />}
                onChange={(val) => setPermission(val)}
                disabled={userPermission === VIEW || userPermission === EDIT}
              >
                {GROUP_PERMISSIONS.map((rights) => (
                  <Option
                    value={rights?.value}
                    label={rights?.label}
                    key={rights?.value}
                  >
                    <div className="rights-option">{rights?.label}</div>
                  </Option>
                ))}
              </Select>
            </div>
            <div span={3} className="group-setting-share-with-btn-wrapper">
              <IconButton
                iconClass={`${
                  userPermission === VIEW || userPermission === EDIT
                    ? "group-setting-share-with-add-disable"
                    : ""
                }
                group-setting-share-with-add`}
                text={<span className="font-inter">{SHARE}</span>}
                handleClick={addUserToGroup}
                loading={userLoading || sharingLoader}
                disable={userPermission === VIEW || userPermission === EDIT}
              />
            </div>
          </div>
        </HasRight>
        <SharedUserList
          members={sharingDetails}
          spaceLoading={groupLoading}
          group
          handleMemberPermission={handleGroupPermission}
          setVisible={setVisible}
          visible={visible}
          spaceName={capitalizeWord(groupData?.name)}
          handleRemoveMember={handleRemoveMember}
          handleReinviteMember={handleReinviteMember}
          reinviteLoader={reinviteLoader}
          invitedUserIds={invitedUserIds}
          setOwnerChangePopup={setOwnerChangePopup}
          ownerChangePopup={ownerChangePopup}
          handleChangeOwner={handleChangeOwner}
          ownerId={groupOwnerId}
          ownerLoader={ownerLoader}
          userPermission={userPermission}
          removeLoader={removeLoader}
          allowToChangeOwner
          offset={offset}
          handleInfiniteScroll={handleInfiniteScroll}
          addEmailInSharedUserList
        />
        <HasRight action={DELETE_GROUP}>
          <div className="cms-delete-group">
            <IconButton
              type="text"
              iconClass="font-inter cms-delete-group-btn"
              icon={<DustbinIcon />}
              text={
                <span className="font-inter cms-leave-text">Delete Group</span>
              }
              handleClick={handleConfirmation}
              disable={edit}
            />
          </div>
        </HasRight>
        {userSubscriptionInfo?.members?.length === 1 && (
          <>
            <div className="cms-group-share-empty-state">
              <div className="cms-group-share-empty-state-top-section">
                <span>
                  <OneShareIcon />
                </span>
                <span className="cms-dash-border" />
                <span>
                  <GroupShareIcon />
                </span>
              </div>
              <div className="cms-group-share-empty-state-bottom-section">
                Use this space to share this group contacts with your colleagues
                and other relevant people. The contacts will be accessible when
                they sign up with ContactBook. You can also assign permissions
                according to your needs.{" "}
                <a
                  href="https://www.contactbook.app/help/how-to-share-contacts"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </div>
            </div>

            {
              //! keeping this comment for now (background image)
              /* <div className="cms-group-share-empty-state-image" /> */
            }
          </>
        )}
      </div>
      {userPermission === OWNER &&
        userSubscriptionInfo?.membersCount >=
          userSubscriptionInfo?.subscription?.quantity && (
          <UpgradePlanPopup
            title="Want to share contacts to more users?"
            content="Have access to additional features by switching to higher plan."
            spaceId={parseInt(spaceId, 10)}
          />
        )}
      {userPermission === ADMIN &&
        userSubscriptionInfo?.membersCount >=
          userSubscriptionInfo?.subscription?.quantity && (
          <WarningMessage
            content="This group is shared with you and the shared members limit is reached.
          Please contact Space Owner to add more shared members."
          />
        )}
      {/* Delete Group Modal */}
      <RemovePopup
        content={popupText}
        okText="Remove"
        visible={open}
        removeNote={handleConfirmation}
        handleRemove={handleDeleteGroup}
        deleteLoader={deleteGroupLoader}
        disabled={deleteGroupLoader}
        maskClosable={false}
      />
      {/* Leave Group Modal */}
      {leaveGroupModal && groupOwnerId !== parseInt(currentUser?.id, 10) ? (
        <RemovePopup
          content={
            <span className="popup-text-title font-inter">
              Are you sure you want to leave from the {groupData?.name} group?
            </span>
          }
          okText="Leave"
          visible={leaveGroupModal}
          removeNote={closeLeaveGroupModal}
          handleRemove={handleLeaveGroup}
          deleteLoader={leaveGroupLoader}
          disabled={leaveGroupLoader}
          maskClosable={false}
        />
      ) : (
        <RemovePopup
          content={
            <span className="popup-text-title font-inter">
              You can't leave the group until you change the owner
            </span>
          }
          okText="Ok"
          visible={leaveGroupModal}
          removeNote={closeLeaveGroupModal}
          handleRemove={closeLeaveGroupModal}
        />
      )}
      {edit && (
        <GroupCreateUpdateModal
          visible={edit}
          onCancel={closeEditModal}
          footer={null}
          centered
          initialValue={groupData}
          onFinish={handleGroupEdit}
          setIsFormUpdated={setIsFormUpdated}
          loading={groupUpdateLoading}
          isFormUpdated={isFormUpdated}
          title="Update Group"
          buttonText="Update"
        />
      )}
    </>
  );
}
export default GroupSetting;
