import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

//! Antd import
import { Button, Typography, Divider } from "antd";

//! User files
import { capitalizeWord } from "../../utils";
import { AppContext } from "../../../AppContext";
import {
  Apple,
  DesktopIcon,
  GooglePlayIcon,
  LogoWithName,
} from "../../../assets/svg";
import IconButton from "../IconButton/IconButton";
import { APP_STORE_URL, PLAY_STORE_URL, ROUTES } from "../../constants";

const { Title } = Typography;

function WebMobileScreen() {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const firstName = currentUser?.name.split(" ");
  const navigate = useNavigate();

  const spaceId = parseInt(localStorage.getItem("currentSpace"), 10);

  const goBackRoute = () => {
    navigate(`${ROUTES?.CONTACTS}/${spaceId}/all`);
  };

  const onButtonClick = (redirectURI) => {
    window.open(redirectURI, "_blank");
  };
  return (
    <div className="cms-web-mobile">
      <div className="cms-web-mobile-wrapper">
        <Title className="font-inter font-primary font-heading">
          Hello {capitalizeWord(firstName[0])},
        </Title>
        <div className="cms-web-mobile-content ">
          <Title level={4} className="font-primary font-inter">
            Welcome to ContactBook
          </Title>
          <p className="cms-web-mobile-content-text">
            You successfully created your ContactBook account.
          </p>
          <p>
            for, continue using ContactBook please download the mobile
            application from the link below
          </p>
        </div>
      </div>

      <div className="cms-app-download">
        <div className="cms-apple-btn-padding">
          <Button
            icon={<Apple className="cms-apple-icon" />}
            className="cms-apple-store"
            onClick={() => onButtonClick(APP_STORE_URL)}
          >
            <div className="cms-apple-btn-text">
              <span className="cms-apple-btn-text-p1">Available on the</span>
              <span className="cms-apple-btn-text-p2">App Store</span>
            </div>
          </Button>
        </div>
        <div>
          <Button
            icon={<GooglePlayIcon className="cms-apple-icon" />}
            className="cms-apple-store"
            onClick={() => onButtonClick(PLAY_STORE_URL)}
          >
            <div className="cms-apple-btn-text">
              <span className="cms-apple-btn-text-p1">GET IT ON</span>
              <span className="cms-apple-btn-text-p2">Google Play</span>
            </div>
          </Button>
        </div>
      </div>
      <Divider>
        <span className="font-inter cms-or-text">OR</span>
      </Divider>
      <div className="cms-desktop-section">
        <div>
          <div className="cms-desktop-info">
            <DesktopIcon />
            <span>use the desktop to continue using ContactBook.</span>
          </div>

          <div className="cms-go-back-container">
            <IconButton
              iconClass="cms-web-mobile-content-back-btn"
              text={<span className="font-inter back-btn-mobile">Go Back</span>}
              handleClick={goBackRoute}
            />
            <span className="cms-mobile-logout">
              <Link to={ROUTES.LOGOUT}>Logout</Link>
            </span>
          </div>
        </div>

        <div className="cms-contactbook-footer">
          <LogoWithName />
        </div>
      </div>
    </div>
  );
}

export default WebMobileScreen;
