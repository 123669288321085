import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

//! Ant Imports
import { Button, Checkbox, Form, Input, Typography } from "antd";

//! User files
import { AMPLITUDE_EVENT_LOG } from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import { SET_CURRENT_SIDE_TAB } from "../../../../../common/actionTypes";
import { createAmplitudeEvent } from "../../../../../common/utils";

//! Graphql files
import { CREATE_GROUP } from "../graphql/Mutations";
import { GET_GROUP_LIST_V2 } from "../../../graphql/queries/getGroupListV2";

const { Title } = Typography;

function CreateGroupModal() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { spaceId } = useParams();
  const { dispatch } = useContext(AppContext);
  const [groupName, setGroupName] = useState("");

  const [createGroup, { loading }] = useMutation(CREATE_GROUP, {
    refetchQueries: [
      {
        query: GET_GROUP_LIST_V2,
        variables: {
          spaceId: parseInt(spaceId, 10),
        },
      },
    ],
  });

  const onFinish = (e) => {
    const { name, visibility } = e;

    createGroup({
      variables: {
        name,
        type: visibility ? "PRIVATE" : "PUBLIC",
        spaceId: parseInt(spaceId, 10),
      },
    })
      .then((res) => {
        const eventProperties = {
          "Group Id": res?.data?.createGroup?.data?.id,
          "Group Name": res?.data?.createGroup?.data?.name,
          "Space Id": spaceId,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.GROUP_CREATED,
          eventProperties,
        );
        dispatch({ type: SET_CURRENT_SIDE_TAB, data: name });
        navigate(
          `/contacts/${spaceId}/group/${res?.data?.createGroup?.data?.id}`,
        );
      })
      .catch(() => {});
  };

  return (
    <div className="create-group-modal-wrapper">
      <div className="create-group-modal">
        <Title
          level={3}
          className="font-inter group-list-heading cms-text-strong"
        >
          Create a Group
        </Title>
        <div className="create-group-area">
          <div className="create-group-form">
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item colon={false} name="name" label="Group Name">
                <Input onChange={(e) => setGroupName(e?.target?.value)} />
              </Form.Item>
              <Form.Item name="visibility" valuePropName="checked">
                <Checkbox>Make this group as private</Checkbox>
              </Form.Item>
              <div className="create-group-modal-btn">
                <Button
                  className="group-add-contact"
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                  disabled={groupName?.length === 0}
                >
                  Create Group
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateGroupModal;
