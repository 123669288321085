import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import slice from "lodash/slice";
import clsx from "clsx";

//! Ant Imports
import Tag from "antd/lib/tag";
import Text from "antd/lib/typography/Text";

//! User files
import { Folder } from "../../../../../assets/svg";
import { AppContext } from "../../../../../AppContext";
import AllTagInfo from "./AllTagInfo";

function CustomTag({ name, icon, ownerId, userId }) {
  return (
    <Tag icon={icon} className="group-tag">
      <Text
        type="secondary"
        className={`font-inter ${ownerId !== userId && "group-tag-pd-left"}`}
        ellipsis={{ tooltip: "" }}
        title={name}
      >
        <span className="text">{name && name}</span>
      </Text>
    </Tag>
  );
}
function ContactGroups({
  tagArray = [],
  sliceGroup,
  groupsLength,
  spaceId,
  mergeContact,
}) {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const GROUPLENGTH = groupsLength;
  const THRESHOLD = 3;
  const firstSlice = slice(
    tagArray,
    0,
    groupsLength ? GROUPLENGTH : THRESHOLD - 1,
  );
  const secondSliceLength = slice(
    tagArray,
    groupsLength ? GROUPLENGTH : THRESHOLD - 1,
  ).length;
  const [tagInformation, showTagInformation] = useState(false);

  const renderTags =
    tagArray.length > 0 && tagArray.length >= THRESHOLD ? (
      <>
        {firstSlice.map((tag, i) => {
          const id = uuidv4();
          const { name, ownerId } = tag;
          return (
            <CustomTag
              key={id}
              name={name}
              icon={<Folder />}
              index={i}
              ownerId={ownerId}
              userId={parseInt(currentUser?.id, 10)}
              groupsLength={groupsLength}
              spaceId={spaceId}
            />
          );
        })}
        {sliceGroup && (
          <span className="popup-text-content-body">
            and {secondSliceLength} more groups.
          </span>
        )}
        {!sliceGroup && !groupsLength && (
          <Tag
            className="dashed-tag"
            onMouseEnter={() => showTagInformation(true)}
            onMouseLeave={() => showTagInformation(false)}
          >
            <Text type="secondary" className="font-inter">
              + {secondSliceLength}
            </Text>
          </Tag>
        )}
        {tagInformation && (
          <AllTagInfo
            tagArray={tagArray}
            userId={parseInt(currentUser?.id, 10)}
            mergeContact={mergeContact}
          />
        )}
      </>
    ) : (
      tagArray.map((tag, i) => {
        const id = uuidv4();
        const { name, ownerId, id: groupId } = tag;
        return (
          <CustomTag
            spaceId={spaceId}
            groupId={groupId}
            key={id}
            name={name}
            icon={<Folder />}
            index={i}
            ownerId={ownerId}
            userId={parseInt(currentUser?.id, 10)}
          />
        );
      })
    );
  return (
    <div
      className={clsx(
        "label-tag-wrapper",
        groupsLength && "groups-tags-wrapper",
      )}
    >
      {renderTags}
    </div>
  );
}

export default ContactGroups;
