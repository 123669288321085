import React, { useContext, useState } from "react";
import { AppContext } from "../../AppContext";
import { ROUTES } from "../constants";
import Loading from "./Loading";
import NoAccessComponent from "./NoAccessComponent/NoAccessComponent";

function LockPage({ loader, subscriptionInfo }) {
  return (
    !loader && (
      <div className="cms-no-plan">
        <NoAccessComponent
          title={
            subscriptionInfo?.subscription?.id === null
              ? "This Space has no active subscription!"
              : "This page is locked for you"
          }
          content={
            subscriptionInfo?.subscription?.id === null ? (
              <span>
                Please upgrade your plan to use this space. You can upgrade
                anytime and start using this space to manage contacts.
              </span>
            ) : (
              <span>Please contact your admin to re-activate the account</span>
            )
          }
          spaceLock
          upgradeButton={subscriptionInfo?.subscription?.id === null}
          spaceId={parseInt(subscriptionInfo?.id, 10)}
          spaceName={subscriptionInfo?.name}
          spacePhoto={subscriptionInfo?.photo}
        />
      </div>
    )
  );
}

function PageOrSpaceLock({ subscriptionInfo, children, pathname }) {
  const [loader, setLoader] = useState(true);
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const spaceId = parseInt(
    localStorage.getItem("currentSpace") || pathname?.split("/")?.[2],
    10,
  );

  setTimeout(() => {
    setLoader(false);
  }, 3000);

  return ((subscriptionInfo?.ownerId !== parseInt(currentUser?.id, 10) &&
    subscriptionInfo?.subscription?.id !== null) ||
    (subscriptionInfo?.ownerId === parseInt(currentUser?.id, 10) &&
      subscriptionInfo?.subscription?.id === null)) &&
    subscriptionInfo?.subscription?.id === null &&
    pathname !== ROUTES.MY_PROFILE &&
    pathname !== ROUTES.CHANGE_PASSWORD &&
    pathname !== ROUTES.NEW_SPACE &&
    pathname !== ROUTES.CONTACTS &&
    !pathname.includes(`${ROUTES?.CONTACTS}/all`) &&
    subscriptionInfo?.ownerId === parseInt(currentUser?.id, 10) &&
    !pathname.includes("/space-setting") &&
    !pathname.includes("/general") &&
    !pathname.includes("/members") &&
    !pathname.includes(`${ROUTES?.CONTACTS}/${spaceId}/all`) ? (
    <>
      {loader && (
        <div className="cms-pageLoader">
          <Loading />
        </div>
      )}
      <LockPage loader={loader} subscriptionInfo={subscriptionInfo} />
    </>
  ) : (
    children
  );
}

export default PageOrSpaceLock;
