import React from "react";
import moment from "moment";

//! Antd import
import { Tag } from "antd";

//! User file
import { REMINDER_MENU_LISTING, REMINDER_TYPES } from "../../constants";
import {
  CakeIcon,
  CelebrationIcon,
  ContactReminderImage,
} from "../../../assets/svg";
import { capitalizeWord } from "../../utils";

function ReminderDateComponent({ reminderType, scheduleAt, frequency }) {
  return (
    <Tag>
      {reminderType === REMINDER_TYPES.CONNECTION ? (
        <>
          <ContactReminderImage className="mr-10" />
          {REMINDER_MENU_LISTING[frequency]} |{" "}
          {moment(scheduleAt).format("D MMMM")}
        </>
      ) : (
        (reminderType === REMINDER_TYPES.BIRTHDAY ||
          reminderType === REMINDER_TYPES.ANNIVERSARY) && (
          <>
            {reminderType === REMINDER_TYPES.BIRTHDAY ? (
              <CakeIcon className="mr-10" />
            ) : (
              <CelebrationIcon className="mr-10" />
            )}
            {capitalizeWord(reminderType?.toLowerCase())} |{" "}
            {moment(scheduleAt).format("D MMMM")}
          </>
        )
      )}
    </Tag>
  );
}

export default ReminderDateComponent;
