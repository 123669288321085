import React from "react";
import styled from "styled-components";

//! Antd import
import { Button } from "antd";

//! User files
import { GroupAdd } from "../../../../../assets/svg";

const StyleIcon = styled.div`
  height: ${({ height }) => height || 74}px;
  width: ${({ width }) => width || 74}px;
`;

const StyleDiv = styled.div`
  font-size: ${({ fontSize }) => fontSize || 16}px;
`;

function NoGroupPlaceholder({
  title,
  buttonText,
  icon,
  showButton,
  height,
  width,
  fontSize,
}) {
  return (
    <div className="no-group">
      <StyleIcon className="no-group-circle" height={height} width={width}>
        {icon}
      </StyleIcon>
      <StyleDiv className="no-group-text font-inter" fontSize={fontSize}>
        {title}
      </StyleDiv>
      {showButton && (
        <div className="no-group-btn-wrapper">
          <Button className="no-group-create-btn">
            <GroupAdd className="no-group-svg" /> {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
}

export default NoGroupPlaceholder;
