import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

function RouterPrompt({
  when,
  title = "Leave this page",
  description = "There are unsaved changes. Are you sure you want to leave this page?",
  okText = "Confirm",
  cancelText = "Cancel",
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [blockedLocation, setBlockedLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (
        !confirmedNavigation &&
        when &&
        nextLocation.pathname !== location.pathname
      ) {
        setBlockedLocation(nextLocation);
        setShowPrompt(true);
        return true; // Indicate blocking
      }
      return false; // Indicate not blocking
    },
    [confirmedNavigation, when, location],
  );

  const handleConfirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const handleCancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setBlockedLocation(null);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && blockedLocation) {
      navigate(blockedLocation.pathname + blockedLocation.search, {
        state: blockedLocation?.state,
      });
    }
  }, [confirmedNavigation, blockedLocation, navigate]);

  useEffect(() => {
    if (when) {
      const beforeUnloadHandler = (event) => {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = description;
      };

      window.addEventListener("beforeunload", beforeUnloadHandler);

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [when, description]);

  useEffect(() => {
    setConfirmedNavigation(false);
  }, [location]);

  useEffect(() => {
    if (!when) return;

    const unblock = () => {
      window.onbeforeunload = (event) => {
        if (handleBlockedNavigation(location)) {
          event.preventDefault();
          return event.returnValue; // Show the default warning
        }
      };
    };

    unblock();

    return () => {
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBlockedNavigation, when]);

  return (
    <Modal
      title={title}
      open={showPrompt} // Use `open` prop for Ant Design v5
      onOk={handleConfirmNavigation}
      okText={okText}
      onCancel={handleCancelNavigation}
      cancelText={cancelText}
      okButtonProps={{ className: "common-button" }}
      cancelButtonProps={{ className: "common-button discard-button" }}
      closable
    >
      {description}
    </Modal>
  );
}

export default RouterPrompt;
