import { uniqBy } from "lodash";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

//! Antd import
import { Divider, Select, Tooltip } from "antd";

//! User files
import { AppContext } from "../../../AppContext";
import {
  DownArrowIcon,
  SyncBlock,
  SyncDone,
  SyncGrayOut,
  SyncProgress,
} from "../../../assets/svg";
import { SET_SPACE_PLAN_STATUS } from "../../actionTypes";
import {
  CHANGE_OWNER,
  CHANGE_OWNER_TEXT,
  CHANGE_PERMISSION,
  CONFIRM,
  GOOGLE,
  GOOGLE_PERMISSIONS,
  GROUP,
  GROUP_PERMISSIONS,
  GROUP_SHARED,
  GROUP_SYNC_STATUS,
  INVITED,
  MANAGER,
  OWNER,
  PERMISSIONS,
  REINVITE,
  REINVITE_USER,
  REMOVE,
  SPACE,
  UPGRADE_PLAN_TITLE,
  VIEW,
} from "../../constants";
import { HasRight } from "../../HasRight";
import {
  capitalizeWord,
  generateRandomColor,
  hasGroupRight,
  hasSpaceRight,
  isPlanActive,
} from "../../utils";
import ChangeOwnerPopup from "../ChangeOwnerPopup/ChangeOwnerPopup";
import IconButton from "../IconButton/IconButton";
import Loading from "../Loading";
import RemovePopup from "../RemovePopup/RemovePopup";
import SpaceListSkeleton from "../SpaceListSkeleton/SpaceListSkeleton";
import TextTag from "../TextTag";
import UserAvatar from "../UserAvatar/UserAvatar";

const { Option } = Select;

function SharedUserList({
  spaceLoading,
  members,
  handleRemoveMember,
  removeLoader,
  spaceName,
  handleMemberPermission,
  setVisible,
  visible,
  group,
  handleReinviteMember,
  reinviteLoader,
  invitedUserIds,
  setOwnerChangePopup,
  ownerChangePopup,
  handleChangeOwner,
  ownerLoader,
  ownerId,
  userPermission,
  allowToChangeOwner,
  noBorder,
  noFlex,
  openShareModal,
  handleInfiniteScroll,
  offset,
  addEmailInSharedUserList = false,
}) {
  const [memberName, setMemberName] = useState();
  const [id, setId] = useState();
  const [userId, setUserId] = useState();
  const {
    state: {
      currentUser,
      userGroupPermission,
      userSpacePermission,
      userSubscriptionInfo,
    },
    dispatch,
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const currentStep = parseInt(localStorage.getItem("currentStep"), 10);

  // eslint-disable-next-line no-shadow
  const openConfirmPopup = (id, name) => {
    setVisible(!visible);
    setMemberName(name);
    if (id) setId(id);
  };

  const handleOwnerChangePopup = (usrId) => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      )
    ) {
      setOwnerChangePopup(!ownerChangePopup);
      setUserId(usrId);
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
    }
  };

  const closePopup = () => {
    setOwnerChangePopup(false);
  };

  const permissionDropdown =
    // eslint-disable-next-line no-nested-ternary
    group
      ? GROUP_PERMISSIONS
      : userSubscriptionInfo?.type === GOOGLE
        ? GOOGLE_PERMISSIONS
        : PERMISSIONS;

  const tooltipText = (text) => (
    <span className="font-inter tooltip-text font-12">{text}</span>
  );

  const getSyncStatus = (status) => {
    if (status) {
      switch (status) {
        case GROUP_SYNC_STATUS.IN_PROGRESS:
          return (
            <Tooltip
              title={tooltipText("Sync is in progress")}
              placement="bottomRight"
              color="#101326"
              getPopupContainer={() => document.getElementById("shareList")}
            >
              <SyncProgress />
            </Tooltip>
          );
        case GROUP_SYNC_STATUS.COMPLETED:
          return (
            <Tooltip
              title={tooltipText("Sync completed")}
              placement="bottomRight"
              color="#101326"
              getPopupContainer={() => document.getElementById("shareList")}
            >
              <SyncDone />
            </Tooltip>
          );
        case GROUP_SYNC_STATUS.FAILED:
          return (
            <Tooltip
              title={tooltipText("Sync failed")}
              placement="bottomRight"
              color="#101326"
              getPopupContainer={() => document.getElementById("shareList")}
            >
              <SyncBlock />
            </Tooltip>
          );
        default:
          break;
      }
    } else {
      return (
        <Tooltip
          title={tooltipText("Permissions access not granted by the user.")}
          placement="bottomRight"
          color="#101326"
          getPopupContainer={() => document.getElementById("shareList")}
        >
          <SyncGrayOut />
        </Tooltip>
      );
    }
  };

  return spaceLoading && offset === 0 ? (
    <div
      className={`${
        allowToChangeOwner ? "cms-group-member" : "cms-space-member"
      }
      cms-shared-user-list cms-contact-scrollbar cms-shared-member-skeleton`}
    >
      <SpaceListSkeleton loading={spaceLoading} />
    </div>
  ) : (
    <div
      className={`${
        allowToChangeOwner ? "cms-group-member" : "cms-space-member"
      } ${noBorder ? "noBorder onboard-space-members" : ""} ${
        noFlex ? "cms-no-padding-margin" : ""
      }
      cms-shared-user-list cms-contact-scrollbar`}
      onScroll={handleInfiniteScroll}
    >
      {members &&
        uniqBy(members, "id")?.map((user) => (
          <div className="cms-shared-user-list-data" key={user?.id}>
            {addEmailInSharedUserList ? (
              <div className="cms-shared-user-list-cms-name email">
                <span>
                  <UserAvatar
                    className="cms-owner-avatar"
                    name={user?.name ?? user?.username}
                    color={generateRandomColor(parseInt(user?.id, 10))}
                    photo={user?.photo}
                  />
                </span>
                <div className="cms-name-email">
                  <div className="cms-name-tag">
                    <span
                      className="cms-font-ellipsis"
                      title={user?.name ?? user?.username}
                    >
                      {parseInt(currentUser?.id, 10) ===
                      parseInt(user?.userId, 10)
                        ? "You"
                        : (user?.name ?? user?.username)}
                    </span>
                    {user?.userId === ownerId && !noBorder && (
                      <TextTag
                        className="font-inter cms-shared-tag"
                        text={OWNER}
                      />
                    )}
                    {user?.sharedGroupsCount > 0 && !noBorder && (
                      <TextTag
                        // eslint-disable-next-line prefer-template
                        text={user.sharedGroupsCount + " " + GROUP_SHARED}
                        className="font-inter cms-shared-tag cms-shared-tag-ellipsis"
                      />
                    )}
                  </div>
                  <span className="cms-font-ellipsis email" title={user?.email}>
                    {user?.email}
                  </span>
                </div>
              </div>
            ) : (
              <div className="cms-shared-user-list-cms-name">
                <span>
                  <UserAvatar
                    className="cms-owner-avatar"
                    name={user?.name ?? user?.username}
                    color={generateRandomColor(parseInt(user?.id, 10))}
                    photo={user?.photo}
                  />
                </span>
                <span
                  className="cms-font-ellipsis"
                  title={user?.name ?? user?.username}
                >
                  {parseInt(currentUser?.id, 10) === parseInt(user?.userId, 10)
                    ? "You"
                    : (user?.name ?? user?.username)}
                </span>
                {user?.userId === ownerId && !noBorder && (
                  <TextTag className="font-inter cms-shared-tag" text={OWNER} />
                )}
                {user?.sharedGroupsCount > 0 && !noBorder && (
                  <TextTag
                    // eslint-disable-next-line prefer-template
                    text={user.sharedGroupsCount + " " + GROUP_SHARED}
                    className="font-inter cms-shared-tag cms-shared-tag-ellipsis"
                  />
                )}
              </div>
            )}
            <div
              className="cms-shared-user-list-rights-dropdown"
              id="shareList"
            >
              {!user?.emailVerified &&
                user?.userId !== ownerId &&
                !noBorder && (
                  <>
                    <HasRight
                      action={REINVITE_USER}
                      type={userGroupPermission !== null ? GROUP : SPACE}
                    >
                      <IconButton
                        iconClass="cms-reinvite-btn font-inter"
                        text={<span className="font-inter">{REINVITE}</span>}
                        handleClick={() =>
                          handleReinviteMember(
                            user?.userId,
                            user?.name ?? user?.username,
                          )
                        }
                        disable={
                          invitedUserIds?.includes(user?.userId) ||
                          !isPlanActive(
                            userSubscriptionInfo?.subscription?.planStatus,
                            userSubscriptionInfo?.subscription?.endDate,
                          )
                        }
                        loading={reinviteLoader}
                      />
                    </HasRight>
                    <TextTag
                      text={INVITED}
                      className="font-inter cms-invited-tag"
                    />
                  </>
                )}

              {allowToChangeOwner &&
                hasGroupRight(userGroupPermission, CHANGE_OWNER) &&
                user?.userId === parseInt(currentUser?.id, 10) && (
                  <IconButton
                    iconClass="font-inter cms-owner-text mr-8"
                    text={
                      <span className="font-inter">{CHANGE_OWNER_TEXT}</span>
                    }
                    handleClick={() => handleOwnerChangePopup(user?.userId)}
                    disable={
                      !isPlanActive(
                        userSubscriptionInfo?.subscription?.planStatus,
                        userSubscriptionInfo?.subscription?.endDate,
                      )
                    }
                  />
                )}
              {user?.userId !== parseInt(currentUser?.id, 10) &&
              user?.permissions !== OWNER &&
              !openShareModal &&
              ((!openShareModal && userSubscriptionInfo?.type !== GOOGLE) ||
                pathname.includes("settings")) &&
              (userGroupPermission !== null
                ? hasGroupRight(userGroupPermission, CHANGE_PERMISSION)
                : hasSpaceRight(userSpacePermission, CHANGE_PERMISSION)) ? (
                <Select
                  defaultValue={capitalizeWord(
                    user?.permissions?.toLowerCase() === "manage"
                      ? MANAGER
                      : user?.permissions?.toLowerCase(),
                  )}
                  className={`${
                    userSubscriptionInfo?.type === GOOGLE ? "mr-8" : "neg-mr-12"
                  } cms-select-after`}
                  optionLabelProp="label"
                  suffixIcon={<DownArrowIcon />}
                  onChange={(val) =>
                    handleMemberPermission(
                      val?.toUpperCase(),
                      user?.id,
                      user?.name ?? user?.username,
                    )
                  }
                  // eslint-disable-next-line react/no-unstable-nested-components
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider className="cms-shared-divider" />
                      <div
                        className="cms-shared-user-remove cms-delete-shared-user cursor-pointer"
                        onClick={() =>
                          openConfirmPopup(
                            user?.id,
                            user?.name ?? user?.username,
                          )
                        }
                      >
                        <span>Remove</span>
                      </div>
                    </div>
                  )}
                  dropdownAlign={{
                    offset: [-80, 0],
                  }}
                >
                  {permissionDropdown.map((rights) => (
                    <Option
                      value={capitalizeWord(rights?.value?.toLowerCase())}
                      label={rights?.label}
                      key={rights?.value}
                    >
                      <div className="rights-option">{rights?.label}</div>
                    </Option>
                  ))}
                </Select>
              ) : (
                userPermission !== OWNER && (
                  <span
                    className={`${
                      user?.permissions === OWNER ? "cms-owner-tag" : ""
                    } ${
                      userSubscriptionInfo?.type === GOOGLE && !noBorder
                        ? "mr-24"
                        : " "
                    }
                       `}
                  >
                    {capitalizeWord(
                      // eslint-disable-next-line no-nested-ternary
                      user?.permissions?.toLowerCase() === "manage"
                        ? MANAGER
                        : // eslint-disable-next-line no-nested-ternary
                          currentStep === 2 &&
                            userSubscriptionInfo?.type === GOOGLE
                          ? user?.permissions === OWNER
                            ? user?.permissions?.toLowerCase()
                            : VIEW.toLowerCase()
                          : user?.permissions?.toLowerCase(),
                    )}
                  </span>
                )
              )}
              {allowToChangeOwner &&
                userSubscriptionInfo?.type === GOOGLE &&
                getSyncStatus(user?.groupMapping?.syncStatus)}
            </div>
          </div>
        ))}
      {spaceLoading && (
        <div className="cms-infinite-scroll-loader">
          <Loading />
        </div>
      )}
      <RemovePopup
        visible={visible}
        okText={REMOVE}
        content={
          <span className="popup-text-title font-inter">
            Are you sure you want to remove {memberName} from {spaceName}?
          </span>
        }
        deleteLoader={removeLoader}
        id={id}
        handleRemove={() => handleRemoveMember(id, memberName)}
        removeNote={openConfirmPopup}
        memberName={memberName}
        disabled={removeLoader}
      />
      <ChangeOwnerPopup
        visible={ownerChangePopup}
        okText={CONFIRM}
        closePopup={closePopup}
        title={CHANGE_OWNER_TEXT}
        content={members}
        handleChangeOwner={handleChangeOwner}
        id={userId}
        ownerId={ownerId}
        loading={ownerLoader}
      />
    </div>
  );
}

export default SharedUserList;
