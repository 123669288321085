import React, { useContext } from "react";

//! Antd import
import { Divider, Tag } from "antd";

//! User files
import {
  NoteFolderIcon,
  SyncCompleteIcon,
  SyncStartedIcon,
} from "../../../../../assets/svg";
import { AppContext } from "../../../../../AppContext";
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import {
  capitalizeWord,
  generateRandomColor,
  getNotificationCreatedTime,
} from "../../../../../common/utils";
import { SYNC_LOG_TYPES } from "../../../../../common/constants";

function SyncLogListItem({ logs }) {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  return (
    <>
      {logs?.type === SYNC_LOG_TYPES.GROUP_SHARE_END && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail cms-sync-detail">
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  {parseInt(currentUser?.id, 10) ===
                  logs?.message?.sharedBy?.id ? (
                    <span className="cms-space-right">You shared</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {capitalizeWord(logs?.message?.sharedBy?.name) ||
                        logs?.message?.sharedBy?.email}
                      shared
                    </span>
                  )}
                  <Tag
                    icon={<NoteFolderIcon className="cms-group-folder" />}
                    className="cms-group-tag"
                    title={logs?.message?.group?.name}
                  >
                    <span className="cms-user-name">
                      {logs?.message?.group?.name}
                    </span>
                  </Tag>
                  <span className="cms-space-right">to</span>
                  {parseInt(currentUser?.id, 10) === logs?.message?.user?.id ? (
                    <span className="cms-text-space">you</span>
                  ) : (
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={logs?.message?.user?.name}
                          color={generateRandomColor(
                            parseInt(logs?.message?.user?.id, 10),
                          )}
                          photo={logs?.message?.user?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={logs?.message?.user?.name}
                    >
                      <span className="cms-user-name">
                        {logs?.message?.user?.name ||
                          logs?.message?.user?.email}
                      </span>
                    </Tag>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(logs?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}

      {logs?.type === SYNC_LOG_TYPES.SPACE_SYNC_END && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail cms-sync-detail">
              <div className="cms-notification-card-info">
                <span className="cms-user-info cms-sync-card">
                  <div>
                    <Tag
                      icon={<SyncCompleteIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                    >
                      <span className="cms-sync-text">Sync completed</span>
                    </Tag>
                  </div>
                  {
                    //! Not required as of now
                    /* <Link href="http://localhost:3000/contacts/2/group/all/contact/38205">
                    <span className="font-inter cms-sync-card-link">
                      View Details
                    </span>
                  </Link> */
                  }
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(logs?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}

      {logs?.type === SYNC_LOG_TYPES.SPACE_SYNC_START && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail cms-sync-detail">
              <div className="cms-notification-card-info">
                <span className="cms-user-info cms-sync-card">
                  <div>
                    <Tag
                      icon={<SyncStartedIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                    >
                      <span className="cms-sync-text">
                        <span className="cms-space-right">Sync started by</span>
                        <b>
                          {parseInt(currentUser?.id, 10) ===
                          logs?.message?.user?.id
                            ? "you"
                            : logs?.message?.user?.name ||
                              logs?.message?.user?.email}
                        </b>
                      </span>
                    </Tag>
                  </div>
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(logs?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
    </>
  );
}

export default SyncLogListItem;
