import React from "react";

//! User files
import {
  APP_STORE_URL,
  GOOGLE,
  PLAY_STORE_URL,
} from "../../../common/constants";

//! User files
import {
  AddOnsFeatureIcon,
  AppIcon,
  AppStoreBadge,
  CleanUpFeatureIcon,
  GooglePlayBadge,
  ReminderFeatureIcon,
} from "../../../assets/svg";

function MoreFeatures({ spaceType }) {
  const contactBookFeatures = [
    {
      id: 1,
      title: "Reminders",
      subTitle: "Never forget to connect with your contacts",
      icon: <ReminderFeatureIcon />,
    },
    {
      id: 2,
      title: "Clean Up",
      subTitle: "Identify and merge duplicate contacts",
      icon: <CleanUpFeatureIcon />,
    },
    {
      id: 3,
      title: "Add-ons",
      subTitle: "Plug n Play features for small businesses",
      icon: <AddOnsFeatureIcon />,
    },
  ];

  const googleWorkspaceFeatures = [
    {
      id: 1,
      title: "Reminders",
      subTitle: "Never forget to connect with your contacts",
      icon: <ReminderFeatureIcon />,
    },
    {
      id: 2,
      title: "Google Marketplace apps",
      subTitle: "Plugins to get more productivity",
      icon: <CleanUpFeatureIcon />,
    },
    {
      id: 3,
      title: "Integrations",
      subTitle: "Easily connect with existing platforms",
      icon: <AddOnsFeatureIcon />,
    },
  ];

  const renderFeatures =
    spaceType === GOOGLE ? googleWorkspaceFeatures : contactBookFeatures;

  return (
    <div className="cms-more-features">
      <div className="cms-more-features-left mr-24">
        <div className="cms-more-features-left-title">
          <span className="cms-more-features-section-title mb-24">
            Do more with contactbook
          </span>
        </div>
        <div className="cms-more-features-left-features">
          {renderFeatures?.map((feature) => (
            <div
              className="cms-more-features-left-features-section mb-24"
              key={feature?.id}
            >
              <div>{feature?.icon}</div>
              <div className="cms-features-desc ml-8">
                <span className="font-inter username cms-features-desc-title">
                  {feature?.title}
                </span>
                <span className="cms-features-desc-subtitle">
                  {feature?.subTitle}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="cms-more-features-right">
        <span className="cms-more-features-section-title mb-24">
          Download our mobile app
        </span>
        <span className="mb-24">
          <AppIcon />
        </span>
        <span>
          <a href={APP_STORE_URL} target="_blank" rel="noreferrer">
            <AppStoreBadge className="mr-16" />
          </a>
          <a href={PLAY_STORE_URL} target="_blank" rel="noreferrer">
            <GooglePlayBadge />
          </a>
        </span>
      </div>
    </div>
  );
}

export default MoreFeatures;
