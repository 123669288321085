import React from "react";

//! User files
import {
  GOOGLE_SUBSCRIPTION_FEATURE,
  SUBSCRIPTION_FEATURE,
} from "../../../../../common/constants";

function SubscriptionFeatures({ isGoogleWorkSpace }) {
  const subsFeature = isGoogleWorkSpace
    ? GOOGLE_SUBSCRIPTION_FEATURE
    : SUBSCRIPTION_FEATURE;

  return (
    <div className="cms-subs-feature">
      <span className="font-inter cms-subs-feature-title">Features</span>
      <div>
        {subsFeature?.map((feature, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="cms-feature-list">
            <span className="font-inter cms-feature-list-title">
              {feature?.title}
            </span>
            {feature?.subTitle && (
              <span className="font-inter cms-feature-list-subtitle">
                ({feature?.subTitle})
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SubscriptionFeatures;
