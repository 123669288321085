import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useMutation } from "@apollo/client";
import { Form, Typography } from "antd";
import { createAmplitudeEvent, notificationToast } from "../../../common/utils";
import {
  AMPLITUDE_EVENT_LOG,
  DOCUMENT_LINKS,
  ROUTES,
  USER,
} from "../../../common/constants";
import Layout from "../layout/Layout";
import { REGISTER_EMAIL_V4 } from "../graphql/Mutations";
import Loading from "../../../common/components/Loading";
import LoginSignupForm from "../../../common/components/LoginSignupForm/LoginSignupForm";
import { AppContext } from "../../../AppContext";

export const termsComponent = (
  <Typography.Text className="font-inter auth-terms">
    By clicking the sign up button above,
    <br /> you agree to our&nbsp;
    <a href={DOCUMENT_LINKS.TERMS} target="_blank" rel="noreferrer">
      Terms of Use&nbsp;
    </a>
    and&nbsp;
    <a href={DOCUMENT_LINKS.PRIVACY_POLICY} target="_blank" rel="noreferrer">
      Privacy Statement
    </a>
  </Typography.Text>
);
function Signup() {
  const { initializeAuth } = useContext(AppContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const authCode = params?.get("code");

  const [register, { loading }] = useMutation(REGISTER_EMAIL_V4, {
    async onCompleted(data) {
      createAmplitudeEvent(AMPLITUDE_EVENT_LOG.NEW_USER_REGISTERED);
      ReactGA.event({
        category: "CB Signup",
        action: "cb-user-signup",
        label: "Signup with Email",
      });

      const user = data?.registerv4?.data?.userInfo;
      localStorage?.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );

      localStorage.setItem("currentSpace", user?.spaceId);
      await initializeAuth(data?.registerv4?.data?.accessToken, user);
      navigate(ROUTES?.TELL_US_MORE, {
        replace: true,
        accessToken: data?.registerv4?.data?.accessToken,
      });
      form.resetFields();
    },
  });

  const onFinish = async (e) => {
    // eslint-disable-next-line no-shadow
    const { name, email, password, confirmPassword } = e;
    localStorage.setItem("userSignUpEmail", email?.toLowerCase());
    if (password !== confirmPassword) {
      notificationToast({
        message: "Password and confirm password should be same",
        type: "error",
      });
      return 0;
    }
    try {
      await register({
        variables: {
          data: {
            name,
            email: email.toLowerCase(),
            isConsentGiven: true,
            password,
          },
        },
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };
  return authCode ? (
    <div className="cms-center-loading">
      <Loading />
    </div>
  ) : (
    <Layout>
      <LoginSignupForm
        login={false}
        loading={loading}
        handleFinish={onFinish}
      />
    </Layout>
  );
}

export default Signup;
