import React, { useContext } from "react";
import clsx from "clsx";
import useWindowSize from "react-use/lib/useWindowSize";

//! Ant Imports
import { Drawer, Layout } from "antd";

//! User Files
import { AppContext } from "../../../AppContext";
import { TAB_SIZE } from "../../../common/constants";
import * as ActionTypes from "../../../common/actionTypes";
import SidebarItems from "../modules/SidebarItems";

const { Sider } = Layout;

function Sidebar() {
  const {
    state: { drawerVisible },
    dispatch,
  } = useContext(AppContext);
  const { width } = useWindowSize();
  const isDesktop = width >= TAB_SIZE;

  const toggleDrawer = () => {
    dispatch({ type: ActionTypes.SET_DRAWER_VISIBLE, data: !drawerVisible });
  };

  const renderDrawerOrSider = isDesktop ? (
    <SidebarItems />
  ) : (
    <Drawer
      className="app-drawer"
      placement="left"
      closable={false}
      onClose={toggleDrawer}
      open={drawerVisible}
    >
      <SidebarItems />
    </Drawer>
  );
  return (
    <Sider
      trigger={null}
      collapsible
      theme="dark"
      collapsedWidth={56}
      collapsed={isDesktop}
      className={clsx("cms-scrollbar", !isDesktop && "hide-sider")}
    >
      {renderDrawerOrSider}
    </Sider>
  );
}

export default Sidebar;
