import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

//! Antd imports
import { Button, message } from "antd";

//! User files
import { notificationToast } from "../../../common/utils";
import FileItem from "./FileItem";
import { AppContext } from "../../../AppContext";
import IconButton from "../../../common/components/IconButton/IconButton";
import * as ActionTypes from "../../../common/actionTypes";
import { FILE_UPLOAD_ERROR_MESSAGE } from "../../../common/constants";

function FilesContainer({
  file,
  setFile,
  importRef,
  currStep,
  setLoader,
  setIsFileUploaded,
}) {
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);
  const [errored, setErrored] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(FILE_UPLOAD_ERROR_MESSAGE);
  const [jobId, setJobId] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const spaceId =
    localStorage.getItem("currentSpace") || pathname?.split("/")[2];

  const handleUpload = async () => {
    setLoading(true);
    setErrored(false);
    let fileType;
    if (file?.name?.includes(".csv")) {
      fileType = "CSV";
    } else if (file?.name?.includes(".vcf")) {
      fileType = "VCF";
    } else {
      message.error("Invalid file format");
    }
    const { originFileObj: currentFile } = file;
    const formData = new FormData();
    formData.append("file", currentFile);
    formData.append("type", fileType);
    try {
      await axios
        .post(`${process.env.REACT_APP_SERVER_REST_URL}/parser`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: { userId: currentUser?.id, spaceId: spaceId },
        })
        .then((data) => {
          setJobId(data?.data?.jobId);
          if (data?.message) {
            notificationToast({
              message: data?.message,
              type: "error",
            });
            // eslint-disable-next-line no-unused-expressions
            setLoader && setLoader(false);
          }
        });
    } catch (error) {
      setLoading(false);
      setErrored(true);
      setErrorMessage(error?.response?.data?.message);
      setJobId();
      // eslint-disable-next-line no-unused-expressions
      setLoader && setLoader(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (jobId) {
      let provider = "VCF";
      if (file.name.includes(".csv")) {
        provider = "CSV";
      }
      dispatch({
        type: ActionTypes.SET_CONNECT_SOURCE_PARAMS,
        data: {
          provider: provider,
          jobId: jobId,
          isVerified: true,
          spaceId: spaceId,
        },
      });
      dispatch({
        type: ActionTypes.SET_IMPORT_CONTACT_STATUS,
        data: "PENDING",
      });
      navigate(`/contacts/${spaceId}/group/all`);
      // eslint-disable-next-line no-unused-expressions
      currStep && localStorage.setItem("currentStep", currStep);
      localStorage.setItem("provider", provider);
      localStorage.setItem("jobId", jobId);
      localStorage.setItem("spaceId", spaceId);
      localStorage.setItem("isVerified", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  return (
    <div className="import-card">
      <div className="import-card-contact">
        <div className="file-list">
          <FileItem
            errored={errored}
            name={file.name}
            file={file}
            setFile={setFile}
            id={file.uid}
            disabled={loading}
            errorMessage={errorMessage}
            setIsFileUploaded={setIsFileUploaded}
          />
        </div>
        <IconButton
          iconClass="file-list-item-button hide"
          type="primary"
          loading={loading}
          handleClick={() => handleUpload()}
          buttonRef={importRef}
        />
        <Button className="hide">
          {!loading && errored ? "Try again" : "Continue"}
        </Button>
      </div>
    </div>
  );
}

export default FilesContainer;
