import React from "react";

//! User Imports
import { UpgradeIcon } from "../assets/svg";
import IconButton from "./components/IconButton/IconButton";
import { UPGRADE } from "./constants";

function UpgradeButtonComponent({ text }) {
  return (
    <IconButton
      iconClass="group-upgrade"
      icon={<UpgradeIcon className="group-upgrade-icon" />}
      text={text ?? UPGRADE}
    />
  );
}

export default UpgradeButtonComponent;
