import React from "react";

//! Antd imports
import { Col, Row, Typography } from "antd";
import { DuplicateIcon } from "../../../../../assets/svg";
import { capitalizeWord } from "../../../../../common/utils";

const { Text } = Typography;

function AssistantHeader({ title }) {
  return (
    <div>
      <Row className="group-label-wrapper">
        <div className="group-label-sub-wrapper">
          <Col span={14} className="group-label-col">
            <DuplicateIcon className="folder-svg cms-svg-xl" />
            <Text
              type="secondary"
              className="group-label-name cms-text-strong font-inter"
            >
              {capitalizeWord(title.split("-").join(" "))}
            </Text>
          </Col>
        </div>
      </Row>
    </div>
  );
}

export default AssistantHeader;
