import React from "react";

//! User files
import { capitalizeWord } from "../utils";

function TextTag({ text, className }) {
  return (
    <div className={className} title={capitalizeWord(text?.toLowerCase())}>
      {capitalizeWord(text?.toLowerCase())}
    </div>
  );
}

export default TextTag;
