import React, { useContext, useEffect, useState } from "react";
import { Navigate, BrowserRouter as Router, useRoutes } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import uniqBy from "lodash/uniqBy";
import { nth } from "lodash";
import { Progress } from "antd";

// User Files
import Gleap from "gleap";
import { AppContext } from "./AppContext";
import {
  ADDON_PARAMS,
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  IMPORT_CONTACT_STATUS,
  ROUTES,
} from "./common/constants";
import Login from "./modules/auth/components/Login";
import Logout from "./modules/auth/components/Logout";
import Signup from "./modules/auth/components/Signup";
import RegisterSuccess from "./modules/auth/components/RegisterSuccess";
import EmailSent from "./modules/auth/components/EmailSent";
import ImportContacts from "./modules/onboard/components/ImportContacts";
import ImportProgress from "./modules/onboard/components/ImportProgress";
import Verify from "./modules/auth/components/Verify";
import ThankYouPage from "./modules/main/modules/subscription/components/ThankYouPage";
import CreatePassword from "./modules/auth/components/CreatePassword";
import TellUsMore from "./modules/auth/components/TellUsMore";
import PublicLinkComponent from "./modules/main/modules/contacts/components/PublicLinkComponent";
import ForgotPassword from "./modules/auth/components/ForgotPassword";
import EmailLogin from "./modules/auth/components/EmailLogin";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { notificationToast, createAmplitudeEvent } from "./common/utils";
import * as ActionTypes from "./common/actionTypes";

// GraphQL queries
import { GET_SPACE_DETAIL_V2 } from "./modules/main/graphql/queries/getSpaceDetailV2";
import { GET_GROUP_LIST_V2 } from "./modules/main/graphql/queries/getGroupListV2";
import { GET_ALL_CONTACTS } from "./modules/main/graphql/queries/getContactsList";
import Main from "./modules/main/Main";

const AUTH_MODULES = [
  {
    path: ROUTES?.LOGIN,
    element: <PublicRoute />,
    children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
  },
  {
    path: ROUTES?.SIGNUP,
    element: <PublicRoute />,
    children: [{ path: ROUTES?.SIGNUP, element: <Signup /> }],
  },
  {
    path: ROUTES?.LOGOUT,
    element: <PrivateRoute />,
    children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
  },
  {
    path: ROUTES?.REGISTER_SUCCESS,
    element: <PublicRoute />,
    children: [
      { path: ROUTES?.REGISTER_SUCCESS, element: <RegisterSuccess /> },
    ],
  },
  {
    path: ROUTES?.EMAIL_SENT,
    element: <PublicRoute />,
    children: [{ path: ROUTES?.EMAIL_SENT, element: <EmailSent /> }],
  },
  {
    path: ROUTES?.EMAIL_LOGIN,
    element: <PublicRoute />,
    children: [{ path: ROUTES?.EMAIL_LOGIN, element: <EmailLogin /> }],
  },
  {
    path: ROUTES?.CREATE_PASSWORD,
    element: <PublicRoute />,
    children: [{ path: ROUTES?.CREATE_PASSWORD, element: <CreatePassword /> }],
  },
  {
    path: ROUTES?.FORGOT_PASSWORD,
    element: <PublicRoute />,
    children: [{ path: ROUTES?.FORGOT_PASSWORD, element: <ForgotPassword /> }],
  },
  {
    path: ROUTES?.AUTH_VERIFY,
    element: <PublicRoute />,
    children: [
      { path: ROUTES?.AUTH_VERIFY, element: <Verify forgotPassword={false} /> },
    ],
  },
  {
    path: ROUTES?.VERIFY,
    element: <PublicRoute />,
    children: [{ path: ROUTES?.VERIFY, element: <Verify forgotPassword /> }],
  },
];

const IMPORT_MODULES = [
  {
    path: ROUTES?.ONBOARDING,
    element: <PrivateRoute />,
    children: [{ path: ROUTES?.ONBOARDING, element: <ImportContacts /> }],
  },
  {
    path: ROUTES?.PROGRESS,
    element: <PrivateRoute />,
    children: [{ path: ROUTES?.PROGRESS, element: <ImportProgress /> }],
  },
];

const TELL_US_MORE_MODULES = [
  {
    path: ROUTES?.TELL_US_MORE,
    element: <PrivateRoute />,
    children: [{ path: ROUTES?.TELL_US_MORE, element: <TellUsMore /> }],
  },
];

const SUBSCRIPTION_MODULES = [
  {
    path: ROUTES?.SUBSCRIPTION_UPDATED,
    element: <PrivateRoute />,
    children: [
      { path: ROUTES?.SUBSCRIPTION_UPDATED, element: <ThankYouPage /> },
    ],
  },
  {
    path: ROUTES?.SUBSCRIPTION_CREATED,
    element: <PrivateRoute />,
    children: [
      { path: ROUTES?.SUBSCRIPTION_CREATED, element: <ThankYouPage /> },
    ],
  },
];

const MAIN_MODULES = [
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "",
        element: <Navigate to="/contacts" replace />,
      },
      {
        path: "/*",
        element: <Main />,
      },
    ],
  },
];

const OTHER_MODULES = [
  {
    path: ROUTES?.PUBLIC_CONTACT_LINK,
    element: <PublicRoute />,
    children: [
      { path: ROUTES?.PUBLIC_CONTACT_LINK, element: <PublicLinkComponent /> },
    ],
  },
];

const RoutesCollection = () => {
  const element = useRoutes([
    ...AUTH_MODULES,
    ...IMPORT_MODULES,
    ...TELL_US_MORE_MODULES,
    ...SUBSCRIPTION_MODULES,
    ...MAIN_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

function RoutesWrapper() {
  const [status, setStatus] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const {
    initializeAuth,
    state: { connectSourceParams, currentUser, contacts },
    dispatch,
  } = useContext(AppContext);
  const { provider, jobId, spaceId, userId } = connectSourceParams;
  const importJobId = jobId || localStorage.getItem("jobId");
  const sourceProvider = provider || localStorage.getItem("provider");
  const userSpaceId =
    parseInt(spaceId, 10) ||
    parseInt(localStorage.getItem("spaceId"), 10) ||
    parseInt(localStorage.getItem("currentSpace"), 10);
  const loginUserId = userId || localStorage.getItem("userId");
  const contactInfo = new URLSearchParams(document?.location?.search);
  const currentPath = window.location.pathname;

  const [getContacts, { data: allContacts, loading: contactsLoader }] =
    useLazyQuery(GET_ALL_CONTACTS, {
      fetchPolicy: "network-only",
      onCompleted() {
        dispatch({
          type: ActionTypes.SET_IMPORT_CONTACT_STATUS,
          data: IMPORT_CONTACT_STATUS.COMPLETED,
        });
      },
    });

  const [getSpaceDetail] = useLazyQuery(GET_SPACE_DETAIL_V2, {
    fetchPolicy: "network-only",
  });

  const [getGroupListV2] = useLazyQuery(GET_GROUP_LIST_V2, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      Array.from(contactInfo.values())?.length !== 0 &&
      nth(Array.from(contactInfo.keys()), 0) === "name" &&
      nth(Array.from(contactInfo.keys()), 1) === "email"
    ) {
      localStorage.setItem(
        ADDON_PARAMS,
        JSON.stringify(Array.from(contactInfo.values())),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo.values()]);

  useEffect(() => {
    if (!contactsLoader)
      if (allContacts?.getContacts?.data?.contacts) {
        dispatch({
          type: ActionTypes.SET_CONTACTS,
          data: uniqBy(
            [...contacts, ...allContacts.getContacts.data.contacts],
            "id",
          ),
        });
        dispatch({
          type: ActionTypes.SET_CONTACTS_COUNT,
          data: allContacts?.getContacts?.data?.count,
        });
      } else {
        dispatch({
          type: ActionTypes.SET_CONTACTS,
          data: [],
        });
        dispatch({
          type: ActionTypes.SET_CONTACTS_COUNT,
          data: 0,
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContacts]);

  useEffect(() => {
    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (
        importJobId &&
        status !== IMPORT_CONTACT_STATUS.COMPLETED &&
        status !== IMPORT_CONTACT_STATUS.DONE
      ) {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_REST_URL}/status/${importJobId}`,
          params: {
            userId: currentUser?.id || loginUserId,
            spaceId: userSpaceId,
          },
        }).then((res) => {
          setStatus(res?.data?.importStatus);
          if (res?.data?.contactCount && res?.data?.successCount) {
            setPercent((res.data.successCount * 100) / res.data.contactCount);
            setTotalCount(res?.data?.contactCount);
            setSuccessCount(res?.data?.successCount);
            dispatch({
              type: ActionTypes.SET_IMPORT_STATUS_AND_PROGRESS,
              data: {
                status: res?.data?.importStatus,
                percent: (res.data.successCount * 100) / res.data.contactCount,
              },
            });
          }
        });
      }
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importJobId, status]);

  useEffect(() => {
    if (status === IMPORT_CONTACT_STATUS.COMPLETED) {
      if (userSpaceId > 0) {
        getSpaceDetail({ variables: { id: parseInt(userSpaceId, 10) } });
        getGroupListV2({
          variables: {
            offset: 0,
            limit: CONTACT_LIMIT,
            spaceId: parseInt(userSpaceId, 10),
          },
        });
        getContacts({
          variables: {
            tag: null,
            spaceId: userSpaceId ? parseInt(userSpaceId, 10) : null,
            groupId: null,
            data: {
              limit: CONTACT_LIMIT,
              offset: 0,
              sortBy: "ASC",
              sortOn: "firstName",
            },
          },
        });
      }
      notificationToast({
        message: "Contacts imported successfully",
        type: "Success",
      });
      dispatch({ type: ActionTypes.SET_IMPORT_CONTACT_STATUS, data: status });
      if (sourceProvider === "GOOGLE" || sourceProvider === "MICROSOFT") {
        const eventProperties = {
          "Space Id": userSpaceId,
          Service: sourceProvider,
          "Total contacts": totalCount,
          "Total Success": successCount,
          Status: status,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.CONTACTS_IMPORTED,
          eventProperties,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
  }, []);

  return (
    <>
      <Router>
        <RoutesCollection />
      </Router>
      <div className="cms-import-help-space">
        {!currentPath?.includes(ROUTES.LOGIN) &&
          !currentPath?.includes(ROUTES.TELL_US_MORE) &&
          sourceProvider !== "undefined" &&
          sourceProvider !== undefined &&
          sourceProvider !== null &&
          importJobId &&
          status !== IMPORT_CONTACT_STATUS.COMPLETED &&
          status !== IMPORT_CONTACT_STATUS.DONE && (
            <div className="import-progress-bar">
              <div className="import-progress-bar-top">
                <span className="font-inter import-progress-bar-top-title cms-progress">
                  Importing Contacts
                </span>
                <span className="import-progress-bar-top-count cms-progress">
                  {successCount}/{totalCount}
                </span>
              </div>
              <div className="import-progress-bar-bottom">
                <Progress percent={percent} showInfo={false} status="active" />
              </div>
            </div>
          )}
      </div>
    </>
  );
}

export default RoutesWrapper;
