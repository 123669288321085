import React from "react";
//! Antd import
import { Row } from "antd";

//! User import
import { AddRow } from "../../../../../assets/svg";

function AddContactInfoRow({ text, handleRoute }) {
  return (
    <Row
      className="contact-info-wrapper-info contact-info-wrapper-add-row"
      onClick={handleRoute}
    >
      <span className="contact-add-row">
        <AddRow />
      </span>
      <span>{text}</span>
    </Row>
  );
}

export default AddContactInfoRow;
