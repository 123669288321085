import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import slice from "lodash/slice";

//! Ant Imports
import { Tag, Typography } from "antd";

//! User files
import { TagIcon } from "../../../../../assets/svg";
import AllTagInfo from "./AllTagInfo";

const { Text } = Typography;

function CustomTag({ name }) {
  return (
    <Tag className="cms-tag-box">
      <Text
        type="secondary"
        className="font-inter"
        ellipsis={{ tooltip: "" }}
        title={name}
      >
        {name && name}
      </Text>
    </Tag>
  );
}

function TagLabel({ tagArray = [] }) {
  const THRESHOLD = 3;
  const firstSlice = slice(tagArray, 0, THRESHOLD - 1);
  const secondSliceLength = slice(tagArray, THRESHOLD - 1).length;
  const [tagInformation, showTagInformation] = useState(false);
  const renderTags =
    tagArray?.length > 0 && tagArray?.length > THRESHOLD ? (
      <>
        {firstSlice?.map((tag) => {
          const id = uuidv4();
          return <CustomTag key={id} name={tag} />;
        })}
        <Tag
          className="cms-tag-box"
          onMouseEnter={() => showTagInformation(true)}
          onMouseLeave={() => showTagInformation(false)}
        >
          <Text type="secondary" className="font-inter">
            + {secondSliceLength}
          </Text>
        </Tag>
        {tagInformation && <AllTagInfo tagArray={tagArray} />}
      </>
    ) : (
      tagArray.map((tag) => {
        const id = uuidv4();
        return <CustomTag key={id} name={tag} />;
      })
    );
  return tagArray.length ? (
    <div className="label-tag-wrapper">
      <span className="label-tag-wrapper-tag-icon">
        <TagIcon />
      </span>
      {renderTags}
    </div>
  ) : null;
}

export default TagLabel;
