import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import axios from "axios";

//! Antd imports
import { Modal } from "antd";

//! User files
import client from "../../../../../apollo";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import ContactSourceList from "./ContactSourceList";
import NoGroupPlaceholder from "../../contacts/components/NoGroupPlaceholder";
import { ContactSource, NoContacts } from "../../../../../assets/svg";
import AddContactSourceCard from "./AddContactSourceCard";
import { AppContext } from "../../../../../AppContext";
import {
  ADD_NEW_SOURCE,
  AMPLITUDE_EVENT_LOG,
  GOOGLE,
  UPGRADE_PLAN_TITLE,
} from "../../../../../common/constants";
import UpgradePlanPopup from "../../../../../common/components/UpgradePlanPopup/UpgradePlanPopup";
import {
  createAmplitudeEvent,
  isPlanActive,
  notificationToast,
} from "../../../../../common/utils";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";
import SettingsHeader from "../../../../../common/components/SettingsHeader/SettingsHeader";
import SpaceListSkeleton from "../../../../../common/components/SpaceListSkeleton/SpaceListSkeleton";
import AddContactSource from "./AddContactSource";

//! Graphql files
import { GET_CONTACT_SOURCE_LIST } from "../../../graphql/queries/getContactSourceList";
import { RESYNC } from "../../../graphql/mutations/reSync";
import { REMOVE_CONTACT_SOURCE } from "../../../graphql/mutations/removeContactSource";
import { UPDATE_CONTACT_SOURCE } from "../../../graphql/mutations/updateContactSource";
import {
  VERIFY_GOOGLE_CODE,
  VERIFY_MICROSOFT_CODE,
} from "../../../../onboard/graphql/Mutations";

function ContactSources() {
  const [sourceData, setSourceData] = useState([]);
  const [hideFetching, setHideFetching] = useState(true);
  const [status, setStatus] = useState(null);
  const [jobId, setJobId] = useState();
  const [id, setId] = useState();
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [service, setService] = useState("GOOGLE");
  const [sourceModal, setSourceModal] = useState();
  const {
    state: { currentUser, userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);
  const { spaceId } = useParams();
  const syncSourceLimit = parseInt(
    userSubscriptionInfo?.subscription?.product?.features?.f_sync_sources,
    10,
  );
  const code = localStorage.getItem("sourceCode");
  const provider = localStorage.getItem("sourceProvider");

  const { data: contactSourceList, loading: sourceLoading } = useQuery(
    GET_CONTACT_SOURCE_LIST,
    {
      fetchPolicy: "network-only",
      variables: {
        id: parseInt(spaceId, 10),
      },
    },
  );

  const [reSync] = useMutation(RESYNC, {
    refetchQueries: [
      {
        fetchPolicy: "network-only",
        query: GET_CONTACT_SOURCE_LIST,
        variables: {
          id: parseInt(spaceId, 10),
        },
      },
    ],
    onCompleted() {
      setTimeout(() => {
        setId();
        setHideFetching(true);
      }, 2000);
    },
  });

  const [removeContactSourceAccount, { loading: removeLoader }] = useMutation(
    REMOVE_CONTACT_SOURCE,
    {
      refetchQueries: [
        {
          query: GET_CONTACT_SOURCE_LIST,
          variables: { id: parseInt(spaceId, 10) },
          fetchPolicy: "network-only",
        },
      ],
      onCompleted() {
        setOpen(false);
      },
    },
  );

  const [updateContactSource] = useMutation(UPDATE_CONTACT_SOURCE);

  useEffect(() => {
    if (contactSourceList?.spaceContactSources?.data) {
      setSourceData(
        contactSourceList?.spaceContactSources?.data?.contactSources,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactSourceList]);

  const handleRouteChange = () => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate,
      )
    ) {
      setSourceModal(true);
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
    }
  };

  // eslint-disable-next-line no-shadow
  const handleFetching = (id) => {
    if (id) {
      setHideFetching(false);
      setId(id);
      reSync({
        variables: {
          sourceId: parseInt(id, 10),
        },
      });
    }
  };

  const removeContactSource = (sourceId) => {
    if (sourceId) {
      removeContactSourceAccount({
        variables: {
          id: sourceId,
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  // eslint-disable-next-line no-shadow
  const handleUpdateSource = (value, id) => {
    if (value) {
      updateContactSource({
        variables: {
          id: parseInt(id, 10),
          data: {
            fetchInterval: value,
          },
        },
      });
    }
  };

  const removeItemFromStorage = () => {
    localStorage.removeItem("sourceCode");
    localStorage.removeItem("sourceProvider");
  };

  useEffect(() => {
    if (code && provider === "GOOGLE") {
      setService("GOOGLE");
      client
        .mutate({
          mutation: VERIFY_GOOGLE_CODE,
          variables: { code: code, spaceId: parseInt(spaceId, 10) },
        })
        .then((res) => {
          removeItemFromStorage();
          client.query({
            query: GET_CONTACT_SOURCE_LIST,
            fetchPolicy: "network-only",
            variables: {
              id: parseInt(spaceId, 10),
            },
          });
          setJobId(res?.data?.verifyGoogleCode?.jobId);
        })
        .catch(() => {
          removeItemFromStorage();
        });
    } else if (code && provider === "MICROSOFT") {
      setService("MICROSOFT");
      client
        .mutate({
          mutation: VERIFY_MICROSOFT_CODE,
          variables: { code: code, spaceId: parseInt(spaceId, 10) },
        })
        .then((res) => {
          removeItemFromStorage();
          client.query({
            query: GET_CONTACT_SOURCE_LIST,
            fetchPolicy: "network-only",
            variables: {
              id: parseInt(spaceId, 10),
            },
          });
          setJobId(res?.data?.verifyMicrosoftCode?.jobId);
        })
        .catch(() => {
          removeItemFromStorage();
        });
    }
    localStorage.removeItem("contactSource");
    localStorage.removeItem("currentSpaceId");
  }, [code, provider, spaceId]);

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (jobId && status !== "COMPLETED") {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_REST_URL}/status/${jobId}`,
          params: { userId: currentUser?.id, spaceId: parseInt(spaceId, 10) },
        }).then((res) => {
          setTotalCount(res?.data?.contactCount);
          setStatus(res?.data?.importStatus);
        });
      }
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
  }, [currentUser, jobId, status, spaceId]);

  useEffect(() => {
    if (status === "COMPLETED") {
      notificationToast({
        message: `Contacts imported successfully`,
        type: "Success",
      });
      const eventProperties = {
        "Space Id": spaceId,
        "Total contacts": totalCount,
        Service: service,
      };
      createAmplitudeEvent(
        AMPLITUDE_EVENT_LOG.SOURCE_CONNECTED,
        eventProperties,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <SettingsHeader icon={<ContactSource />} title="Contact Sources" />
      <div className="cms-contact-source-wrapper">
        <div className="cms-contact-source-wrapper-block">
          {sourceLoading && (
            <div className="cms-skeleton-outer">
              <SpaceListSkeleton loading={sourceLoading} />
            </div>
          )}
          {sourceData?.length > 0 ? (
            <>
              <div className="cms-contact-source-account">
                <ContactSourceList
                  data={sourceData}
                  handleFetching={handleFetching}
                  hideFetching={hideFetching}
                  removeContactSource={removeContactSource}
                  removeLoader={removeLoader}
                  handleUpdateSource={handleUpdateSource}
                  status={status}
                  rowId={id}
                  setOpen={setOpen}
                  open={open}
                />
              </div>
              {userSubscriptionInfo?.type !== GOOGLE && (
                <div className="cms-add-contact-source-btn-wrapper">
                  <IconButton
                    iconClass={`${
                      sourceData?.length >= syncSourceLimit
                        ? "cms-add-contact-sources-btn-disable"
                        : ""
                    }
                    cms-add-contact-source-btn`}
                    text={ADD_NEW_SOURCE}
                    handleClick={handleRouteChange}
                    disable={sourceData?.length >= syncSourceLimit}
                    type="primary"
                  />
                </div>
              )}
              {userSubscriptionInfo?.type !== GOOGLE &&
                sourceData?.length >= syncSourceLimit && (
                  <div>
                    <UpgradePlanPopup
                      title="You've reached your contact sources limit"
                      content="Have access to additional features by switching to higher plan."
                      spaceId={parseInt(spaceId, 10)}
                    />
                  </div>
                )}
            </>
          ) : (
            !sourceLoading && (
              <>
                <NoGroupPlaceholder
                  icon={<NoContacts />}
                  title="No account source found"
                />
                <AddContactSourceCard spaceId={spaceId} />
              </>
            )
          )}
        </div>
      </div>
      <Modal
        open={sourceModal}
        footer={null}
        onCancel={() => setSourceModal(false)}
        centered
      >
        <AddContactSource />
      </Modal>
    </>
  );
}

export default ContactSources;
