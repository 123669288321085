import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { nth } from "lodash";

//! User files

import { AddNote, CloseBlack, NoNotesFound } from "../../../../../assets/svg";
import Loading from "../../../../../common/components/Loading";
import AddNotePopup from "./AddNoteModal";
import ContactNotesListItem from "./ContactNotesListItem";
import NoGroupPlaceholder from "./NoGroupPlaceholder";
import { ADD_NOTE, EDIT, VIEW } from "../../../../../common/constants";
import { hasGroupRight } from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";

function ContactNotesList({
  openAddNoteModal,
  visible,
  notesList,
  setVisible,
  addNotes,
  setVisibility,
  form,
  notesLoader,
  handleRemoveNote,
  open,
  setOpen,
  deleteLoader,
  loading,
  contactPermission,
}) {
  const {
    state: { userGroupPermission },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const isDeletedContact = nth(pathname?.split("/"), 4) === "deleted-contacts";
  const [addNoteBottom, setAddNoteBottom] = useState(undefined);
  const scrollableSection = document.querySelector(
    ".dashboard-contact-detail-col",
  );
  const notesElement = document.querySelector(".add-note");
  const removeStickyStyle = () => {
    notesElement.style.position = "";
    notesElement.style.bottom = "";
    notesElement.style.right = "";
    notesElement.style.marginBottom = "";
    notesElement?.classList?.remove("is-sticky");
  };
  const isSticky = () => {
    const scrollBottom = window.scrollY + window.innerHeight;
    const notePosition = notesElement.offsetTop + notesElement.offsetHeight;
    if (scrollBottom >= notePosition) {
      notesElement.style.position = "fixed";
      notesElement.style.bottom = "74px";
      notesElement.style.right = "32px";
      notesElement.style.marginBottom = "0";
      notesElement?.classList?.add("is-sticky");
    } else {
      removeStickyStyle();
    }
  };
  const parentBoundary = document
    .querySelector(".contact-tabs")
    ?.getClientRects()?.[0]?.bottom;

  useEffect(() => {
    setAddNoteBottom(notesElement?.getBoundingClientRect()?.bottom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesElement]);

  useEffect(() => {
    if (!addNoteBottom) return;
    scrollableSection?.addEventListener("scroll", isSticky);
    return () => {
      scrollableSection?.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNoteBottom]);

  useEffect(() => {
    if (notesElement) {
      if (parentBoundary >= window.scrollY + window.innerHeight) {
        isSticky();
      } else {
        removeStickyStyle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesList, notesElement, parentBoundary]);

  return (
    <>
      {notesList?.length > 0 ? (
        notesList.map((notes, i) => (
          <ContactNotesListItem
            key={notes?.id}
            openAddNoteModal={openAddNoteModal}
            note={notes?.note}
            createdAt={notes?.createdAt}
            visibility={notes?.visibility}
            username={notes?.username}
            handleRemoveNote={handleRemoveNote}
            open={open}
            setOpen={setOpen}
            id={notes?.id}
            deleteLoader={deleteLoader}
            userId={notes?.userId}
            contactPermission={contactPermission}
            notesLength={notesList?.length}
            index={i}
          />
        ))
      ) : (
        <div className="cms-no-notes">
          <NoGroupPlaceholder
            title="No comments available."
            showButton={false}
            icon={<NoNotesFound />}
          />
        </div>
      )}
      {loading && <Loading />}
      {!isDeletedContact &&
        contactPermission &&
        hasGroupRight(
          (userGroupPermission ?? contactPermission) ? EDIT : VIEW,
          ADD_NOTE,
        ) && (
          <div className={`${visible ? "close-note" : ""} add-note`}>
            {visible ? (
              <CloseBlack onClick={openAddNoteModal} />
            ) : (
              <AddNote onClick={openAddNoteModal} />
            )}
            {visible && (
              <AddNotePopup
                setVisible={setVisible}
                addNotes={addNotes}
                setVisibility={setVisibility}
                form={form}
                notesLoader={notesLoader}
              />
            )}
          </div>
        )}
    </>
  );
}

export default ContactNotesList;
